import React from "react"
import { Button } from 'antd'
import moment from "moment"
import 'moment/locale/zh-cn';

export default class Component extends React.Component {

	ucases = ()=> {
		if(!this.props.data || this.props.data.length ==0) { return (
				<span>暂无数据</span>
		) }
		return this.props.data.map(ucase=>{
			return (<div className="index_words_item" key={ucase.id}>
				<div className="index_words_content">
					<div className="index_words_text">
						<span className="blue">检查员</span> {ucase.creator.name} 
						提交了 <span className="blue">编号为: {ucase.id} </span> 的{t.case}&nbsp;
						<span className="blue">请尽快审核</span>
					</div>
					<div className="index_words_time">
					{moment(ucase.created_at).format("llll")}
					</div>
				</div>
				<div className="index_words_btn">
					<a href={`/ucases/${ucase.id}`}><Button style={{marginRight:10}}>查看{t.case}</Button></a>
				</div>
			</div>)
		})
	}

	render(){
		return(
			<div className="index_words">
				{ this.ucases() }
			</div>
		)
	}
}