import React from 'react'
import Lightbox from 'react-images'
import { Button, Popconfirm, message } from 'antd'

export default class Component extends React.Component {
  state = {
    lightboxIsOpen: false,
    currentImage: 0,
    height: this.props.height || 'h_180',
    images: this.props.images,
    activeImage: '',
  }

  gotoPrevious = () => {
    this.setState({
      currentImage: this.state.currentImage - 1,
    })
  }

  gotoNext = () => {
    this.setState({
      currentImage: this.state.currentImage + 1,
    })
  }

  closeLightbox = () => {
    this.setState({
      lightboxIsOpen: false,
    })
  }

  clickImg = (i, e) => {
    console.log('click img')
    this.setState({
      currentImage: i,
      lightboxIsOpen: true,
    })
  }

  confirm = image => {
    // axios
    //   .delete(`/ucases/${this.props.ucase.id}/image.json?image=${image}`, { withCredentials: true })
    //   .then(({ data }) => {
    //     if (data.code == 200) {
    //       this.setState({
    //         images: this.state.images.filter(x => x != data.data.deleted),
    //       })
    //       this.props.delImg(this.state.images.filter(x => x != image))
    //       console.log(data, this.state.images)
    //     } else {
    //       console.log('删除失败')
    //     }
    //   })
    this.setState({
      images: this.state.images.filter(x => x != image),
    })
    this.props.delImg(this.state.images.filter(x => x != image))
  }
  cancel = () => {
    return
  }
  overImg = image => {
    console.log('i', image)
    this.setState({
      activeImage: image,
    })
  }
  orImages = () => {
    return this.state.images.map((image, i) => {
      return (
        <div className="image_container" style={{ position: 'relative' }} key={i}>
          <img
            src={`https://ugm.oss-cn-beijing.aliyuncs.com/${image}?x-oss-process=image/resize,${this.state.height}`}
            onClick={e => this.clickImg(i, e)}
            onMouseOver={e => this.overImg(image, e)}
            onMouseLeave={() => {
              this.setState({ activeImage: '' })
            }}
          />
          <img
            src={`https://ugm.oss-cn-beijing.aliyuncs.com/${image}`}
            style={{
              position: 'absolute',
              width: '500px',
              marginLeft: '20px',
              marginTop: '-100px',
            }}
            hidden={image !== this.state.activeImage}
          />
          {this.props.showDelete ? (
            <Popconfirm
              title="确定删除吗？"
              okText="确定"
              cancelText="取消"
              onConfirm={e => {
                this.confirm(image, e)
              }}
              onCancel={this.cancel}
            >
              <Button type="danger" className="image_dele_btn" size={'small'}>
                删除
              </Button>
            </Popconfirm>
          ) : (
            ''
          )}
        </div>
      )
    })
  }

  render() {
    // console.info(this.state.images)
    let images = (this.state.images || []).map(image => {
      return { src: `https://ugm.oss-cn-beijing.aliyuncs.com/${image}` }
    })

    return (
      <div>
        {this.orImages()}
        <Lightbox
          images={images}
          isOpen={this.state.lightboxIsOpen}
          onClickPrev={this.gotoPrevious}
          onClickNext={this.gotoNext}
          onClose={this.closeLightbox}
          currentImage={this.state.currentImage}
        />
      </div>
    )
  }
}
