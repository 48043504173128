import {
  Switch,
  Form,
  Input,
  Radio,
  DatePicker,
  LocaleProvider,
  Cascader,
  Select,
  Row,
  Col,
  Button,
  message,
  AutoComplete,
} from 'antd'
import React from 'react'
// import Images from '../components/images'
import moment from 'moment'
import { Map, Marker } from 'react-amap'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import ImgUpload from '../components/img_upload'
import FileUpload from '../components/file_upload'

const Option = Select.Option
const { TextArea } = Input
const FormItem = Form.Item
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
}
const longItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 3 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
}

export default class Component extends React.Component {
  wrappedMapRef = React.createRef()

  constructor(props) {
    super(props)
    console.log(props)

    let attrs = {}
    console.log('props===', props)
    for (let line of props.stage.form_template) {
      let attr = props.data ? props.data.special_part[line.id] : null
      attrs[`attr_${line.id}`] = attr
    }
    this.state = attrs
    // console.log(this.state, props)
  }

  onChange = attr => {
    return e => {
      if (!e) {
        return this.setState({ [attr]: null })
      }
      console.log(e)
      let val = e.target ? e.target.value : e
      this.setState({ [attr]: val })
    }
  }

  onChangeDate = attr => {
    return e => {
      if (!e) {
        return this.setState({ [attr]: null })
      }
      let val = e.target ? e.target.value : e
      /* val =  */
      this.setState({ [attr]: val })
    }
  }

  renderTemplate() {
    let template = this.props.stage.form_template
    let attrs = this.props.ucase
    return template.map(t => {
      if (['String'].includes(t.dataType)) {
        return (
          <FormItem {...formItemLayout} label={t.name} key={t.id}>
            <Input
              placeholder={'请输入' + t.name}
              onChange={this.onChange(`attr_${t.id}`)}
              value={this.state[`attr_${t.id}`]}
            />
          </FormItem>
        )
      } else if (['Text'].includes(t.dataType)) {
        return (
          <FormItem {...formItemLayout} label={t.name} key={t.id}>
            <Input
              placeholder={'请输入' + t.name}
              onChange={this.onChange(`attr_${t.id}`)}
              value={this.state[`attr_${t.id}`]}
            />
          </FormItem>
        )
      } else if (['Numeric'].includes(t.dataType)) {
        return (
          <FormItem {...formItemLayout} label={t.name} key={t.id}>
            <Input
              placeholder={'请输入' + t.name}
              onChange={this.onChange(`attr_${t.id}`)}
              value={this.state[`attr_${t.id}`]}
            />
          </FormItem>
        )
      } else if (['Enum'].includes(t.dataType)) {
        return (
          <FormItem {...formItemLayout} label={t.name} key={t.id}>
            <Select
              placeholder={`请选择${t.name}`}
              onChange={this.onChange(`attr_${t.id}`)}
              value={this.state[`attr_${t.id}`]}
            >
              {_.map(t.enums, (v, k) => (
                <Option value={k} key={k}>
                  {v}
                </Option>
              ))}
            </Select>
          </FormItem>
        )
      } else if (['Date'].includes(t.dataType)) {
        return (
          <FormItem {...formItemLayout} label={t.name} key={t.id}>
            <DatePicker
              placeholder={`请选择${t.name}`}
              onChange={this.onChange(`attr_${t.id}`)}
              value={moment(this.state[`attr_${t.id}`])}
            />
          </FormItem>
        )
      } else if (['Boolean'].includes(t.dataType)) {
        return (
          <FormItem {...formItemLayout} label={t.name} key={t.id}>
            <Switch
              defaultChecked
              checked={this.state[`attr_${t.id}`]}
              onChange={this.onChange(`attr_${t.id}`)}
            />
          </FormItem>
        )
      } else if (['Image'].includes(t.dataType)) {
        return (
          <FormItem {...formItemLayout} label={t.name} key={t.id}>
            <ImgUpload
              images={this.state[`attr_${t.id}`]}
              onChange={this.onChange(`attr_${t.id}`)}
            />
          </FormItem>
        )
      } else if (['File'].includes(t.dataType)) {
        return (
          <FormItem {...formItemLayout} label={t.name} key={t.id}>
            <FileUpload
              accept={t.format}
              files={this.state[`attr_${t.id}`]}
              onChange={this.onChange(`attr_${t.id}`)}
            />
          </FormItem>
        )
      } else if (['Lnglat'].includes(t.dataType)) {
        return (
          <div style={{ width: '700px', height: '300px', marginLeft: '18%', marginBottom: '15px' }}>
            <Map
              amapkey={'d57b050e830566009d1332a076231223'}
              mapStyle={'amap://styles/a59593ff89ee3597dbc63eb987f5a36f'}
              center={t.value ? t.value : ''}
              zoom={12}
              version={'1.4.9'}
            >
              {t.value ? <Marker position={x.lnglat} /> : ''}
            </Map>
          </div>
        )
      } else {
        return <div key={t.id}>{t.dataType}</div>
      }
    })
  }

  submit = async () => {
    let attrs = {}
    for (let attr in this.state) {
      if (_.startsWith(attr, 'attr_')) {
        let id = attr.replace('attr_', '')
        attrs[id] = this.state[attr]
      }
    }
    let res
    try {
      if (this.props.data) {
        res = await axios.put(
          `/ucases/${this.props.ucaseId}/stages/${this.props.stage.id}.json?form_id=${this.props.data.id}`,
          {
            stage: { special_part: attrs },
          }
        )
      } else {
        res = await axios.post(`/ucases/${this.props.ucaseId}/stages.json`, {
          stage: { special_part: attrs, stage: this.props.stage.id },
        })
      }
      console.log('res==', res)
      if (res.data.code == 200) {
        location.reload()
        // emitter.emit('alert', { message: '保存成功', type: 'info' })
      } else {
        message.error(res.data.msg.status)
      }
    } catch (error) {
      console.log(error, res)
    }
  }
  cancel = () => {
    Turbolinks.visit('/attr_books')
  }
  render() {
    return (
      <LocaleProvider locale={zh_CN}>
        <Form style={{ backgroundColor: '#fff', paddingLeft: 30, paddingBottom: 30 }}>
          <Row>
            {this.renderTemplate()}
            <Col span={24}>
              <FormItem className="form_btn">
                <Button type="primary" style={{ marginRight: 20 }} onClick={this.submit}>
                  保存
                </Button>
                {/* <Button onClick={this.cancel}>取消</Button> */}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </LocaleProvider>
    )
  }
}
