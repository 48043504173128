import React from 'react'
import Axios from 'axios'
// 引入 ECharts 主模块
import echarts from 'echarts/lib/echarts'
// 引入柱状图
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/pie'
import 'echarts/lib/chart/line'
// 引入提示框和标题组件
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/title'
import 'echarts/lib/component/legend'

export default class Component extends React.Component {
  componentDidMount() {
    this.getJson()
  }
  state = {
    五里坨街道: '',
    八宝山街道: '',
    八角街道: '',
    古城街道: '',
    广宁街道: '',
    老山街道: '',
    苹果园街道: '',
    金顶街街道: '',
    鲁谷社区: '',
  }
  getJson = async () => {
    let json = this.props.data
    let jiedao = json.JieDaoList
		let qiantou = json.WeiBanJuList
		let totalcase = 0
		let totalpoint = 0
		jiedao.map(x=>{
			totalcase = totalcase + x.ucaseSize
			totalpoint = totalpoint + x.pointSize
			this.setState({
				[x.name]:x.ucaseSize
			})
		})
    this.setState({
			totalcase,
			totalpoint,
      qiantou,
		})
		setTimeout(()=>{
			this.qiantouChart(qiantou)
		},20)  
    this.bar(jiedao)
    this.setCaseNum(json.trend)
    this.setBar()
  }
  qiantouChart = count => {
    // 牵头单位pie
    for (let i = 0; i < count.length; i++) {
      let qiantou = echarts.init(document.getElementById(`data-qiantou-pie${i + 1}`))
      let option2 = {
        series: [
          {
            type: 'pie',
            radius: '55%',
            center: ['40%', '30%'],
            top: '0px',
            data: [
              {
                value: count[i].ucaseSize,
                itemStyle: {
                  normal: {
                    color: '#009CFF',
                  },
                },
              },
              {
                value: 100 - count[i].ucaseSize,
                itemStyle: {
                  normal: {
                    color: '#052F62',
                  },
                },
              },
            ],
            label: {
              normal: {
                show: false,
              },
              emphasis: {
                show: true,
              },
            },
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      }
      qiantou.setOption(option2)
    }
  }
  bar = list => {
		let name = []
		let data = []
		let newlist = list.filter(x=>{
			return x.name != '举报电话'
		})
		newlist.map(l=>{
			name.push(l.name)
			data.push({
				value: (l.rateSize * 100).toFixed(2),
				num: l.pointSize,
				count: l.ucaseSize,
			})
		})
    var jiedao = echarts.init(document.getElementById('jiedao'))
    jiedao.setOption({
      tooltip: {
        show: true,
        trigger: 'axis',
        axisPointer: {
          type: 'line',
        },
      },
      grid: {
        top: '5%',
        bottom: '1%',
        left: '0px',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'value',
          max: 140,
          axisLabel: {
            show: false,
            interval: 1,
            textStyle: {
              color: '#fff',
              fontSize: 12,
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#44D3FF',
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
      ],
      yAxis: [
        {
          type: 'category',
          data: name.reverse(),
          axisLine: {
            show: true,
            lineStyle: {
              color: '#44D3FF',
            },
          },
          axisLabel: {
            color: '#44D3FF',
            formatter: function(value) {
              return value
            },
            rich: {
              value: {
                align: 'center',
                padding: [0, 0, 0, -5],
                fontSize: 14,
              },
            },
          },
          axisTick: {
            show: false,
          },
        },
      ],
      series: [
        {
          type: 'bar',
          barWidth: '20%',
          barCategoryGap: '60%',
          itemStyle: {
            normal: {
              barBorderRadius: [10, 10, 10, 10],
              color: '#49D6FF',
            },
          },
          data: data.reverse(),
          label: {
            normal: {
              show: true,
              position: 'right',
              formatter: function(obj) {
                return [
                  '{a|' + obj.data.count + '/}',
                  '{b|' + obj.data.num + '/}',
                  '{c|' + obj.value + '%}',
                ].join('')
              },
              rich: {
                a: {
                  fontSize: 12,
                  align: 'center',
                  color: '#00FF72',
                  height: 20,
                },
                b: {
                  fontSize: 12,
                  align: 'center',
                  height: 20,
                  color: '#FFC80B',
                },
                c: {
                  fontSize: 12,
                  align: 'center',
                  height: 20,
                  color: '#47D5FF',
                },
              },
            },
          },
        },
      ],
    })
  }
  qiantou = () => {
    let arr = this.state.qiantou || []
    let className
    let className2
    let bg
    return arr.map((x, index) => {
      if (index == 0) {
        className = className2 = 'data-yellow'
        bg = 'data-yellow-bg'
      } else if (index == 1 || index == 2) {
        className = className2 = 'data-green'
        bg = 'data-green-bg'
      } else {
        className = 'data-blue'
        className2 = 'data-white'
        bg = ''
      }
      return (
        <div key={index} className="data-qiantou-content">
          <div className={`data-qiantou-index ${bg}`}>{index + 1}</div>
          <div className="data-qiantou-pie" id={`data-qiantou-pie${index + 1}`} />
          <span
            className={className}
            style={{ marginLeft: '15px', width: '40px' }}
          >{`${x.rateSize == 0 ? x.rateSize : (x.rateSize*100).toFixed(2)}%`}</span>
          <span className={className2} style={{ marginLeft: '35px', width: '30%' }}>{`${
            x.name
          }`}</span>
          <span className={className} style={{ marginLeft: '20px', width: '10px' }}>{`${
            x.ucaseSize
          }`}</span>
          <span style={{ marginLeft: '45px', color: '#00BBFF' }}>{`${x.pointSize}`}</span>
        </div>
      )
    })
  }

  setCaseNum = (data) => {
		const color = [
      '#2FC25B',
      '#FFA541',
      '#1890FF',
		]
		const shadow = [
      'rgba(47,194,91,0.3)',
      'rgba(255,165,65,0.3)',
      'rgba(24, 144,255, 0.3)',
    ]
    let name = []
    let time = []
    let series = []
      data.select[0].map(t => {
        time.push(t.time)
      })
      data.select.map((x, i) => {
        name.push(x[0].name)
        series.push({
          name: x[0].name,
          type: 'line',
          smooth: true,
          lineStyle: {
            normal: {
              width: 1,
            },
					},
					areaStyle: {
            normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color:  shadow[i]
                }, {
                    offset: 0.8,
                    color: 'rgba(137, 189, 27, 0)'
                }], false),
            }
        	},
          itemStyle: {
            normal: {
              color: color[i],
            },
          },
          data: x.map(v => {
            return v.value
          }),
        })
      })
    var casenum = echarts.init(document.getElementById('case_num'))
    casenum.setOption({
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          lineStyle: {
            color: '#57617B',
          },
        },
			},
			legend: {
        itemWidth: 14,
        itemHeight: 5,
        itemGap: 10,
        data: name,
        right: '2%',
        textStyle: {
					fontSize: 12,
					color:'#fff'
        },
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        top: '13%',
        containLabel: true,
      },
			xAxis: [
        {
          type: 'category',
          boundaryGap: true,
          axisLine: {
            lineStyle: {
              color: '#57617B',
            },
					},
					axisLabel: {
            show: true,
            textStyle: {
              color: '#00DEFF'
            },
          },
          data: time,
        },
      ],
      yAxis: [
        {
          type: 'value',
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: '#00DEFF',
            },
          },
          axisLabel: {
            margin: 10,
            textStyle: {
              fontSize: 14,
            },
          },
          splitLine: {
            lineStyle: {
              color: '#57617B',
            },
          },
        },
      ],
      series: series
    })
  }

  setBar = () => {
    var manyidu = echarts.init(document.getElementById('manyidu'))
    manyidu.setOption({
      tooltip: {
        trigger: 'axis',
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        top: '9%',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          data: [
            '八宝山',
            '八角',
            '古城',
            '广宁',
            '金顶街',
            '老山',
            '鲁谷社区',
            '苹果园',
            '五里坨',
          ],
          axisLabel: {
            interval: 0,
            rotate: 0,
            show: true,
            splitNumber: 15,
            textStyle: {
              color: '#00DEFF',
              fontSize: 10,
            },
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
        },
      ],
      series: [
        {
          type: 'bar',
          barWidth: '60%',
          data: [95, 95, 95, 95, 95, 95, 95, 95, 95],
          itemStyle: {
            normal: {
              // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              //   {
              //     offset: 0,
              //     color: '#69C4FC',
              //   },
              //   {
              //     offset: 1,
              //     color: '#254DB4',
              //   },
							// ]),
							color:'#00DEFF'
            },
          },
        },
      ],
    })
  }

  render() {
		console.log("props",this.props)
		console.log("state",this.state)
    return (
      <div className="data-bg">
        <div className="data-title-content">
          <span className="data-title">石景山区文明城区测评监测平台</span>
        </div>
        <div className="data-content">
          <div className="data-left" style={{ float: 'left', width: '70%' }}>
            <div className="data-count">
              <div style={{ overflow: 'hidden', marginBottom: '10px' }}>
                <img className="data-title-border" src="/assets/left-border.png" />
                <div className="data-title-bg">街道问题发现情况统计</div>
                <img className="data-title-border" src="/assets/right-border.png" />
              </div>
              <div className="data-count-num">
                <div style={{ paddingTop: '12px' }}>{`${this.state.totalcase}/${this.state.totalpoint}`}</div>
                <div>
									{(this.state.totalcase/this.state.totalpoint *100).toFixed(2)}%
                  <span style={{ fontSize: '14px' }}>(占比)</span>
                </div>
              </div>
            </div>
            <div className="data-jiedao" style={{ width: '550px' }}>
              <div style={{ clear: 'both', width: '100%' }}>
                <div style={{ width: '100%', height: 350, marginTop: 15 }}>
                  <div id="jiedao" style={{ height: '100%' }} />
                </div>
              </div>
            </div>
            <div className="data-map">
              <div className="data-map-bg">
                <div className="data-map-content1">
                  <span>五里坨街道</span>
                  <div className="data-map-num">{this.state.五里坨街道}</div>
                </div>
                <div className="data-map-content2">
                  <span>广宁街道</span>
                  <div className="data-map-num">{this.state.广宁街道}</div>
                </div>
                <div className="data-map-content3">
                  <span>金顶街街道</span>
                  <div className="data-map-num">{this.state.金顶街街道}</div>
                </div>
                <div className="data-map-content4">
                  <span>苹果园街道</span>
                  <div className="data-map-num">{this.state.苹果园街道}</div>
                </div>
                <div className="data-map-content5">
                  <span>古城街道</span>
                  <div className="data-map-num">{this.state.古城街道}</div>
                </div>
                <div className="data-map-content6">
                  <span>八角街道</span>
                  <div className="data-map-num">{this.state.八角街道}</div>
                </div>
                <div className="data-map-content7">
                  <span style={{ float: 'left' }}>老山街道</span>
                  <div
                    className="data-map-num"
                    style={{ float: 'left', marginTop: 0, marginLeft: 5 }}
                  >
                    {this.state.老山街道}
                  </div>
                </div>
                <div className="data-map-content8">
                  <span>鲁谷社区</span>
                  <div className="data-map-num">{this.state.鲁谷社区}</div>
                </div>
                <div className="data-map-content9">
                  <span>八宝山街道</span>
                  <div className="data-map-num">{this.state.八宝山街道}</div>
                </div>
              </div>
            </div>
            <div className="data-liuge">
              <div style={{ overflow: 'hidden' }}>
                <img className="data-title-border" src="/assets/left-border.png" />
                <div className="data-title-bg">案件数量趋势图</div>
                <img className="data-title-border" src="/assets/right-border.png" />
              </div>
              <div id="case_num" style={{ width: '100%', height: '170px' }} />
            </div>
          </div>
          <div className="data-right" style={{ float: 'right', width: '30%' }}>
            <div className="data-qiantou">
              <div style={{ overflow: 'hidden' }}>
                <img className="data-title-border" src="/assets/left-border.png" />
                <div className="data-title-bg">各委办局案件情况</div>
                <img className="data-title-border" src="/assets/right-border.png" />
              </div>
              <div className="data-qiantou-count">{this.qiantou()}</div>
            </div>
            <div className="data-tudi">
              <div style={{ overflow: 'hidden' }}>
                <img className="data-title-border" src="/assets/left-border.png" />
                <div className="data-title-bg">各街道居民满意度情况调查</div>
                <img className="data-title-border" src="/assets/right-border.png" />
              </div>
              <div className="data-tudi-count">
                <div id="manyidu" style={{ width: '100%', height: 170, float: 'left' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
