import { Map, Marker, InfoWindow } from 'react-amap'
import {Button} from 'antd'
import React from 'react'
import moment from 'moment'
import 'moment/locale/zh-cn'

const defPoints = [
  { longitude: 116.191031, latitude: 39.988585 },
  { longitude: 116.389275, latitude: 39.925818 },
  { longitude: 116.287444, latitude: 39.810742 },
  { longitude: 116.481707, latitude: 39.940089 },
  { longitude: 116.410588, latitude: 39.880172 },
  { longitude: 116.394816, latitude: 39.880172 },
  { longitude: 116.416002, latitude: 39.952917 },
]

// config props

export default class Component extends React.Component {
  state = {
    infoVisible: false, 
    extData: {}, 
    display: true
  }
	openInfo = (location, data, target) => {
		//构建信息窗体中显示的内容
		let targetArr = []
		let info = []
		let datacontent = ''
		let detailBtn = ''
		if (data.data == null || data.data == '') {
			datacontent = '暂无数据'
			detailBtn = ''
		} else {
			data.data.map(x => {
				datacontent = datacontent + `<div>${x.name}: ${x.value == null ? '暂无数据' : x.value}</div>`
			})
			detailBtn = `<a href="/standing_books/${
				data.id
				}" target='_blank'><Button class="geo_detail">查看详情</Button></a>`
		}

		info.push(
			`<div class="amap-ext-info">
        <div class="box">
          <div class="arrow">
            <em></em><span></span>
          </div>
          <div class="content"></div>
          ${datacontent}
          <div>
            <div class="show-btn" style="margin-left:80px"> 
              ${detailBtn}
              <Button class="geo_close" style="margin-right:10px;" type="primary" onClick="javascript:hide()">隐 藏</Button>
              <Button class="geo_close" type="primary" onClick="javascript:closeInfo()">关 闭</Button>
            </div>
          </div>
        </div>
      </div>`
		)
		let infoWindow = new AMap.InfoWindow({
			isCustom: true,
			content: info.join(''),
			offset: new AMap.Pixel(-4, -20),
		})
		infoWindow.open(this.map, location)
		window.closeInfo = () => {
			infoWindow.close()
		}
		window.hide = () => {
			this.state.targetArr.push(target)
			this.setState({
				targetArr: this.state.targetArr,
				disabled: false,
			})
			this.props.getAble(false)
			target.hide()
			infoWindow.close()
		}
	}

  constructor() {
    super()
	  this.mapEvents = {
		  created: async (map) => {
			  this.map = map
		  }
	  }

    this.markerEvents = {
      click: (e, f) => {
        console.log(e, f)
        let extData = e.target.getExtData()
        console.log('你点击了这个图标；调用参数为：')
        this.setState({
          infoVisible: true, 
          position: {
            longitude: extData.lnglat[0],
            latitude: extData.lnglat[1]
          },
          extData})
        console.log(this.state)
      },
    }
  }

  renderMarker=(x)=>{
    return <div><img src={this.searchIcon(x)} /></div>
  }

  searchIcon = (x) => {
		if ([1,2].includes(x.stage)) {
			return "/assets/定位-1.png"
		}
		if ([3,4,5].includes(x.stage)) {
			return "/assets/定位-2.png"
		}

		if ([6].includes(x.stage)) {
			return "/assets/定位-3.png"
		}
		// if (x.timeLimit != null) {
		// 	let time_end = x.reformed_at
		// 	if (time_end == null) {
		// 		time_end = new Date()
		// 	}
		// 	let flag = (new Date(time_end).getTime() - new Date(x.inspected_at).getTime()) / 1000 / x.timeLimit
		// 	if (flag > 1) {
		// 		return "/assets/定位-延期.png"
		// 	}
		// }
		return "/assets/定位-普通.png"
	}

  markers = () => {
    let i = 0
    let ucases = this.props.data

    return ucases.map(x => {
      if(x.lnglat) {
        return (<Marker
          render={this.renderMarker(x)}
          key={i++} 
          clickable 
          extData={x}
          bubble={false}
          events={this.markerEvents}
          position={x.lnglat} />)
      } else {
        return null
      }
    })
  }
  componentWillUnmount() {
    this.setState({
      display: false
    })
  }

  onClickClose=() => {
    this.setState({
      infoVisible: false
    })
  }

  center = [116.177358,39.926754]

  render() {
    console.log('props===',this.props)
    // console.log('render map')
    // if (this.props.data && this.props.data[0]) {
    //   var center = this.props.data[0].lnglat
    // }
    // const points = this.props.points
    return (
      this.state.display ? <Map
        amapkey={'d57b050e830566009d1332a076231223'}
        mapStyle={'amap://styles/a59593ff89ee3597dbc63eb987f5a36f'}
        zoom={17}
        center={this.props.data.length !== 0 ? this.props.data[0].lnglat : this.center}
        events={this.mapEvents}
        plugins={['MapType', 'Scale']}
        version={'1.4.9'}
      >
        <InfoWindow
          position={this.state.position}
          visible={this.state.infoVisible}
          isCustom={true}
          offset={[-1, -40]}
        >
          <div className="amap-ext-info">
            <div className="box">
              <div className="arrow">
                <em></em><span></span>
              </div>
              <div className="content"></div>
              <div>{`${t.case}地址: ${this.state.extData.address}`} </div>
              <div>{`报案时间: ${moment(this.state.extData.inspected_at).format('llll')}`}</div>
              <div> {`报案人: ${this.state.extData.creator && this.state.extData.creator.name}`}
                <div className="show-btn"> 
                  <a href={`/ucases/${this.state.extData.id}`} target='_blank'><Button size={'small'} style={{ float:'left',marginRight:10 }}>查看详细</Button></a>
                  <Button size={'small'} style={{ float:'left' }} type="primary" onClick={this.onClickClose} >关闭</Button>
                </div>
              </div>
            </div>
          </div>
        </InfoWindow>
        {this.markers()}
      </Map> : null
    )
  }
}
