import { Input } from 'antd';
import React from "react"
// import zhCN from 'antd/lib/locale-provider/zh_CN'
const Search = Input.Search;

export default class App extends React.Component {

  onSearch = (value) => {
    Turbolinks.visit("?keyword=" + value)
  }

  render() {
    return <div className="PCI-search"><Search
        placeholder="请输入搜索内容"
        onSearch={this.onSearch}
        style={{ width: 200 }}
      />
    </div>
  }
}