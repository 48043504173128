import React from 'react'
import { DatePicker, LocaleProvider, Table, Row, Col, Tooltip, Icon } from 'antd'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import qs from 'query-string'
import moment from 'moment'

const { RangePicker } = DatePicker

export default class Component extends React.Component {
  state = {}
  componentDidMount() {
    let lq = qs.parse(location.search)
    const { st1, st2, et1, et2 } = lq
    this.setState({
      st1,
      st2,
      et1,
      et2,
    })
  }
  onChange = (attr, date, datestring) => {
    if (attr == 'third') {
      this.setState({
        st1: datestring[0],
        et1: datestring[1],
      })
    } else if (attr == 'volunteer') {
      this.setState({
        st2: datestring[0],
        et2: datestring[1],
      })
    }
    setTimeout(() => {
      this.setQuery()
    }, 10)
  }
  setQuery = () => {
    const { st1, st2, et1, et2 } = this.state
    let lq = qs.parse(location.search)
    lq.st1 = st1
    lq.et1 = et1
    lq.st2 = st2
    lq.et2 = et2
    Turbolinks.visit(`?${qs.stringify(lq)}`)
  }
  renderTitle = () => {
    return (
      <span>
        <Tooltip title="反弹率=复核审核不通过数/复核数*100%">
          <Icon type="question-circle" />
        </Tooltip>
        <span style={{ marginLeft: '10px' }}>反弹率</span>
      </span>
    )
  }
  setEndDate = () => {
    let date = new Date()
    const time = date.setDate(date.getDate() - 1)
    return moment(time).format('YYYY-MM-DD')
  }
  setStartDate = () => {
    let date = new Date()
    date.setDate(1)
    let month = parseInt(date.getMonth() + 1)
    let day = date.getDate()
    if (month < 10) {
      month = '0' + month
    }
    if (day < 10) {
      day = '0' + day
    }
    return date.getFullYear() + '-' + month + '-' + day
  }
  render() {
    console.log('props', this.props)
    const columns1 = [
      {
        title: '排名',
        key: 'rank',
        render: (text, record, index) => {
          return <span>{index + 1}</span>
        },
      },
      {
        title: '街镇名称',
        dataIndex: 'street',
        key: 'street',
      },
      {
        title: '审核员审核通过数',
        dataIndex: 'review_pass',
        key: 'review_pass',
        sorter: (a, b) => a.review_pass - b.review_pass,
      },
      {
        title: '区确认通过数',
        dataIndex: 'district_pass',
        key: 'district_pass',
        sorter: (a, b) => a.district_pass - b.district_pass,
      },
      {
        title: '街道整改数',
        dataIndex: 'tweak',
        key: 'tweak',
        sorter: (a, b) => a.tweak - b.tweak,
      },
      {
        title: '检查员复核数',
        dataIndex: 'reinspect',
        key: 'reinspect',
        sorter: (a, b) => a.reinspect - b.reinspect,
      },
      {
        title: '复核审核不通过数',
        dataIndex: 'reinspect_failed',
        key: 'reinspect_failed',
        sorter: (a, b) => a.reinspect_failed - b.reinspect_failed,
      },
      {
        title: this.renderTitle(),
        dataIndex: 'rate',
        key: 'rate',
        sorter: (a, b) => parseInt(a.rate.split('.')[0]) - parseInt(b.rate.split('.')[0]),
      },
    ]
    const columns2 = [
      {
        title: '排名',
        key: 'rank',
        render: (text, record, index) => {
          return <span>{index + 1}</span>
        },
      },
      {
        title: '委办局名称',
        dataIndex: 'street',
        key: 'street',
      },
      {
        title: '审核员审核通过数',
        dataIndex: 'review_pass',
        key: 'review_pass',
        sorter: (a, b) => a.review_pass - b.review_pass,
      },
      {
        title: '区确认通过数',
        dataIndex: 'district_pass',
        key: 'district_pass',
        sorter: (a, b) => a.district_pass - b.district_pass,
      },
      {
        title: '街道整改数',
        dataIndex: 'tweak',
        key: 'tweak',
        sorter: (a, b) => a.tweak - b.tweak,
      },
      {
        title: '检查员复核数',
        dataIndex: 'reinspect',
        key: 'reinspect',
        sorter: (a, b) => a.reinspect - b.reinspect,
      },
      {
        title: '复核审核不通过数',
        dataIndex: 'reinspect_failed',
        key: 'reinspect_failed',
        sorter: (a, b) => a.reinspect_failed - b.reinspect_failed,
      },
      {
        title: this.renderTitle(),
        dataIndex: 'rate',
        key: 'rate',
        sorter: (a, b) => parseInt(a.rate.split('.')[0]) - parseInt(b.rate.split('.')[0]),
      },
    ]
    const columns3 = [
      {
        title: '排名',
        key: 'rank',
        render: (text, record, index) => {
          return <span>{index + 1}</span>
        },
      },
      {
        title: '街镇名称',
        dataIndex: 'street',
        key: 'street',
      },
      {
        title: '志愿者上报案件数',
        dataIndex: 'report',
        key: 'report',
        sorter: (a, b) => a.report - b.report,
      },
      {
        title: '街道审核通过数',
        dataIndex: 'review_pass',
        key: 'review_pass',
        sorter: (a, b) => a.review_pass - b.review_pass,
      },
      {
        title: '街道上报整改数',
        dataIndex: 'tweak',
        key: 'tweak',
        sorter: (a, b) => a.tweak - b.tweak,
      },
      {
        title: '案件延期数',
        dataIndex: 'delay',
        key: 'delay',
        sorter: (a, b) => a.delay - b.delay,
      },
      {
        title: '志愿者复核数',
        dataIndex: 'reinspect',
        key: 'reinspect',
        sorter: (a, b) => a.reinspect - b.reinspect,
      },
      {
        title: '复核不通过数',
        dataIndex: 'reinspect_failed',
        key: 'reinspect_failed',
        sorter: (a, b) => a.reinspect_failed - b.reinspect_failed,
      },
      {
        title: this.renderTitle(),
        dataIndex: 'rate',
        key: 'rate',
        sorter: (a, b) => parseInt(a.rate.split('.')[0]) - parseInt(b.rate.split('.')[0]),
      },
    ]
    const columns4 = [
      {
        title: '排名',
        key: 'rank',
        render: (text, record, index) => {
          return <span>{index + 1}</span>
        },
      },
      {
        title: '委办局名称',
        dataIndex: 'street',
        key: 'street',
      },
      {
        title: '志愿者上报案件数',
        dataIndex: 'report',
        key: 'report',
        sorter: (a, b) => a.report - b.report,
      },
      {
        title: '街道审核通过数',
        dataIndex: 'review_pass',
        key: 'review_pass',
        sorter: (a, b) => a.review_pass - b.review_pass,
      },
      {
        title: '街道上报整改数',
        dataIndex: 'tweak',
        key: 'tweak',
        sorter: (a, b) => a.tweak - b.tweak,
      },
      {
        title: '案件延期数',
        dataIndex: 'delay',
        key: 'delay',
        sorter: (a, b) => a.delay - b.delay,
      },
      {
        title: '志愿者复核数',
        dataIndex: 'reinspect',
        key: 'reinspect',
        sorter: (a, b) => a.reinspect - b.reinspect,
      },
      {
        title: '复核不通过数',
        dataIndex: 'reinspect_failed',
        key: 'reinspect_failed',
        sorter: (a, b) => a.reinspect_failed - b.reinspect_failed,
      },
      {
        title: this.renderTitle(),
        dataIndex: 'rate',
        key: 'rate',
        sorter: (a, b) => parseInt(a.rate.split('.')[0]) - parseInt(b.rate.split('.')[0]),
      },
    ]
    const { summary, third_party, volunteer } = this.props
    const { st1, st2, et1, et2 } = this.state
    return (
      <LocaleProvider locale={zh_CN}>
        <div>
          <div style={{ width: '100%', overflow: 'hidden' }}>
            <div className="index_nav" style={{ width: '58%', float: 'left' }}>
              <div className="index_nav_title">第三方闭环检查报案</div>
              <div className="index_nav_process index_nav_num">
                {this.props.current_user.role == '5' ? (
                  <Row>
                    <Col span={4}>
                      <div className="index_nav_num_title">报案待审核</div>
                      <div className="index_nav_num_text">{summary.third_party.need_review}</div>
                    </Col>
                    <Col span={4}>
                      <div className="index_nav_num_title">待区确认</div>
                      <div className="index_nav_num_text">{summary.third_party.need_confirm}</div>
                    </Col>
                    <Col span={4}>
                      <div className="index_nav_num_title">待整改</div>
                      <div className="index_nav_num_text">{summary.third_party.tweaking}</div>
                    </Col>
                    <Col span={4}>
                      <div className="index_nav_num_title">整改待审核</div>
                      <div className="index_nav_num_text">
                        {summary.third_party.tweaked_need_review}
                      </div>
                    </Col>
                    <Col span={4}>
                      <div className="index_nav_num_title">仲裁待审核</div>
                      <div className="index_nav_num_text">
                        {summary.third_party.arbitrated_need_review}
                      </div>
                    </Col>
                    <Col span={4}>
                      <div className="index_nav_num_title">待复核</div>
                      <div className="index_nav_num_text">{summary.third_party.need_reinspect}</div>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col span={6}>
                      <div className="index_nav_num_title">当前报案待审核总数</div>
                      <div className="index_nav_num_text">{summary.third_party.need_review}</div>
                    </Col>
                    <Col span={6}>
                      <div className="index_nav_num_title">当前待区确认总数</div>
                      <div className="index_nav_num_text">{summary.third_party.need_confirm}</div>
                    </Col>
                    <Col span={6}>
                      <div className="index_nav_num_title">当前待整改总数</div>
                      <div className="index_nav_num_text">{summary.third_party.tweaking}</div>
                    </Col>
                    <Col span={6}>
                      <div className="index_nav_num_title">当前待复核总数</div>
                      <div className="index_nav_num_text">{summary.third_party.need_reinspect}</div>
                    </Col>
                  </Row>
                )}
              </div>
            </div>
            <div className="index_nav" style={{ width: '40%', float: 'right' }}>
              <div className="index_nav_title">志愿者报案</div>
              <div className="index_nav_process index_nav_num">
                {this.props.current_user.role == '5' ? (
                  <Row>
                    <Col span={6}>
                      <div className="index_nav_num_title">报案待审核</div>
                      <div className="index_nav_num_text">{summary.volunteer.need_review}</div>
                    </Col>
                    <Col span={6}>
                      <div className="index_nav_num_title">待整改</div>
                      <div className="index_nav_num_text">{summary.volunteer.tweaking}</div>
                    </Col>
                    <Col span={6}>
                      <div className="index_nav_num_title">仲裁待审核</div>
                      <div className="index_nav_num_text">
                        {summary.volunteer.arbitrated_need_review}
                      </div>
                    </Col>
                    <Col span={6}>
                      <div className="index_nav_num_title">待复核</div>
                      <div className="index_nav_num_text">{summary.volunteer.need_reinspect}</div>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col span={8}>
                      <div className="index_nav_num_title">当前报案待审核总数</div>
                      <div className="index_nav_num_text">{summary.volunteer.need_review}</div>
                    </Col>
                    <Col span={8}>
                      <div className="index_nav_num_title">当前待整改总数</div>
                      <div className="index_nav_num_text">{summary.volunteer.tweaking}</div>
                    </Col>
                    <Col span={8}>
                      <div className="index_nav_num_title">当前待复核总数</div>
                      <div className="index_nav_num_text">{summary.volunteer.need_reinspect}</div>
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          </div>
          <div className="index_nav">
            <div className="index_nav_title">第三方闭环检查流程</div>
            <div className="index_nav_process">
              <div style={{ margin: 'auto', maxWidth: '1300px' }}>
                <div className="index_process_item">
                  <div className="index_process_num">1</div>
                  <div className="index_process_text">审核员安排任务</div>
                  <div className="index_process_line"></div>
                </div>
                <div className="index_process_item">
                  <div className="index_process_num">2</div>
                  <div className="index_process_text">检查员现场报案</div>
                  <div className="index_process_line"></div>
                </div>
                <div className="index_process_item">
                  <div className="index_process_num">3</div>
                  <div className="index_process_text">审核员审核案件</div>
                  <div className="index_process_line"></div>
                </div>
                <div className="index_process_item">
                  <div className="index_process_num">4</div>
                  <div className="index_process_text">区领导确认案件</div>
                  <div className="index_process_line"></div>
                </div>
                <div className="index_process_item">
                  <div className="index_process_num">5</div>
                  <div className="index_process_text">责任单位整改案件</div>
                  <div className="index_process_line"></div>
                </div>
                <div className="index_process_item">
                  <div className="index_process_num">6</div>
                  <div className="index_process_text">检查员现场复核</div>
                  <div className="index_process_line"></div>
                </div>
                <div className="index_process_item">
                  <div className="index_process_num">7</div>
                  <div className="index_process_text">结案</div>
                </div>
              </div>
            </div>
          </div>
          <div className="index_nav">
            <div className="index_nav_title">志愿者上报检查流程</div>
            <div className="index_nav_process">
              <div style={{ margin: 'auto', maxWidth: '1300px' }}>
                <div className="index_process_item">
                  <div className="index_process_num">1</div>
                  <div className="index_process_text">区领导安排志愿者</div>
                  <div className="index_process_line"></div>
                </div>
                <div className="index_process_item">
                  <div className="index_process_num">2</div>
                  <div className="index_process_text">志愿者现场报案</div>
                  <div className="index_process_line"></div>
                </div>
                <div className="index_process_item">
                  <div className="index_process_num">3</div>
                  <div className="index_process_text">街道审核案件</div>
                  <div className="index_process_line"></div>
                </div>
                <div className="index_process_item">
                  <div className="index_process_num">4</div>
                  <div className="index_process_text">街道整改案件</div>
                  <div className="index_process_line"></div>
                </div>
                <div className="index_process_item">
                  <div className="index_process_num">5</div>
                  <div className="index_process_text">志愿者复核案件</div>
                  <div className="index_process_line"></div>
                </div>
                <div className="index_process_item">
                  <div className="index_process_num">6</div>
                  <div className="index_process_text">街道审核复核</div>
                  <div className="index_process_line"></div>
                </div>
                <div className="index_process_item">
                  <div className="index_process_num">7</div>
                  <div className="index_process_text">结案</div>
                </div>
              </div>
            </div>
          </div>
          <div className="index_nav">
            <div className="index_nav_title">
              第三方闭环检查报案
              <RangePicker
                onChange={(date, datestring) => this.onChange('third', date, datestring)}
                style={{ float: 'right', marginTop: '12px' }}
                value={
                  st1
                    ? [moment(st1), moment(et1)]
                    : [moment(this.setStartDate()), moment(this.setEndDate())]
                }
              ></RangePicker>
            </div>
            <div className="index_nav_process index_nav_num">
              <div className="index_nav_num_content">
                <div className="index_nav_num_title">审核员审核通过数</div>
                <div className="index_nav_num_text">{third_party.summary.review_pass}</div>
              </div>
              <div className="index_nav_num_content">
                <div className="index_nav_num_title">区确认通过数</div>
                <div className="index_nav_num_text">{third_party.summary.district_pass}</div>
              </div>
              <div className="index_nav_num_content">
                <div className="index_nav_num_title">街道整改数</div>
                <div className="index_nav_num_text">{third_party.summary.tweak}</div>
              </div>
              <div className="index_nav_num_content">
                <div className="index_nav_num_title">检查员复核数</div>
                <div className="index_nav_num_text">{third_party.summary.reinspect}</div>
              </div>
              <div className="index_nav_num_content">
                <div className="index_nav_num_title">审核员结案数</div>
                <div className="index_nav_num_text">{third_party.summary.finished}</div>
              </div>
            </div>
          </div>
          <div className="index_nav">
            <div className="index_nav_title">街镇统计</div>
            <div className="index_nav_table">
              <Table columns={columns1} dataSource={third_party.street_statistics} />
            </div>
          </div>
          {this.props.current_user.role == '5' ? (
            <div className="index_nav">
              <div className="index_nav_title">委办局统计</div>
              <div className="index_nav_table">
                <Table columns={columns2} dataSource={third_party.office_statistics} />
              </div>
            </div>
          ) : (
            ''
          )}
          <div className="index_nav">
            <div className="index_nav_title">
              志愿者报案统计
              <RangePicker
                onChange={(date, datestring) => this.onChange('volunteer', date, datestring)}
                style={{ float: 'right', marginTop: '12px' }}
                value={
                  st2
                    ? [moment(st2), moment(et2)]
                    : [moment(this.setStartDate()), moment(this.setEndDate())]
                }
              ></RangePicker>
            </div>
            <div className="index_nav_process index_nav_num">
              <div className="index_nav_num_content">
                <div className="index_nav_num_title">志愿者上报案件数</div>
                <div className="index_nav_num_text">{volunteer.summary.report}</div>
              </div>
              <div className="index_nav_num_content">
                <div className="index_nav_num_title">街道审核通过数</div>
                <div className="index_nav_num_text">{volunteer.summary.review_pass}</div>
              </div>
              <div className="index_nav_num_content">
                <div className="index_nav_num_title">街道上报整改数</div>
                <div className="index_nav_num_text">{volunteer.summary.tweak}</div>
              </div>
              <div className="index_nav_num_content">
                <div className="index_nav_num_title">志愿者复核数</div>
                <div className="index_nav_num_text">{volunteer.summary.reinspect}</div>
              </div>
              <div className="index_nav_num_content">
                <div className="index_nav_num_title">街道结案数</div>
                <div className="index_nav_num_text">{volunteer.summary.finished}</div>
              </div>
            </div>
          </div>
          <div className="index_nav">
            <div className="index_nav_title">街镇统计</div>
            <div className="index_nav_table">
              <Table columns={columns3} dataSource={volunteer.street_statistics} />
            </div>
          </div>
          {this.props.current_user.role == '5' ? (
            <div className="index_nav">
              <div className="index_nav_title">委办局统计</div>
              <div className="index_nav_table">
                <Table columns={columns4} dataSource={volunteer.office_statistics} />
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </LocaleProvider>
    )
  }
}
