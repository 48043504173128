import { Alert } from 'antd';
import React from "react"

export default class Component extends React.Component {
  state = {
    visible: false,
  }

  handler = (opts)=>{
    console.log(this)
    this.setState({
      message: opts.message,
      type: opts.type || "success",
      visible: true
    })
    this.handler = emitHandlers.alert
  }

  componentDidMount() {
    emitter.on('alert', this.handler)
    if(this.props.notice) {
      let notice = this.props.notice.split(":")
      setTimeout(()=>{
        emitter.emit('alert', { message: notice[1], type: notice[0] })
      }, 300)
    }
  }

  componentWillUnmount() {
    // console.log(emitter.getListeners())
    if(this.handler) {
      emitter.removeListener('alert', this.handler)
    }
    this.setState({
      visible: false
    })
  }

  handleClose = () => {
    this.setState({ visible: false });
  }

  render() {
    return (
      <div>
        {
          this.state.visible ? (
            <Alert
              message={this.state.message || "消息"}
              type={this.state.type}
              closable
              afterClose={this.handleClose}
            />
          ) : null
        }
      </div>
    );
  }
}
