import { Upload, Icon, Button } from 'antd'
import React from 'react'

export default class PicturesWall extends React.Component {
  state = {
    previewVisible: false,
    previewImage: '',
    host: 'https://ugm.oss-cn-beijing.aliyuncs.com/',
    fileList: [],
  }
  constructor(props) {
    super(props)
    // console.log('PicturesWall', props)
    this.state.files = props.files || []
    props.files &&
      this.state.fileList.push(
        ...props.files.map(([key, name]) => ({
          uid: key,
          name: name,
          status: 'done',
          url: this.state.host + key,
        }))
      )
  }

  handleCancel = () => this.setState({ previewVisible: false })

  handleChange = ({ file, fileList, event }) => {
    // console.log(file, fileList, event)
    if (['done', 'removed'].includes(file.status)) {
      console.log(file)
      const arr = []
      fileList.forEach(p => {
        if (p.status === 'done') {
          const { response } = p
          arr.push(response ? [response.data.key, p.name] : [p.uid, p.name])
          !p.url && (p.url = this.state.host + response.data.key)
        }
      })
      this.props.onChange(arr)
    }
    this.setState({
      fileList: [...fileList],
    })
  }
  // handleRemove = file => {
  //   console.log(file)
  // }
  render() {
    const { previewVisible, previewImage, previewImageUrl, fileList } = this.state
    return (
      <div className="clearfix">
        <Upload
          action="http://101.201.37.28:8009/oss/putFileToOssOrigin"
          name="multipartFile"
          data={{ json: '', username: 'hexinbiao', password: '123' }}
          fileList={fileList}
          onChange={this.handleChange}
          onRemove={this.handleRemove}
        >
          <Button>
            <Icon type="upload" /> Upload
          </Button>
        </Upload>
      </div>
    )
  }
}
