import { Spin, Button, message, Modal } from 'antd'

import React from 'react'
export default class Component extends React.Component {
  state = {
    loading: true,
    list: [],
    btnLoading: false,
  }
  constructor(props) {
    super(props)
  }

  componentWillReceiveProps(props) {
    const { show, id } = props
    console.log(show, id)
    if (!show || !id) {
      return false
    }
    this.state.loading = true
    axios.get('/ucases.json?standing_book_id=' + id).then(({ data }) => {
      console.log(data)
      this.setState({ loading: false, list: data.data })
    })
  }
  handleDeleteStandingBookWithUcases = () => {
    this.setState({ btnLoading: true })
    const idstr = this.state.list.map(p => p.id).join('-')
    return axios
      .delete(`/ucases/${idstr}.json`)
      .then(obj => {
        console.log(obj)
      })
      .then(() => {
        return this.handleDeleteStandingBook()
      })
  }
  handleDeleteStandingBook = () => {
    const { handleSuccess, id } = this.props
    this.setState({ btnLoading: true })
    axios.delete(`/standing_books/${id}.json`).then(obj => {
      console.log(obj)
      if (obj.status == 200) {
        this.setState({
          loading: false,
          btnLoading: false,
          list: [],
        })
        message.success('删除成功！')
        handleSuccess()
      }
    })
  }
  render() {
    const { show, handleCancel } = this.props
    const { loading, list, btnLoading } = this.state
    const cancel = () => {
      this.setState({ list: [] })
      handleCancel()
    }
    return (
      <Modal
        keyboard={false}
        maskClosable={false}
        visible={show}
        // onOk={this.handleOk}
        // confirmLoading={confirmLoading}
        footer={null}
        onCancel={cancel}
      >
        <Spin spinning={loading} size="large" tip="查询案件">
          {list.length ? (
            <div style={{ textAlign: 'center' }}>
              <p style={{ fontSize: '18px' }}>关联问题列表</p>
              {list.map(p => (
                <div key={'aj' + p.id}>
                  <a href={'/ucases/' + p.id} target="_blank">
                    {'< ' + p.id + '号问题' + ' >'}
                  </a>
                </div>
              ))}
              <div style={{ marginTop: 20 }}>
                <Button
                  type="primary"
                  size="large"
                  onClick={this.handleDeleteStandingBook}
                  loading={btnLoading}
                >
                  仅删除台账
                </Button>
                <Button
                  type="danger"
                  size="large"
                  style={{ marginLeft: 20 }}
                  loading={btnLoading}
                  onClick={this.handleDeleteStandingBookWithUcases}
                >
                  同时删除台账和问题
                </Button>
                <Button
                  size="large"
                  style={{ marginLeft: 20 }}
                  onClick={cancel}
                  loading={btnLoading}
                >
                  取消
                </Button>
              </div>
            </div>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <p style={{ fontSize: '18px' }}>确定删除？</p>
              <Button
                type="primary"
                size="large"
                loading={btnLoading}
                onClick={this.handleDeleteStandingBook}
              >
                确定
              </Button>
              <Button size="large" style={{ marginLeft: 40 }} onClick={cancel} loading={btnLoading}>
                取消
              </Button>
            </div>
          )}
        </Spin>
      </Modal>
    )
  }
}
