import React from 'react'
import {
  Switch,
  Form,
  Input,
  Radio,
  DatePicker,
  LocaleProvider,
  Select,
  Row,
  Col,
  Button,
  message,
  InputNumber,
} from 'antd'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import InsSelect from '../components/inspectors_select'
import ImgUpload from '../components/img_upload'
import FileUpload from '../components/file_upload'
import * as qs from 'query-string'
const Option = Select.Option
const { TextArea } = Input

const FormItem = Form.Item

const longItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
}

const RadioGroup = Radio.Group

export default class Component extends React.Component {
  state = {
    transferData: [],
    regulatorsList: [],
    regulators: [],
    responsiblesList: [],
    responsibles: [],
  }

  constructor(props) {
    super(props)
    this.maped_review_status = {}
    props.opts.stage_spec.review_status.forEach(x => {
      this.maped_review_status[x.id] = x
    })
    console.log('maped_review', this.maped_review_status)
  }

  componentDidMount = () => {
    const key = qs.parse(window.location.search).key
    if (!this.props.ucase) {
      return
    }
    let ucase = this.props.ucase
    console.log('ucase', ucase)
    console.log(this.props.ucForms)
    axios
      .get(`/commons/package_regulators?ucase_id=${this.props.ucase.id}&key=${key}`)
      .then(res => {
        if (res.data.code == 200) {
          this.setState({
            regulatorsList: res.data.data,
          })
        }
      })
    axios.get('/commons/common_responsibles').then(res => {
      console.log('rr', res)
      if (res.data.code == 200) {
        this.setState({
          responsiblesList: res.data.data,
        })
      }
    })
    this.setState({
      id: ucase.id,
      reinspector_id: ucase.reinspector_id || ucase.creator_id,
      stage: ucase.stage,
      review_status: ucase.review_status,
    })
  }

  submit = () => {
    console.log('state', this.state.timeLimit)
    const key = qs.parse(window.location.search).key
    let currentForm = this.state[`form_${this.state.stage}_${this.state.review_status}`]
    let ucase = {
      stage: this.state.stage,
      review_status: this.state.review_status,
      deadline_at: this.addDate(this.state.time) || '',
      responsibler_id: this.state.responsibler_id || '',
    }
    if (key == 3) {
      if (
        (ucase.stage == 1 && ucase.review_status == 2 && this.state.regulators.length == 0) ||
        (ucase.stage == 3 && ucase.review_status == 8 && this.state.regulators.length == 0)
      ) {
        message.error('请选择主责单位')
        return
      }
      if (ucase.stage == 1 && ucase.review_status == 2 && this.state.timeLimit == '') {
        message.error('请输入整改时限')
        return
      }
      if (
        ucase.stage == 1 &&
        ucase.review_status == 2 &&
        this.state.timeLimit &&
        this.state.timeLimit.toString().indexOf('.') != -1
      ) {
        message.error('整改时限为整数')
        return
      }
      if (ucase.stage == 1 && ucase.review_status == 2 && !this.state.timeLimit) {
        Object.assign(ucase, { timeLimit: '3' })
      }
      if (ucase.stage == 1 && ucase.review_status == 2 && this.state.timeLimit) {
        Object.assign(ucase, { timeLimit: this.state.timeLimit.toString() })
      }
    } else {
      if (ucase.stage == 3 && ucase.review_status == 8 && this.state.regulators.length == 0) {
        message.error('请选择主责单位')
        return
      }
    }
    if (this.props.current_user.role == '2' || this.props.current_user.role == '5') {
      if (ucase.stage == 2 && ucase.review_status == 2 && this.state.timeLimit == '') {
        message.error('请输入整改时限')
        return
      }
      if (
        ucase.stage == 2 &&
        ucase.review_status == 2 &&
        this.state.timeLimit &&
        this.state.timeLimit.toString().indexOf('.') != -1
      ) {
        message.error('整改时限为整数')
        return
      }
      if (ucase.stage == 2 && ucase.review_status == 2 && !this.state.timeLimit) {
        Object.assign(ucase, { timeLimit: '3' })
      }
      if (ucase.stage == 2 && ucase.review_status == 2 && this.state.timeLimit) {
        Object.assign(ucase, { timeLimit: this.state.timeLimit.toString() })
      }
    }
    if (this.state.regulators.length != 0) {
      Object.assign(ucase, { regulators: this.state.regulators })
    }
    if (this.state.responsibles.length != 0) {
      Object.assign(ucase, { responsibles: this.state.responsibles })
    }
    // if (this.state.timeLimit) {
    //   Object.assign(ucase, { timeLimit: this.state.timeLimit.toString() })
    // }
    if (ucase.review_status == 8) {
      Object.assign(ucase, { review_status: 11 })
    }
    console.log('ucase', ucase)
    if (currentForm) {
      ucase.form = currentForm
    }
    if (this.needReinspector()) {
      ucase.reinspector_id = this.state.reinspector_id
    }
    if (window.location.pathname.indexOf('batch_review') !== -1) {
      const ids = JSON.parse(localStorage.getItem('ids'))
      let hasErr = false
      let errIds = []
      let errmss = ''
      ids.map(id => {
        axios
          .put(`/ucases/${id}/review_update.json?key=${key}`, { ucase }, { withCredentials: true })
          .then(res => {
            console.log('buullll====', res)
            if (res.data.code === 200) {
              hasErr = false
            } else if (res.data.code === 502) {
              hasErr = true
              errmss = '请输入审核不通过原因！'
            } else {
              hasErr = true
              errIds.push(id)
              errmss = '审核操作失败'
            }
          })
      })
      setTimeout(() => {
        if (hasErr) {
          message.destroy()
          message.error(`${errIds.join(',')}${errmss}`)
        } else {
          message.destroy()
          message.success('审核操作成功！')
          Turbolinks.visit(`/ucases${localStorage.getItem('query')}`)
        }
      }, 300)
    } else {
      var res = axios.put(
        `/ucases/${this.state.id}/review_update.json?key=${key}`,
        { ucase },
        { withCredentials: true }
      )
      res.then(({ data }) => {
        if (data.code == 200) {
          message.destroy()
          message.success('审核操作成功！')
          setTimeout(() => {
            Turbolinks.visit(`/ucases?key=${qs.parse(window.location.search).key}`)
          }, 300)
        } else if (data.code == 502) {
          message.destroy()
          message.error('请输入审核不通过原因！')
        } else {
          message.destroy()
          message.error('审核操作失败！')
        }
      })
    }
  }

  onChange = attr => {
    return e => {
      if (!e) {
        return this.setState({ [attr]: null })
      }
      let val = e.target ? e.target.value : e
      this.setState({ [attr]: val })
    }
  }

  onChangeReviewStatus = e => {
    if (!e) {
      return this.setState({ review_status: null })
    }
    let val = e.target ? e.target.value : e
    let crs = this.maped_review_status[val]
    console.log('crs', crs)
    console.log('state', this.state)
    if (crs && crs.form_spec) {
      this.setState({ review_status: val, [`form_${this.state.stage}_${val}`]: {} })
    } else {
      this.setState({ review_status: val })
    }
    if (this.state.stage == 1 && crs.id == 2) {
      this.setState({
        time: 1,
      })
    } else if (this.state.stage == 1 && crs.id == 7) {
      axios.get('/responsiblers/current.json').then(res => {
        console.log('res==', res)
        if (res.data.status == 200) {
          this.setState({
            time: 0,
            transferData: res.data.data,
          })
        }
      })
    } else {
      this.setState({
        time: 0,
      })
    }
  }

  getStage = stageId => {
    for (let stage of this.props.opts.stage_spec.stages) {
      if (stage.id == stageId) return stage
    }
    return this.props.opts.stage_spec.stages[0]
  }

  getNextStage = stageId => {
    let ns = null
    for (let stage of this.props.opts.stage_spec.stages) {
      if (ns) return stage
      if (stage.id == stageId) ns = true
    }
  }

  needReinspector = status => {
    let c_stage = this.getStage(this.state.stage)
    let n_stage = this.getNextStage(this.state.stage)
    if (c_stage.id == 2) {
      return false
    } else if (
      n_stage &&
      n_stage.form_template &&
      c_stage.next &&
      c_stage.next[this.state.review_status]
    ) {
      return true
    }
  }

  cancel = () => {
    if (window.location.pathname.indexOf('batch_review') !== -1) {
      Turbolinks.visit(`/ucases${localStorage.getItem('query')}`)
    }
    // Turbolinks.visit('/ucases')
  }

  stage_filter = arg_stages => {
    console.log('ucase', this.props.ucase)
    console.log('arg', arg_stages)
    let result = arg_stages
    if (this.props.current_user.role == '3') {
      result = arg_stages.filter(x => [2, 3].includes(x.id))
    }
    let query = qs.parse(location.search)
    if (
      !this.props.ucase.is_volunteer_ucase &&
      this.props.current_user.role == '5' &&
      this.props.ucase.extra_status !== '1'
    ) {
      result = arg_stages.filter(x => ![1].includes(x.id))
    }
    return result
  }

  status_display = () => {
    let flag = true
    if (this.props.current_user.role == '3') {
      if (![2, 3].includes(this.state.stage)) {
        flag = false
      }
    }
    return flag
  }

  currentReviewStatusForm = () => {
    let crs = this.maped_review_status[this.state.review_status]
    return crs && crs.form_spec
  }

  onChangeForm = attr => {
    let crs = this.maped_review_status[this.state.review_status]
    return e => {
      if (!e) {
        return this.setState({
          [`form_${this.state.stage}_${crs.id}`]: {
            ...this.state[`form_${this.state.stage}_${crs.id}`],
            [attr]: null,
          },
        })
      }
      let val = e.target ? e.target.value : e
      this.setState({
        [`form_${this.state.stage}_${crs.id}`]: {
          ...this.state[`form_${this.state.stage}_${crs.id}`],
          [attr]: val,
        },
      })
    }
  }

  needTime = status => {
    console.log('status==', status)
    let a_stage = this.getStage(this.state.stage)
    console.log('a_stage', a_stage)
    if (a_stage.id === 1 && status === 2) {
      return true
    }
    return false
  }

  needDeadline = status => {
    const key = qs.parse(window.location.search).key
    console.log('status==', status)
    let a_stage = this.getStage(this.state.stage)
    console.log('a_stage', a_stage)
    if (key == '3') {
      if (a_stage.id === 1 && status === 2) {
        return true
      }
    }
    if (a_stage.id === 2 && status === 2) {
      return true
    }
    return false
  }

  showDeadline = () => {
    return (
      <FormItem {...longItemLayout} label="整改时限(天)">
        <InputNumber onChange={this.onChange('timeLimit')} min={0} defaultValue={3} />
      </FormItem>
    )
  }
  needTransfer = status => {
    console.log('status', status)
    let a_stage = this.getStage(this.state.stage)
    if (a_stage.id === 1 && status === 7) {
      return true
    }
    return false
  }
  needRegulators = status => {
    const key = qs.parse(window.location.search).key
    if (key == 3) {
      if (this.state.stage == 1 && status == 2) {
        return true
      } else if (this.state.stage == 3 && status == 8) {
        return true
      }
      return false
    } else {
      if (this.state.stage == 3 && status == 8) {
        return true
      }
      return false
    }
  }
  chooseRegulators = () => {
    const key = qs.parse(window.location.search).key
    console.log('s', this.state)
    if (key == 2) {
      return (
        <span>
          <FormItem {...longItemLayout} label="选择主责单位">
            <Select
              onChange={this.onChange('regulators')}
              style={{ width: '250px' }}
              placeholder="选择主责单位"
              mode="multiple"
            >
              {this.state.regulatorsList &&
                this.state.regulatorsList.map(t => {
                  return <Option value={t.value}>{t.label}</Option>
                })}
            </Select>
          </FormItem>
          <FormItem {...longItemLayout} label="选择责任单位">
            <Select
              onChange={this.onChange('responsibles')}
              style={{ width: '250px' }}
              placeholder="选择责任单位"
              mode="multiple"
            >
              {this.state.responsiblesList &&
                this.state.responsiblesList.map(t => {
                  return <Option value={t.value}>{t.label}</Option>
                })}
            </Select>
          </FormItem>
        </span>
      )
    }
    return (
      <FormItem {...longItemLayout} label="选择主责单位">
        <Select
          onChange={this.onChange('regulators')}
          style={{ width: '250px' }}
          placeholder="选择主责单位"
          mode="multiple"
        >
          {this.state.regulatorsList &&
            this.state.regulatorsList.map(t => {
              return <Option value={t.value}>{t.label}</Option>
            })}
        </Select>
      </FormItem>
    )
  }
  renderTransfer = () => {
    return (
      <FormItem {...longItemLayout} label="选择转派单位">
        <Select
          onChange={this.onChange('responsibler_id')}
          style={{ width: '250px' }}
          value={this.state.responsibler_id}
          placeholder="选择转派单位"
        >
          {this.state.transferData.map(t => {
            return <Option value={t.id}>{t.name}</Option>
          })}
        </Select>
        <div style={{ color: 'red' }}>注：各单位不能相互推诿，坚决杜绝踢皮球现象</div>
      </FormItem>
    )
  }
  renderAddTime = () => {
    return (
      <span>
        <FormItem {...longItemLayout} label="整改时限">
          <Select
            onChange={this.onChange('time')}
            style={{ width: '250px' }}
            value={this.state.time}
          >
            <Option value={1}>1天</Option>
            <Option value={2}>2天</Option>
            <Option value={3}>3天</Option>
            <Option value={5}>5天</Option>
            <Option value={7}>7天</Option>
            <Option value={15}>15天</Option>
          </Select>
        </FormItem>
        <FormItem {...longItemLayout} label="截止日期">
          {this.addDate(this.state.time)}
        </FormItem>
      </span>
    )
  }

  addDate = (days = 1) => {
    if (days == 0) {
      return null
    }
    const date = new Date()
    date.setDate(date.getDate() + days)
    var month = date.getMonth() + 1
    var day = date.getDate()
    const deadline_at =
      date.getFullYear() + '-' + this.getFormatDate(month) + '-' + this.getFormatDate(day)
    return deadline_at
  }

  getFormatDate = arg => {
    if (arg == undefined || arg == '') {
      return ''
    }
    var re = arg + ''
    if (re.length < 2) {
      re = '0' + re
    }
    return re
  }

  renderTemplate(template) {
    if (!template) {
      return null
    }
    let currentForm = this.state[`form_${this.state.stage}_${this.state.review_status}`] || {}
    return template.map(t => {
      if (['String'].includes(t.dataType)) {
        return (
          <FormItem {...longItemLayout} label={t.name} key={t.id}>
            <Input
              placeholder={'请输入' + t.name}
              onChange={this.onChangeForm(t.id)}
              value={currentForm[t.id]}
            />
          </FormItem>
        )
      } else if (['Text'].includes(t.dataType)) {
        return (
          <FormItem {...longItemLayout} label={t.name} key={t.id}>
            <TextArea
              placeholder={'请输入' + t.name}
              onChange={this.onChangeForm(t.id)}
              value={currentForm[t.id]}
            />
          </FormItem>
        )
      } else if (['Numeric'].includes(t.dataType)) {
        return (
          <FormItem {...longItemLayout} label={t.name} key={t.id}>
            <Input
              placeholder={'请输入' + t.name}
              onChange={this.onChangeForm(t.id)}
              value={currentForm[t.id]}
            />
          </FormItem>
        )
      } else if (['Enum'].includes(t.dataType)) {
        return (
          <FormItem {...longItemLayout} label={t.name} key={t.id}>
            <Select
              placeholder={`请选择${t.name}`}
              onChange={this.onChangeForm(t.id)}
              value={currentForm[t.id]}
            >
              {_.map(t.enums, (v, k) => (
                <Option value={k} key={k}>
                  {v}
                </Option>
              ))}
            </Select>
          </FormItem>
        )
      } else if (['Enum'].includes(t.dataType)) {
        return (
          <FormItem {...longItemLayout} label={t.name} key={t.id}>
            <RadioGroup
              placeholder={`请选择${t.name}`}
              onChange={this.onChangeForm(t.id)}
              value={currentForm[t.id]}
            >
              {_.map(t.selects, sl => (
                <Radio value={sl.id} key={sl.id}>
                  {sl.name}
                </Radio>
              ))}
            </RadioGroup>
          </FormItem>
        )
      } else if (['Date'].includes(t.dataType)) {
        return (
          <FormItem {...longItemLayout} label={t.name} key={t.id}>
            <DatePicker
              format="YYYY-MM-DD HH:mm:ss"
              locale={zh_CN}
              placeholder={`请选择${t.name}`}
              onChange={this.onChangeForm(t.id)}
              value={moment(currentForm[t.id] || new Date())}
            />
          </FormItem>
        )
      } else if (['Boolean'].includes(t.dataType)) {
        return (
          <FormItem {...longItemLayout} label={t.name} key={t.id}>
            <Switch defaultChecked checked={currentForm[t.id]} onChange={this.onChangeForm(t.id)} />
          </FormItem>
        )
      } else if (['Image'].includes(t.dataType)) {
        return (
          <FormItem {...longItemLayout} label={t.name} key={t.id}>
            <ImgUpload images={currentForm[t.id]} onChange={this.onChangeForm(t.id)} />
          </FormItem>
        )
      } else if (['File'].includes(t.dataType)) {
        return (
          <FormItem {...longItemLayout} label={t.name} key={t.id}>
            <FileUpload
              accept={t.format}
              files={currentForm[t.id]}
              onChange={this.onChangeForm(t.id)}
            />
          </FormItem>
        )
      } else if (['Lnglat'].includes(t.dataType)) {
        return (
          <div style={{ width: '700px', height: '300px', marginLeft: '18%', marginBottom: '15px' }}>
            <Map
              amapkey={'d57b050e830566009d1332a076231223'}
              mapStyle={'amap://styles/a59593ff89ee3597dbc63eb987f5a36f'}
              center={t.value ? t.value : ''}
              zoom={12}
              version={'1.4.9'}
            >
              {t.value ? <Marker position={x.lnglat} /> : ''}
            </Map>
          </div>
        )
      } else {
        return <div key={t.id}>{t.dataType}</div>
      }
    })
  }

  render() {
    const key = qs.parse(window.location.search).key
    console.log('state', this.state)
    console.log('props', this.props)
    return (
      <Form style={{ marginTop: 30 }}>
        <Row>
          {this.props.ucase.is_volunteer_ucase ? (
            <Col offset={3}>
              <span style={{ color: 'red' }}>
                请各责任单位认真研判上报问题，及时整改，严禁任何形式的弄虚作假，一经发现，严肃处理！
              </span>
            </Col>
          ) : (
            ''
          )}
          {this.status_display() ? (
            <FormItem {...longItemLayout} label={t.case + '阶段'}>
              <RadioGroup onChange={this.onChange('stage')} value={this.state.stage}>
                {this.stage_filter(this.props.opts.stage_spec.stages).map(stage => {
                  return (
                    <Radio value={stage.id} key={stage.id}>
                      {stage.name}
                    </Radio>
                  )
                })}
              </RadioGroup>
            </FormItem>
          ) : (
            <div />
          )}

          {this.status_display() ? (
            <FormItem {...longItemLayout} label={t.case + '审核状态'}>
              <RadioGroup onChange={this.onChangeReviewStatus} value={this.state.review_status}>
                {this.getStage(this.state.stage)
                  .status.map(x => {
                    return this.maped_review_status[x]
                  })
                  .map(rs => {
                    return (
                      <Radio value={rs.id} key={rs.id}>
                        {rs.name}
                      </Radio>
                    )
                  })}
              </RadioGroup>
            </FormItem>
          ) : (
            <div />
          )}
          {!this.props.ucase.is_volunteer_ucase && this.props.ucase.extra_status === '0' ? (
            this.needReinspector(this.state.review_status) ? (
              <FormItem {...longItemLayout} label="复查人员(如需复核)">
                <InsSelect
                  chs={this.props.opts.inspectors}
                  onChange={this.onChange('reinspector_id')}
                  value={this.state.reinspector_id}
                  placeholder="请选择复查人员"
                />
              </FormItem>
            ) : (
              <div />
            )
          ) : (
            ''
          )}
          {/* {this.needReinspector(this.state.review_status) ? (
            <FormItem {...longItemLayout} label="复查人员(如需复核)">
              <InsSelect
                chs={this.props.opts.inspectors}
                onChange={this.onChange('reinspector_id')}
                value={this.state.reinspector_id}
                placeholder="请选择复查人员"
              />
            </FormItem>
          ) : (
            <div />
          )} */}
          {this.props.ucase.is_volunteer_ucase
            ? this.needTime(this.state.review_status)
              ? this.renderAddTime()
              : ''
            : ''}
          {this.props.ucase.is_volunteer_ucase
            ? this.needTransfer(this.state.review_status)
              ? this.renderTransfer()
              : ''
            : ''}
          {this.needRegulators(this.state.review_status) ? this.chooseRegulators() : ''}
          {this.renderTemplate(this.currentReviewStatusForm())}
          {this.props.current_user.role == '2' || this.props.current_user.role == '5'
            ? this.needDeadline(this.state.review_status)
              ? this.showDeadline()
              : ''
            : ''}
          {this.status_display() ? (
            <Col span={24}>
              <FormItem className="form_btn">
                <Button type="primary" style={{ marginRight: 20 }} onClick={this.submit}>
                  提交
                </Button>
                <Button onClick={this.cancel}>取消</Button>
              </FormItem>
            </Col>
          ) : (
            <div />
          )}
        </Row>
      </Form>
    )
  }
}
