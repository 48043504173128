import React from 'react'

export default class Component extends React.Component {
  state = {}
  changeTab = target => {
    this.setState({
      target,
    })
  }
  componentWillMount() {
    this.changeTab(0)
  }
  render() {
    let arr = [0, 1, 2, 3, 4]
    let blue_text = ['【城市环境第三方检查】', '【垃圾分类第三方检查】', '【疏解整治第三方监管】', '【旅游秩序第三方暗访】','【河湖管理第三方巡查】']
    let text = [
      '围绕市容、设施、秩序、生态等城市环境相关领域进行日常检查 ',
      '对城市和农村垃圾分类管理情况进行日常检查',
      '对城市疏解整治效果和过程管理进行日常检查',
      '对景区设施和服务、导游服务等旅游秩序进行明察暗访',
      '按照河长制管理要求对河湖管理效果进行巡查考核'
    ]
    return (
      <div className="ugm_plan_content">
        <div className="ugm_plan_title_section">
          <div className="ugm_plan_header">应用领域</div>
          {arr.map((item, index) => {
            return (
              <div
                key={index}
                className={`ugm_plan_title_item ${
                  index == this.state.target ? 'ugm_plan_title_item_active' : ''
                }`}
                onMouseEnter={() => this.changeTab(index)}
                style={{height: '67px',paddingTop: '10px'}}
              >
                <span className="blue_text">{blue_text[index]}</span>
                {text[index]}
              </div>
            )
          })}
        </div>
        <div className="ugm_plan_plan_section">
          <div className="ugm_plan_header">解决方案</div>
          {arr.map((item, index) => {
            if (index == this.state.target) {
              return (
                <div key={index} className="ugm_plan_plan_item">
                  <img src={`/assets/iue_tab${index + 1}.png`} />
                </div>
              )
            }
          })}
        </div>
      </div>
    )
  }
}