import React from 'react'
import CommonTable from './commonTable'

export default class Component extends React.Component {
  constructor() {
    super()
  }
  render() {
    return (
      <div>
        <CommonTable
          data={this.props.dataList.rural}
          month={this.props.month}
          editScore={(id, score, name, total) => {
            this.props.editScore(id, score, name, total)
          }}
          role={this.props.role}
        />
      </div>
    )
  }
}
