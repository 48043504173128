import React from 'react'
import { DatePicker, LocaleProvider, Table } from 'antd'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import qs from 'query-string'
import moment from 'moment'
// 引入 ECharts 主模块
import echarts from 'echarts/lib/echarts'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/bar'
// 引入提示框和标题组件
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/title'

const { RangePicker, MonthPicker } = DatePicker
let columns_jd
let columns_wbj
let columns

export default class Component extends React.Component {
  state = {}
  componentDidMount = () => {
    columns = [
      {
        title: '排名',
        dataIndex: 'sort',
        align: 'left',
        render: p => {
          return <span className="statistics_paiming">{p}</span>
        },
        // width: '30px'
      },
      {
        title: '主责单位',
        dataIndex: 'responsiblerName',
        align: 'left',
      },
      {
        title: '案件数',
        dataIndex: 'ucaseAmount',
        align: 'left',
      },

    ]
    const query = qs.parse(location.search)
    const st1 = query.st1 ? moment(query.st1) : null
    const et = query.et ? moment(query.et) : null
    const st2 = query.st2 ? moment(query.st2) : null
    const et2 = query.et2 ? moment(query.et2) : null
    const type = query.type
    this.setState({
      st1,
      et,
      st2,
      et2,
      type,
    })
    const data = this.props.data
    let name = []
    let value = []
    let point = []
    if (type == undefined) {
      this.setState({
        case_hidden: false,
        wbj_hidden: true,
        jd_hidden: true,
      })
      this.setNum(this.props.trend)
    } else if (type == 'jiedao') {
      columns_jd = [
        {
          title: '街道',
          dataIndex: 'name',
          align: 'right',
        },
        {
          title: '案件数',
          dataIndex: 'ucaseSize',
          align: 'right',
        },
        {
          title: '点位数',
          dataIndex: 'pointSize',
          align: 'right',
        },
        {
          title: '案件发生率',
          dataIndex: 'rateSize',
          align: 'right',
          render: r => {
            if (r != undefined) {
              if (r == 0) {
                return <span>{r}</span>
              }
              return <span>{(r*100).toFixed(2)}%</span>
            }
          },
        },
        {
          title: '排名',
          dataIndex: 'number',
          align: 'right',
          render: p => {
            return <span className="statistics_paiming">{p}</span>
          },
        },
      ]
      let newData = data.filter(d => {
        return d.name != '举报电话'
      })
      newData.map(x => {
        name.push(x.name)
        value.push(x.ucaseSize)
        point.push(x.pointSize)
      })
      this.setState({
        case_hidden: true,
        wbj_hidden: true,
        jd_hidden: false,
      })
      this.setBar('jiedao', name, value,point)
      this.setNum(this.props.trend)
    } else if (type == 'weibanju') {
      columns_wbj = [
        {
          title: '委办局',
          dataIndex: 'name',
          align: 'right',
        },
        {
          title: '案件数',
          dataIndex: 'ucaseSize',
          align: 'right',
        },
        {
          title: '点位数',
          dataIndex: 'pointSize',
          align: 'right',
        },
        {
          title: '案件发生率',
          dataIndex: 'rateSize',
          align: 'right',
          render: r => {
            if (r != undefined) {
              if (r == 0) {
                return <span>{r}</span>
              }
              return <span>{(r*100).toFixed(2)}%</span>
            }
          },
        },
        {
          title: '排名',
          dataIndex: 'number',
          align: 'right',
          render: p => {
            return <span className="statistics_paiming">{p}</span>
          },
        },
      ]
      this.props.data.map(n => {
        name.push(n.name)
        value.push(n.ucaseSize)
        point.push(n.pointSize)
      })
      this.setState({
        case_hidden: true,
        wbj_hidden: false,
        jd_hidden: true,
      })
      this.setBar('weibanju', name, value,point)
      this.setNum(this.props.trend)
    }
  }
  setNum = data => {
    console.log('data', data)
    const color = [
      '#2FC25B',
      '#FFA541',
      '#1890FF',
      '#FF7F50',
      '#FF00FF',
      '#C71585',
      '#DEB887',
      '#7FFF00',
      '#32CD32',
      'red',
      '#FF1493',
      '#9932CC',
      '#7B68EE',
      '#191970',
      '#00BFFF',
      '#48D1CC',
      '#90EE90',
      '#6B8E23',
      '#0000FF',
      '#D2B48C',
      '#FF1493',
      '#FA8072',
      '#CD5C5C',
      '#32CD32',
      '#008080',
      '#ADD8E6',
      '#B0C4DE',
      '#E6E6FA',
      '#4B0082',
      '#FFC0CB',
    ]
    let name = []
    let time = []
    let series = []
    let select = {}
    let unselect = {}
    if (data.selected.length != 0) {
      data.selected[0].map(t => {
        time.push(t.time)
      })
      data.selected.map((x, i) => {
        select[x[0].name] = true
        name.push(x[0].name)
        series.push({
          name: x[0].name,
          type: 'line',
          smooth: true,
          lineStyle: {
            normal: {
              width: 1,
            },
          },
          itemStyle: {
            normal: {
              color: color[i],
            },
          },
          data: x.map(v => {
            return v.value
          }),
        })
      })
    }
    if (data.unselected.length != 0) {
      let newArray = data.unselected.filter(x => {
        return x[0].name != '举报电话'
      })
      newArray.map((x, i) => {
        unselect[x[0].name] = false
        name.push(x[0].name)
        series.push({
          name: x[0].name,
          type: 'line',
          smooth: true,
          lineStyle: {
            normal: {
              width: 1,
            },
          },
          itemStyle: {
            normal: {
              color: color[i + 3],
            },
          },
          data: x.map(v => {
            return v.value
          }),
        })
      })
    }
    // let num = echarts.init(document.getElementById('num'))
    // num.setOption({
    //   tooltip: {
    //     trigger: 'axis',
    //   },
    //   legend: {
    //     itemWidth: 14,
    //     itemHeight: 5,
    //     itemGap: 10,
    //     data: name,
    //     bottom: '0%',
    //     textStyle: {
    //       fontSize: 12,
    //     },
    //     selected: Object.assign(select, unselect),
    //   },
    //   grid: {
    //     left: '3%',
    //     right: '4%',
    //     bottom: '19%',
    //     containLabel: true,
    //   },
    //   xAxis: [
    //     {
    //       type: 'category',
    //       boundaryGap: true,
    //       axisLine: {
    //         lineStyle: {
    //           color: '#57617B',
    //         },
    //       },
    //       data: time,
    //     },
    //   ],
    //   yAxis: [
    //     {
    //       type: 'value',
    //       axisTick: {
    //         show: false,
    //       },
    //       axisLine: {
    //         show: false,
    //         lineStyle: {
    //           color: '#E9E9E9',
    //         },
    //       },
    //       axisLabel: {
    //         margin: 10,
    //         textStyle: {
    //           fontSize: 14,
    //           color: '#000000',
    //         },
    //       },
    //       splitLine: {
    //         lineStyle: {
    //           color: '#E9E9E9',
    //         },
    //       },
    //     },
    //   ],
    //   series: series,
    // })
  }
  setBar = (name, dataName, data,point) => {
    let chart = echarts.init(document.getElementById(name))
    chart.setOption({
      grid: { left: '5%', right: '2%', bottom: '2%', top: '50px', containLabel: true },
      tooltip: {
        formatter: '{b} ({c})',
      },
      xAxis: [
        {
          gridIndex: 0,
          axisTick: { show: false },
          axisLabel: { show: false },
          splitLine: { show: false },
          axisLine: { show: false },
        },
      ],
      yAxis: [
        {
          gridIndex: 0,
          interval: 0,
          data: dataName.reverse(),
          axisTick: { show: false },
          axisLabel: { show: true },
          splitLine: { show: false },
          axisLine: { show: false },
        },
      ],
      series: [
        {
          show: true,
          type: 'bar',
          barGap: '-100%',
          barWidth: '25', 
          itemStyle: {
              normal: {
                  color: '#F0F2F5'
              },
          },
          z: 1,
          data: point.reverse(),
      },
        {
          type: 'bar',
          barGap: '-100%',
          xAxisIndex: 0,
          yAxisIndex: 0,
          barWidth: '25',
          itemStyle: { normal: { color: '#119DF8' } },
          data: data.reverse(),
        },
      ],
    })
  }

  onChange = (date) => {
    // console.log('data', type)
    let query = qs.parse(document.location.search)
    query.et = date.format('YYYY-MM-DD')
    // if (type == 'heji') {
    //   query.st1 = date[0].format('YYYY-MM-DD')
    //   query.et1 = date[1].format('YYYY-MM-DD')
    // }
    // if (type == 'qushi') {
    //   query.st2 = date[0].format('YYYY-MM-DD')
    //   query.et2 = date[1].format('YYYY-MM-DD')
    // }
    document.location.href = `?${qs.stringify(query)}`
  }

  render() {
    console.log('props', this.props)
    const { case_hidden, wbj_hidden, jd_hidden } = this.state
    return (
      <LocaleProvider locale={zh_CN}>
        <div>
          <div style={{ marginBottom: '50px' }}>
            <div className="statistics_title">
              <span>案件合计</span>
              <DatePicker
                onChange={this.onChange}
                className="statistics_time"
                value={this.state.et}
              />
            </div>
            <div className={`statistics_content ${case_hidden ? 'case_hidden' : ''}`}>
              <div className="statistics_sta">
                <span>街道数量</span>
                <br />
                <span style={{ fontSize: '22px', fontWeight: '500' }}>
                  {this.props.data2.streetLength}
                </span>
              </div>
              <div className="statistics_sta">
                <span>点位类型</span>
                <br />
                <span style={{ fontSize: '22px', fontWeight: '500' }}>
                  {this.props.data2.secondAreaLength}
                </span>
              </div>
              <div className="statistics_sta" style={{ border: 'none' }}>
                <span>点位数量</span>
                <br />
                <span style={{ fontSize: '22px', fontWeight: '500' }}>
                  {this.props.data2.thirdAreaLength}
                </span>
              </div>
            </div>
            <div className={`statistics_content ${case_hidden ? 'case_hidden' : ''}`}>
              <div className="statistics_sta">
                <span>问题点位</span>
                <br />
                <span style={{ fontSize: '22px', fontWeight: '500' }}>
                  {this.props.data2.errThirdAreaLength}
                </span>
              </div>
              <div className="statistics_sta">
                <span>涉及指标</span>
                <br />
                <span style={{ fontSize: '22px', fontWeight: '500' }}>
                  {this.props.data2.errStandardLength}
                </span>
              </div>
              <div className="statistics_sta" style={{ border: 'none' }}>
                <span>涉及主责单位</span>
                <br />
                <span style={{ fontSize: '22px', fontWeight: '500' }}>
                  {this.props.data2.responsiblerLength}
                </span>
              </div>
            </div>
            {qs.parse(location.search).type == 'jiedao' ? (
              <div className={`${jd_hidden ? 'jd_hidden' : ''}`} style={{ overflow: 'hidden' }}>
                <div id="jiedao" style={{ width: '500px', height: '400px', float: 'left' }} />
                <Table
                  columns={columns_jd}
                  dataSource={this.props.data.filter(x => {
                    return x.name != '举报电话'
                  })}
                  pagination={false}
                  className="statistics_table"
                />
              </div>
            ) : (
              ''
            )}
            {qs.parse(location.search).type == 'weibanju' ? (
              <div className={`${wbj_hidden ? 'wbj_hidden' : ''}`} style={{ overflow: 'hidden' }}>
                <div id="weibanju" style={{ width: '500px', height: '1500px', float: 'left' }} />
                <Table
                  columns={columns_wbj}
                  dataSource={this.props.data}
                  pagination={false}
                  className="statistics_table"
                />
              </div>
            ) : (
              ''
            )}
          </div>
          {/* <div>
            <div className="statistics_title">
              <span>案件数量趋势</span>
              <RangePicker
                onChange={date => this.onChange('qushi', date)}
                className="statistics_time"
                value={[this.state.st2, this.state.et2]}
              />
            </div>
            <div className="statistics_content">
              <div id="num" style={{ width: '1200px', height: '500px' }} />
            </div>
          </div> */}
          <div>
            <Table
              columns={columns}
              dataSource={this.props.data2.part1}
              pagination={false}
              className=""
              style={{ marginTop: '30px' }}
            />
          </div>
        </div>
      </LocaleProvider>
    )
  }
}
