import { Button, Icon, Popconfirm, Divider, Table, LocaleProvider, Modal, Input } from 'antd'
import React from 'react'
import { castArray } from 'lodash'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import List from '../components/normal_list'
import qs from 'query-string'

const { TextArea } = Input

export default class Component extends React.Component {
  state = {
    visible: false,
    data: this.props.users.map(x => {
      x.key = x.id
      return x
    }),
    bulkable: true,
  }
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(selectedRowKeys, selectedRows)
      if (selectedRowKeys.length > 0) {
        this.setState({ bulkable: false, selectedRowKeys, selectedRows })
      } else {
        this.setState({ bulkable: true, selectedRowKeys, selectedRows })
      }
    },
  }

  onClickDelete = ids => {
    ids = castArray(ids)
    console.log(ids)
    axios.delete(`/users/${ids.join('-')}.json`).then(data => {
      console.log('data', data)
      if (data.status == 204) {
        Turbolinks.visit('/users')
      } else {
        // 错误提示
      }
    })
  }
  create = () => {
    if (this.props.current_user.role == '5') {
      Turbolinks.visit(`/users/new?type=${qs.parse(window.location.search).type}`)
    } else {
      Turbolinks.visit(`/users/new`)
    }
  }
  bulkadd = () => {
    this.setState({
      visible: true,
    })
  }
  bulkdel = () => {
    if (this.state.selectedRowKeys.length == 0) {
      return
    }
    this.onClickDelete(this.state.selectedRowKeys)
  }
  onChange = e => {
    console.info(e.target.value)
  }
  handleOk = () => {
    console.log('ok')
    this.setState({
      visible: false,
    })
  }
  handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  render() {
    console.log(this.props)
    const columns = [
      {
        title: '账号',
        align: 'center',
        dataIndex: 'mobile',
      },
      {
        title: '是否激活',
        align: 'center',
        dataIndex: 'active',
        render: active => {
          return <span>{active ? '是' : '否'}</span>
        },
      },
      {
        title: '名称',
        align: 'center',
        dataIndex: 'name',
      },
      {
        title: '角色',
        align: 'center',
        dataIndex: 'role',
        render: role => {
          if (role == 1) {
            return <span>检查员</span>
          } else if (role == 2) {
            return <span>审核员</span>
          } else if (role == 6) {
            return <span>志愿者</span>
          } else if (role == 5) {
            return <span>区账号</span>
          } else if (role == 4) {
            return <span>责任单位</span>
          }
        },
      },
      {
        title: '操作',
        align: 'center',
        key: 'action',
        render: data => {
          return (
            <span>
              <a
                href={
                  this.props.current_user.role == '5'
                    ? `/users/${data.id}/edit?type=${qs.parse(window.location.search).type}`
                    : `/users/${data.id}/edit`
                }
                target="_blank"
              >
                编辑
              </a>
              {data.role == 1 ? (
                <span>
                  <Divider type="vertical" />
                  <a href={`/users/${data.id}/active_path`} >
                    轨迹
                  </a>
                </span>
              ) : null}
              {this.props.current_user.role == '4' ? (
                ''
              ) : (
                <span>
                  <Divider type="vertical" />
                  <Popconfirm
                    title="是否删除?"
                    onConfirm={this.onClickDelete.bind(this, data.id)}
                    onCancel={this.onCancel}
                    okText="是"
                    cancelText="否"
                  >
                    <a href="javascript:void(0)">删除</a>
                  </Popconfirm>
                </span>
              )}
            </span>
          )
        },
      },
    ]
    const operateBox = (
      <div style={{ float: 'left' }}>
        <Button type="primary" onClick={this.create}>
          <Icon type="plus" theme="outlined" />
          新建
        </Button>
      </div>
    )
    return (
      <LocaleProvider locale={zh_CN}>
        <div>
          <List
            operateBox={this.props.current_user.role == '4' ? '' : operateBox}
            rowSelection={this.rowSelection}
            columns={columns}
            dataSource={this.state.data}
            defaultPageSize="10"
          />
          <Modal
            title="批量添加"
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
          >
            <TextArea
              rows={8}
              placeholder="请按照：“账号#密码#是否激活#权限#名称”的顺序编辑添加用户信息，&#13;&#10;如： 18941802243#123#是#管理员#狗蛋 &#13;&#10;18941802243#123#否#检查员#狗子&#13;&#10;(换行即添加新的用户信息)"
              onChange={this.onChange}
            />
          </Modal>
        </div>
      </LocaleProvider>
    )
  }
}
