import { Button, Icon, Table, LocaleProvider, DatePicker } from 'antd'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import React from 'react'
import moment from 'moment'
import qs from 'query-string'

const { RangePicker } = DatePicker
const columns = [
  {
    title: '序号',
    key: 'rank',
    render: (text,record,index)=>{
      return <span>{index + 1}</span>
    }
  },
  {
    title: '上报人员',
    dataIndex: 'name',
    key: 'name',  
  },
  {
    title: '上报案件总数',
    dataIndex: 'total',
    key: 'total',
    sorter: (a, b) => a.total - b.total,
  },
  {
    title: '待审核数量',
    dataIndex: 'need_review',
    key: 'need_review',
    sorter: (a, b) => a.need_review - b.need_review,
  },
  {
    title: '审核通过数量',
    dataIndex: 'review_pass',
    key: 'review_pass',
    sorter: (a, b) => a.review_pass - b.review_pass,
  },
  {
    title: '审核不通过数量',
    dataIndex: 'review_failed',
    key: 'review_failed',
    sorter: (a, b) => a.review_failed - b.review_failed,
  },
  {
    title: '不是问题数量',
    dataIndex: 'not_question',
    key: 'not_question',
    sorter: (a, b) => a.not_question - b.not_question,
  },
  {
    title: '整改中数量',
    dataIndex: 'reinspect',
    key: 'reinspect',
    sorter: (a, b) => a.reinspect - b.reinspect,
	},
	{
    title: '已整改未复核数量',
    dataIndex: 'not_reinspect',
    key: 'not_reinspect',
    sorter: (a, b) => a.not_reinspect - b.not_reinspect,
	},
	{
    title: '复核数量',
    dataIndex: 'tweak_count',
    key: 'tweak_count',
    sorter: (a, b) => a.tweak_count - b.tweak_count,
	},
	{
    title: '结案数量',
    dataIndex: 'finished',
    key: 'finished',
    sorter: (a, b) => a.finished - b.finished,
  },
];

export default class Component extends React.Component {
	state={
		current: this.props.pagis.current
	}
	componentDidMount() {
    let lq = qs.parse(location.search)
    const {st,et} = lq
    this.setState({
      st,
			et
    })
  }
  setEndDate = ()=>{
    let date = new Date()
    const time = date.setDate(date.getDate() - 1)
    return moment(time).format('YYYY-MM-DD')
  }
  setStartDate = ()=>{
    let date = new Date();
    date.setDate(1);
    let month = parseInt(date.getMonth()+1);
    let day = date.getDate();
    if (month < 10) {
        month = '0' + month
    }
    if (day < 10) {
        day = '0' + day
    }
    return date.getFullYear() + '-' + month + '-' + day;
  }
	onChange = (date,datestring)=>{
		console.log(datestring)
		this.setState({
			st: datestring[0],
			et: datestring[1]
		})
		setTimeout(()=>{
			let lq = qs.parse(location.search)
			lq.st = this.state.st
      lq.et = this.state.et
      lq.page = this.state.current
			Turbolinks.visit(`?${qs.stringify(lq)}`)
		},10)
	}
	onChangePage = (current)=>{
		console.log("current",current)
		this.setState({
			current
    })
    setTimeout(()=>{
			let lq = qs.parse(location.search)
			lq.st = this.state.st
      lq.et = this.state.et
      lq.page = this.state.current
			Turbolinks.visit(`?${qs.stringify(lq)}`)
		},10)
	}
  render(){
		console.log("porps==",this.props)
		const {st,et} = this.state
		const {pagis} = this.props
		const pagination = {
			total:pagis.total,
			pagiSize: pagis.pagiSize,
			current: this.state.current,
      onChange: (current)=>this.onChangePage(current)
		}
		return(
			<LocaleProvider locale={zh_CN}>
				<div>
					<div className="opra_container">
						<Button type="primary">
							<a href={`/volunteer/index.xlsx`}><Icon type="download"></Icon>生成全部文件</a>
						</Button>
						<span style={{marginLeft:'20px'}}>共查询到 {this.props.pagis.total} 条数据</span>
						<RangePicker
							style={{float:'right',marginTop:'10px'}}
							onChange={this.onChange}
							value={st ? [moment(st),moment(et)] : [moment(this.setStartDate()),moment(this.setEndDate())]}
						>						
						</RangePicker>
					</div>
					<Table columns={columns} dataSource={this.props.data} pagination={pagination} />
				</div>
			</LocaleProvider>			
		)
	}
}