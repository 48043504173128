import React from 'react'
import { Input, Button, message } from 'antd'
import axios from 'axios'
import qs from 'query-string'

export default class Component extends React.Component {
  state = {}
  onChange = e => {
    this.setState({
      reason: e.target.value,
    })
  }
  submit = () => {
    if (this.state.reason == '' || !this.state.reason) {
      message.error('请填写仲裁理由')
      return
    }
    const key = qs.parse(window.location.href).key
    const query = {
      ucase: {
        stage: 3,
        review_status: 8,
        form: {
          '0': this.state.reason,
        },
      },
    }
    axios.put(`/ucases/${this.props.ucaseId}/review_update.json`, query).then(res => {
      console.log(res)
      if (res.data.code == 200) {
        Turbolinks.visit(`/ucases?key=${key}`)
      }
    })
  }
  render() {
    return (
      <div>
        <Input
          placeholder="请输入申请仲裁理由"
          value={this.state.reason}
          onChange={e => {
            this.setState({
              reason: e.target.value,
            })
          }}
          style={{ margin: '20px 0' }}
        />
        <Button type="primary" onClick={this.submit}>
          提交
        </Button>
      </div>
    )
  }
}
