import {
  Form,
  Input,
  Radio,
  DatePicker,
  LocaleProvider,
  Cascader,
  Select,
  Row,
  Col,
  Button,
  message,
} from 'antd'
import React from 'react'
import * as qs from 'query-string'
import CaseSelect from '../components/case_select'
import Map from './map'
import InsSelect from '../components/inspectors_select'
import Images from '../components/images'
import ImgUpload from '../components/img_upload'
import Review from './review'
import moment from 'moment'
import zh_CN from 'antd/lib/locale-provider/zh_CN'

const { TextArea } = Input
const FormItem = Form.Item
const Option = Select.Option
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
}
const longItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 3 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
}

export default class Component extends React.Component {
  state = { standard_ids: [], oldImages: [], imgs: [], optionList: [] }

  componentDidMount = () => {
    console.log('props', this.props)
    axios.get('/standards/options').then(res => {
      console.log('options', res)
      if (res.data.code === 200) {
        this.setState({
          optionList: res.data.data,
        })
      }
    })
    if (!this.props.ucase) {
      return
    }
    let ucase = this.props.ucase
    let tags = []
    if (ucase.tags.indexOf('重点案件') > -1) {
      tags = ['重点案件']
    } else if (ucase.tags.indexOf('普通案件') > -1) {
      tags = ['普通案件']
    } else {
      tags = []
    }
    this.setState({
      id: ucase.id,
      lnglat: ucase.lnglat,
      status: ucase.status,
      task_id: ucase.task_id,
      oldImages: ucase.images,
      describe: ucase.describe,
      created_at: ucase.created_at,
      //reformed_at: ucase.reformed_at,
      creator_id: ucase.creator_id,
      area_ids: ucase.area_ids,
      standard_ids: ucase.standard_ids && ucase.standard_ids.length > 0 ? ucase.standard_ids : [[]],
      regulators: ucase.regulators,
      responsibles: ucase.responsibles,
      inspected_at: ucase.inspected_at == 0 ? new Date().getTime() : ucase.inspected_at,
      area_text: ucase.area_text,
      options: ucase.option_value,
      tags: tags,
    })
  }
  submitAxios = () => {
    let lnglat
    let tags = []
    if (this.props.ucase) {
      lnglat = this.state.lnglat
    } else if (this.state.lnglat) {
      lnglat = this.state.lnglat.split('#')
    } else {
      lnglat = ''
    }
    let arr = []
    if (this.props.ucase) {
      if (this.props.ucase.tags.indexOf('普通案件') > -1) {
        arr = this.props.ucase.tags.filter(x => {
          x != '普通案件'
        })
        arr.push(this.state.tags)
        arr[0] instanceof Array ? (tags = arr[0]) : (tags = arr)
      } else if (this.props.ucase.tags.indexOf('重点案件') > -1) {
        arr = this.props.ucase.tags.filter(x => {
          x != '重点案件'
        })
        arr.push(this.state.tags)
        arr[0] instanceof Array ? (tags = arr[0]) : (tags = arr)
      } else {
        this.props.ucase.tags.push(this.state.tags)
        tags = this.props.ucase.tags
      }
    } else {
      tags = [this.state.tags]
    }
    let time
    if (this.state.inspected_at) {
      if (typeof this.state.inspected_at == 'number') {
        time = this.state.inspected_at
      } else {
        time = new Date(this.state.inspected_at).getTime()
      }
    }
    let ucase = {
      lnglat,
      // stage_status: this.state.current_review_status,
      images: this.state.oldImages.concat(this.state.images, this.state.newImg),
      task_id: this.state.task_id,
      describe: this.state.describe,
      //reformed_at: this.state.reformed_at,
      creator_id: this.state.creator_id,
      area_ids: this.state.area_ids,
      standard_ids: this.state.standard_ids,
      regulators: this.state.regulators,
      responsibles: this.state.responsibles,
      option_value: this.state.options,
      inspected_at: time || new Date().getTime(),
      area_text: this.state.area_text,
      is_locked: false,
      tags,
    }
    let img = ucase.images.filter(i => {
      return i != undefined
    })
    console.log('i', img)
    if (img.length < 2) {
      message.error('请至少上传两张照片')
      return
    }
    if (this.props.ucase) {
      let _ucase = Object.assign(ucase, { id: this.state.id })
      var res = axios.put(`/ucases/${ucase.id}.json`, { ucase: _ucase }, { withCredentials: true })
    } else {
      var res = axios.post('/ucases.json', { ucase }, { withCredentials: true })
    }
    res.then(({ data }) => {
      console.log(data)
      if (data.code == 200) {
        this.props.ucase
          ? message.success('保存成功!')
          : Turbolinks.visit(`/ucases?key=${qs.parse(window.location.search).key}`)
      } else {
        // 错误提示
      }
    })
  }
  submit = () => {
    if (this.props.ucase.is_volunteer_ucase) {
      // if(this.state.flag == true){
      //   debounce(this.submitAxios)()
      // }
      this.submitAxios()
    } else {
      if (this.state.standard_ids[0].length == 0) {
        message.error('请选择指标')
        return
      } else if (this.state.area_ids.length == 0) {
        message.error('请选择区域')
        return
      }
      // if(this.state.flag == true){
      //   debounce(this.submitAxios)()
      // }
      this.submitAxios()
    }
  }
  // debounce = (func, wait=2000)=>{
  //   this.setState({
  //     flag : false
  //   })
  //   let pretime // 定时器变量
  //   return ()=>{
  //       clearTimeout(pretime)
  //       pretime = setTimeout(()=>{
  //         func()
  //         this.setState({
  //           flag: true
  //         })
  //       },wait)
  //   };
  // }
  delImg = images => {
    console.info('images', images)
    this.setState({
      oldImages: [],
      images,
    })
  }

  onChange = attr => {
    return e => {
      if (!e) {
        return this.setState({ [attr]: null })
      }
      let val = e.target ? e.target.value : e
      this.setState({ [attr]: val })
    }
  }
  cancel = () => {
    Turbolinks.visit(`/ucases?key=${qs.parse(location.search).key}`)
  }

  onChangeStandardIds = i => {
    return std => {
      console.log('std', std)
      this.state.standard_ids[i] = std
      this.state.standard_ids = this.state.standard_ids.filter(x => x && x.length > 0)
      if (this.state.standard_ids.length == 0) {
        this.state.standard_ids = [[]]
      }
      this.setState({
        standard_ids: this.state.standard_ids,
        options: '',
      })
    }
  }

  addStandardIds = () => {
    if (!this.state.standard_ids) {
      this.this.state.standard_ids = []
    }
    console.log(this.state.standard_ids)
    this.state.standard_ids.push(null)
    this.setState({
      standard_ids: this.state.standard_ids,
      options: '',
    })
  }

  // standardsSel = () => {
  //   // if (!this.state.standard_ids) {
  //   //   return ''
  //   // }
  //   return (
  //     <FormItem {...longItemLayout} label="指标">
  //       {this.state.standard_ids.map((std, i) => {
  //         return (
  //           <Cascader
  //             key={i}
  //             placeholder="请选择指标"
  //             options={this.props.opts.standards}
  //             onChange={this.onChangeStandardIds(i)}
  //             value={std}
  //           />
  //         {/* )
  //       })} */}
  //       {/* <Button size="small" icon="plus" onClick={this.addStandardIds}>
  //         {' '}
  //         添加指标
  //       </Button> */}
  //     </FormItem>
  //   )
  // }
  standardsSel = () => {
    if (!this.state.standard_ids) {
      return ''
    }
    return (
      <FormItem {...longItemLayout} label="指标">
        {this.state.standard_ids.map((std, i) => {
          return (
            <Cascader
              key={i}
              placeholder="请选择指标"
              options={this.props.opts.standards}
              onChange={this.onChangeStandardIds(i)}
              value={std}
              disabled={window.location.pathname.indexOf('edit') !== -1}
            />
          )
        })}
        <Button size="small" icon="plus" onClick={this.addStandardIds}>
          {' '}
          添加指标
        </Button>
      </FormItem>
    )
  }
  render() {
    console.log('props===', this.props)
    console.log('state===', this.state)
    return (
      <LocaleProvider locale={zh_CN}>
        <Form style={{ marginTop: 30 }}>
          {qs.parse(location.search).key == '1' ? (
            <Row>
              {this.props.ucase ? (
                <Col span={12}>
                  <FormItem {...formItemLayout} label={t.case + '编号'}>
                    <Input
                      placeholder={`请输入${t.case}编号`}
                      onChange={this.onChange('id')}
                      value={this.state.id}
                      disabled
                    />
                  </FormItem>
                </Col>
              ) : (
                ''
              )}
              <Col span={12}>
                <FormItem {...formItemLayout} label="上报时间">
                  <DatePicker
                    placeholder="请选择上报时间"
                    onChange={this.onChange('inspected_at')}
                    value={moment(this.state.inspected_at)}
                    disabled
                  />
                </FormItem>
              </Col>
              {this.props.ucase ? (
                <Col span={12}>
                  <FormItem {...formItemLayout} label="上报人员">
                    <InsSelect
                      chs={this.props.opts.inspectors}
                      onChange={this.onChange('creator_id')}
                      value={this.state.creator_id}
                      placeholder="请选择上报人员"
                    />
                  </FormItem>
                </Col>
              ) : (
                ''
              )}
              <Col span={24}>
                <FormItem {...longItemLayout} label={t.case + '图片'}>
                  {this.props.ucase && this.props.ucase.images.length != 0 ? (
                    <div className="detail_img" style={{ border: 'none' }}>
                      <div>
                        <Images
                          images={this.props.ucase.images}
                          ucase={this.props.ucase}
                          showDelete={true}
                          delImg={this.delImg}
                        />
                        <ImgUpload
                          images={[]}
                          onChange={imgs => {
                            console.log('imgs', imgs)
                            this.setState({
                              newImg: imgs,
                            })
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <ImgUpload
                      images={[]}
                      onChange={imgs => {
                        console.log('imgs', imgs)
                        this.setState({
                          newImg: imgs,
                        })
                      }}
                    />
                  )}
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem {...longItemLayout} label="描述">
                  <Input
                    placeholder="请输入描述内容"
                    onChange={this.onChange('describe')}
                    value={this.state.describe}
                  />
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem {...longItemLayout} label="定位">
                  {this.props.ucase ? (
                    <div>
                      <div className="task_table_location">
                        {this.props.ucase ? this.props.ucase.address : ''}
                      </div>
                      <div className={'ucase_location'}>
                        <Map data={[this.props.ucase]} />
                      </div>
                    </div>
                  ) : (
                    <Input
                      placeholder="请输入经纬度,用“#”分隔"
                      onChange={this.onChange('lnglat')}
                      value={this.state.lnglat}
                    />
                  )}
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem className="form_btn">
                  <Button type="primary" style={{ marginRight: 20 }} onClick={this.submit}>
                    提交
                  </Button>
                  <Button onClick={this.cancel}>取消</Button>
                </FormItem>
              </Col>
            </Row>
          ) : (
            <Row>
              {this.props.ucase ? (
                <Col span={12}>
                  <FormItem {...formItemLayout} label={t.case + '编号'}>
                    <Input
                      placeholder={`请输入${t.case}编号`}
                      onChange={this.onChange('id')}
                      value={this.state.id}
                      disabled
                    />
                  </FormItem>
                </Col>
              ) : (
                ''
              )}
              {/* {
              this.props.ucase ? 
              <Col span={12}>
                <FormItem {...formItemLayout} label="经纬度">
                  <Input disabled={true} value={this.state.lnglat} />
                </FormItem>
              </Col> : ''
            }            */}
              {/* {this.props.opts.stage_spec ? (
              <Col span={24}>
                <FormItem {...longItemLayout} label={t.case + '状态'}>
                  <RadioGroup value={0} onChange>
                    <Radio value={0}>{this.props.ucase.stage_status} </Radio>
                  </RadioGroup>
                </FormItem>
              </Col>
            ) : (
              <Col span={24}>
                <FormItem {...longItemLayout} label={t.case + '状态'}>
                  <RadioGroup onChange={this.onChange('stage_status')} value={this.state.stage_status}>
                    <Radio value={0}>待审核</Radio>
                    <Radio value={1}>审核未通过</Radio>
                    <Radio value={2}>审核通过待整改</Radio>
                    <Radio value={3}>整改完成待复核</Radio>
                    <Radio value={4}>结案</Radio>
                  </RadioGroup>
                </FormItem>
              </Col>
            )} */}
              <Col span={24}>{this.standardsSel()}</Col>
              {/* <Col span={24}>
              <FormItem {...longItemLayout} label="指标">
                <Cascader
                  placeholder="请选择指标"
                  options={this.props.opts.standards}
                  onChange={this.onChange('standard_ids')}
                  value={this.state.standard_ids}
                />
              </FormItem>
            </Col> */}
              <Col span={12}>
                {/* TODO: 这里要优化啊!!! */}
                {[1, 3].includes(this.props.opts.project.area_type) ? (
                  <FormItem {...formItemLayout} label="台账">
                    <Input
                      disabled={true}
                      value={this.props.ucase ? this.props.ucase.standing_book.name : ''}
                    />
                  </FormItem>
                ) : (
                  <FormItem {...formItemLayout} label="区域">
                    <Cascader
                      placeholder="请选择区域"
                      options={this.props.opts.areas}
                      onChange={this.onChange('area_ids')}
                      value={this.state.area_ids}
                    />
                  </FormItem>
                )}
              </Col>
              {/* <Col span={12}>
              <FormItem {...formItemLayout} label="所属任务">
                <InsSelect
                  chs={this.props.tasks}
                  onChange={this.onChange('task_id')}
                  value={this.state.task_id}
                  placeholder="请选择所属任务"
                />
              </FormItem>
            </Col> */}
              <Col span={12}>
                <FormItem {...formItemLayout} label="上报时间">
                  <DatePicker
                    placeholder="请选择上报时间"
                    onChange={this.onChange('inspected_at')}
                    value={moment(this.state.inspected_at)}
                    disabled
                  />
                </FormItem>
              </Col>
              {this.props.ucase ? (
                <Col span={12}>
                  <FormItem {...formItemLayout} label="上报人员">
                    <InsSelect
                      chs={this.props.opts.inspectors}
                      onChange={this.onChange('creator_id')}
                      value={this.state.creator_id}
                      placeholder="请选择上报人员"
                    />
                  </FormItem>
                </Col>
              ) : (
                ''
              )}
              {/* <Col span={12}>
              <FormItem {...formItemLayout} label="整改时间">
                <DatePicker
                  placeholder="请选择整改时间"
                  onChange={this.onChange('reformed_at')}
                  value={moment(this.state.reformed_at)}
                />
              </FormItem>
            </Col> */}
              {this.props.current_user.role !== '4' ? (
                <span>
                  <Col span={12}>
                    <FormItem {...formItemLayout} label="主责单位">
                      <InsSelect
                        placeholder="请选择主责单位"
                        chs={this.props.opts.regulators}
                        onChange={this.onChange('regulators')}
                        value={this.state.regulators}
                        multiple
                      />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem {...formItemLayout} label="责任单位">
                      <InsSelect
                        placeholder="请选择责任单位"
                        chs={this.props.opts.responsiblers}
                        onChange={this.onChange('responsibles')}
                        value={this.state.responsibles}
                        multiple
                      />
                    </FormItem>
                  </Col>
                </span>
              ) : (
                ''
              )}

              {/* <Col span={12}>
              <FormItem {...formItemLayout} label={`是否为重点案件`}>
              <Select onChange={this.onChange('tags')} value={this.state.tags} placeholder={`请选择是否为重点案件`}>
                <Option value={`重点案件`}>是</Option>
                <Option value={`普通案件`}>否</Option>
              </Select>
              </FormItem>
            </Col> */}
              <Col span={24}>
                <FormItem {...longItemLayout} label="区域名称">
                  <TextArea
                    placeholder="请输入区域名称"
                    autosize={{ minRows: 2, maxRows: 6 }}
                    value={this.state.area_text}
                    onChange={this.onChange('area_text')}
                  />
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem {...longItemLayout} label={t.case + '图片'}>
                  {this.props.ucase && this.props.ucase.images.length != 0 ? (
                    <div className="detail_img" style={{ border: 'none' }}>
                      <div>
                        <Images
                          images={this.props.ucase.images}
                          ucase={this.props.ucase}
                          showDelete={true}
                          delImg={this.delImg}
                        />
                        <ImgUpload
                          images={[]}
                          onChange={imgs => {
                            console.log('imgs', imgs)
                            this.setState({
                              newImg: imgs,
                            })
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <ImgUpload
                      images={[]}
                      onChange={imgs => {
                        console.log('imgs', imgs)
                        this.setState({
                          newImg: imgs,
                        })
                      }}
                    />
                  )}
                </FormItem>
              </Col>
              {(qs.parse(window.location.search).key === '2' ||
                qs.parse(window.location.search).key === '6') && (
                <Col span={24}>
                  <FormItem {...longItemLayout} label="问题描述">
                    <Select
                      showSearch
                      onChange={this.onChange('options')}
                      value={this.state.options}
                      placeholder="请选择问题描述"
                      filterOption={(input, option) => {
                        return option.props.children.indexOf(input) >= 0
                      }}
                      disabled={this.state.standard_ids.length > 1}
                    >
                      {/* {this.state.optionList.map(list => (
                        <Option value={`${list.value}`}>{list.label}</Option>
                      ))} */}
                      {this.state.standard_ids &&
                        this.state.standard_ids.length !== 0 &&
                        this.props.opts.standards.map(standard => {
                          if (this.state.standard_ids[0].indexOf(standard.value) !== -1) {
                            return standard.children.map(sc => {
                              if (this.state.standard_ids[0].indexOf(sc.value) !== -1) {
                                return sc.children.map(scc => {
                                  if (this.state.standard_ids[0].indexOf(scc.value) !== -1) {
                                    return scc.options.map(o => {
                                      return <Option value={`${o.value}`}>{o.label}</Option>
                                    })
                                  }
                                })
                              }
                            })
                          }
                        })}
                    </Select>
                  </FormItem>
                </Col>
              )}

              <Col span={24}>
                <FormItem {...longItemLayout} label="问题补充描述">
                  <Input
                    placeholder="请输入描述内容"
                    onChange={this.onChange('describe')}
                    value={this.state.describe}
                  />
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem {...longItemLayout} label="定位">
                  {this.props.ucase ? (
                    <div>
                      <div className="task_table_location">
                        {this.props.ucase ? this.props.ucase.address : ''}
                      </div>
                      <div className={'ucase_location'}>
                        <Map data={[this.props.ucase]} />
                      </div>
                    </div>
                  ) : (
                    <Input
                      placeholder="请输入经纬度,用“#”分隔"
                      onChange={this.onChange('lnglat')}
                      value={this.state.lnglat}
                    />
                  )}
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem className="form_btn">
                  <Button type="primary" style={{ marginRight: 20 }} onClick={this.submit}>
                    提交
                  </Button>
                  <Button onClick={this.cancel}>取消</Button>
                </FormItem>
              </Col>
            </Row>
          )}
        </Form>
      </LocaleProvider>
    )
  }
}
