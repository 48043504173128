import { Button, Icon, Radio, Popconfirm, message, Divider, Modal, Spin } from 'antd'
import List from '../components/list'
import Map from './map'
import React from 'react'
import { castArray } from 'lodash'
import moment from 'moment'
import Images from '../components/images'
import ModalComponent from '../components/forExportModal'
import * as qs from 'query-string'
import axios from 'axios'
import _ from 'lodash'

const RadioButton = Radio.Button
const RadioGroup = Radio.Group

export default class Component extends React.Component {
  state = {
    data: this.props.data,
    data_all: [],
    bulkable: false,
    selectedRowKeys: [],
    mode: 'list',
    visible: false,
    isMark: 'mark',
  }

  componentDidMount() {}

  onChange = attr => {
    return e => {
      if (!e) {
        return this.setState({ [attr]: null })
      }
      let val = e.target ? e.target.value : e
      if (val == 'map') {
        this.setState({ [attr]: val })
        axios.get('/ucases/get_all.json' + document.location.search).then(res => {
          console.log('ressssss', res)
          if (res.status == 200) {
            this.setState({
              data_all: res.data.data,
            })
          } else {
            // error
          }
        })
      }
      this.setState({ [attr]: val })
    }
  }

  notCaseClick = id => {
    return () => {
      let ucase = {
        stage: 1,
        review_status: 9,
      }
      let res = axios.put(`/ucases/${id}/review_update.json`, { ucase }, { withCredentials: true })
      res.then(({ data }) => {
        if (data.code == 200) {
          message.success('不是问题操作成功！')
          setTimeout(() => {
            location.reload()
          }, 300)
        } else {
          message.error('不是问题操作失败！')
        }
      })
    }
  }

  newCase = () => {
    Turbolinks.visit('/ucases/new')
  }

  onClickDeleteBulk = () => {
    if (this.state.selectedRowKeys.length == 0) {
      return
    }
    this.onClickDelete(this.state.selectedRowKeys)
  }

  handleShowDownloadBox = type => {
    let downloadIds
    if ('selected' === type) {
      downloadIds = this.state.selectedRowKeys
    } else {
      downloadIds = type
    }
    this.setState({
      visible: true,
      downloadIds,
    })
  }

  onClickDelete = rows => {
    rows = castArray(rows)
    axios.delete(`/ucases/${rows.join('-')}.json`).then(({ data }) => {
      if (data.code == 200) {
        this.setState({
          data: this.state.data.filter(x => {
            try {
              return data.data.deleted_ucases.indexOf(x.id.toString()) < 0
            } catch (e) {
              console.log(e)
              return this.state.data
            }
          }),
        })
      } else {
        // 错误提示
      }
    })
  }
  onCancel = () => {
    return
  }
  onStatusChange = (status, rrows) => {
    let rows = rrows && rrows.length > 0 ? rrows : this.state.selectedRowKeys
    if (rows.length == 0) {
      return
    }
    axios
      .patch(`/ucases/${rows.join('-')}/lock.json`, { ucase: { is_locked: status } })
      .then(({ data }) => {
        if (data.code == 200) {
          this.setState({
            data: this.state.data.map(x => {
              try {
                if (data.data.ucase_ids.indexOf(x.id.toString()) >= 0) {
                  x.is_locked = status
                }
              } catch (e) {
                console.log(e)
              }
              return x
            }),
          })
        } else {
          // 错误提示
        }
      })
  }

  onClickLock = rid => {
    return () => this.onStatusChange(true, rid ? [rid] : null)
  }

  onClickUnlock = rid => {
    return () => this.onStatusChange(false, rid ? [rid] : null)
  }

  onSelectChange = (selectedRowKeys, selectedRows) => {
    // if (selectedRowKeys.length > 0) {
    //   this.setState({ bulkable: true, selectedRowKeys, selectedRows })
    // } else {
    //   this.setState({ bulkable: false, selectedRowKeys, selectedRows })
    // }
  }
  onSelect = record => {
    console.log('ls==', JSON.parse(localStorage.getItem('ids')))
    let arr = _.cloneDeep(this.state.selectedRowKeys)
    if (arr.length !== 0) {
      if (arr.indexOf(record.id) === -1) {
        arr.push(record.id)
      } else {
        const index = arr.findIndex(value => {
          return value === record.id
        })
        arr.splice(index, 1)
      }
    } else {
      arr.push(record.id)
    }
    this.setState({
      selectedRowKeys: arr,
    })
    console.log('arr====', arr)
    localStorage.setItem('ids', JSON.stringify(arr))
  }
  onSelectAll = (selected, selectedRows, changeRows) => {
    console.log('dddd', selected, selectedRows, changeRows)
    let arr = _.cloneDeep(this.state.selectedRowKeys)
    if (selected) {
      selectedRows.map(row => {
        if (row) {
          if (arr.indexOf(row.id) === -1) {
            arr.push(row.id)
          }
        }
      })
    } else {
      changeRows.map(row => {
        const index = arr.findIndex(value => {
          return value === row.id
        })
        arr.splice(index, 1)
      })
    }
    this.setState({
      selectedRowKeys: arr,
    })
    localStorage.setItem('ids', JSON.stringify(arr))
  }
  /**
   * downloadBoxHide
   */
  handleDownloadBoxCancel = () => {
    this.setState({
      visible: false,
    })
  }
  setOperate = data => {
    const key = qs.parse(location.search).key
    if (key == '1') {
      return (
        <span>
          <a href={`/ucases/${data.id}?key=1`} target="_blank">
            查看
          </a>
          {this.props.role == '5' ? (
            <span>
              {/* <br />
              <a href={`/ucases/${data.id}/edit?key=1`} target="_blank">
                编辑
              </a> */}
              {data.review_status == 8 ? (
                <span>
                  <br />
                  <a href={`/ucases/${data.id}/reassign?key=1`} target="_blank">
                    重新派发
                  </a>
                </span>
              ) : (
                ''
              )}
            </span>
          ) : (
            ''
          )}
          <br />
          <a href={`/ucases/${data.id}/review?key=${key}`} target="_blank">
            审核
          </a>
          {data.stage == 3 ? (
            <span>
              <br />
              <a href={`/ucases/${data.id}?s3=true&key=${key}`}>提交整改</a>
            </span>
          ) : (
            ''
          )}
          {data.stage == 3 && this.props.role == '4' ? (
            <span>
              <br />
              <a href={`/ucases/${data.id}?s4=true&key=${key}`}>申请仲裁</a>
            </span>
          ) : (
            ''
          )}
          {this.props.role == '5' ? (
            <span>
              <br />
              <Popconfirm
                title="是否删除?"
                onConfirm={e => this.onClickDelete(data.id, e)}
                onCancel={this.onCancel}
                okText="是"
                cancelText="否"
              >
                <a href="javascript:void(0)">删除</a>
              </Popconfirm>
            </span>
          ) : (
            ''
          )}
          {data.stage == 1 && data.review_status == 1 ? (
            <span>
              {' '}
              <br />{' '}
              <a href={'javascript:void(0)'} onClick={this.notCaseClick(data.id)}>
                {' '}
                不是问题{' '}
              </a>{' '}
            </span>
          ) : (
            ''
          )}
        </span>
      )
    } else if (key == '2' || key == '6' || key == '8' || key == '9') {
      return (
        <span>
          <a href={`/ucases/${data.id}?key=${key}`} target="_blank">
            查看
          </a>
          {this.props.role != '4' ? (
            <span>
              {/* <br />
              <a href={`/ucases/${data.id}/edit?key=${key}`} target="_blank">
                编辑
              </a> */}
              {this.props.role !== '2' &&
              (data.review_status == 1 || data.stage == 3 || data.stage == 5) ? (
                <span>
                  <br />
                  <a href={`/ucases/${data.id}/review?key=${key}`} target="_blank">
                    审核
                  </a>
                </span>
              ) : (
                ''
              )}
              {this.props.role === '2' && (
                <span>
                  <br />
                  <a href={`/ucases/${data.id}/review?key=${key}`} target="_blank">
                    审核
                  </a>
                </span>
              )}
              <br />
              <Popconfirm
                title="是否删除?"
                onConfirm={e => this.onClickDelete(data.id, e)}
                onCancel={this.onCancel}
                okText="是"
                cancelText="否"
              >
                <a href="javascript:void(0)">删除</a>
              </Popconfirm>
            </span>
          ) : (
            ''
          )}
          {this.props.role == '4' &&
          data.current_stage.id == 3 &&
          data.current_stage.current_review_status.id == 4 ? (
            <span>
              <br />
              <a href={`/ucases/${data.id}?s3=true&key=${key}`}>提交整改</a>
            </span>
          ) : (
            ''
          )}
          {this.props.role == '4' &&
          data.current_stage.id == 3 &&
          data.current_stage.current_review_status.id == 1 ? (
            <span>
              <br />
              <a href={`/ucases/${data.id}?s3=true&key=${key}`}>提交整改</a>
            </span>
          ) : (
            ''
          )}
          {data.current_stage.id == 1 && data.current_stage.current_review_status.id == 1 ? (
            <span>
              {' '}
              <br />{' '}
              <a href={'javascript:void(0)'} onClick={this.notCaseClick(data.id)}>
                {' '}
                不是问题{' '}
              </a>{' '}
            </span>
          ) : (
            ''
          )}
          {data.stage == 3 && this.props.role == '4' ? (
            <span>
              <br />
              <a href={`/ucases/${data.id}?s4=true&key=${key}`}>申请仲裁</a>
            </span>
          ) : (
            ''
          )}
        </span>
      )
    } else if (key == '3' || key == '7') {
      return (
        <span>
          <a href={`/ucases/${data.id}?key=3`} target="_blank">
            查看
          </a>
          <br />
          <a href={`/ucases/${data.id}/review?key=${key}`} target="_blank">
            审核
          </a>
          {data.current_stage.id == 1 && data.current_stage.current_review_status.id == 3 ? (
            <span>
              {/* <br />
              <a href={`/ucases/${data.id}/edit?key=${key}`} target="_blank">
                编辑
              </a> */}
            </span>
          ) : (
            ''
          )}
          <br />
          <Popconfirm
            title="是否删除?"
            onConfirm={e => this.onClickDelete(data.id, e)}
            onCancel={this.onCancel}
            okText="是"
            cancelText="否"
          >
            <a href="javascript:void(0)">删除</a>
          </Popconfirm>
          {data.current_stage.id == 1 && data.current_stage.current_review_status.id == 1 ? (
            <span>
              {' '}
              <br />{' '}
              <a href={'javascript:void(0)'} onClick={this.notCaseClick(data.id)}>
                {' '}
                不是问题{' '}
              </a>{' '}
            </span>
          ) : (
            ''
          )}
        </span>
      )
    } else if (key == '4') {
      return (
        <span>
          <a href={`/ucases/${data.id}?key=4`} target="_blank">
            查看
          </a>
          {data.stage == 3 && data.review_status == 4 ? (
            <span>
              <br />
              <a href={`/ucases/${data.id}?s3=true&key=${key}`}>上报整改</a>
            </span>
          ) : (
            ''
          )}
          {data.stage == 3 && data.review_status == 1 ? (
            <span>
              <br />
              <a href={`/ucases/${data.id}?s3=true&key=${key}`}>上报整改</a>
            </span>
          ) : (
            ''
          )}
          {data.stage == 3 && this.props.role == '4' ? (
            <span>
              <br />
              <a href={`/ucases/${data.id}?s4=true&key=${key}`}>申请仲裁</a>
            </span>
          ) : (
            ''
          )}
        </span>
      )
    } else if (key == '5') {
      return (
        <span>
          <a href={`/ucases/${data.id}?key=5`} target="_blank">
            查看
          </a>
          {data.review_status == 1 && data.stage == 1 ? (
            <span>
              {/* <br />
              <a href={`/ucases/${data.id}/edit?key=${key}`} target="_blank">
                编辑
              </a> */}
            </span>
          ) : (
            ''
          )}
          {data.current_stage.id == 1 && data.current_stage.current_review_status.id == 3 ? (
            <span>
              {/* <br />
              <a href={`/ucases/${data.id}/edit?key=${key}`} target="_blank">
                编辑
              </a> */}
            </span>
          ) : (
            ''
          )}
          {data.review_status == 1 && data.stage == 3 ? (
            <span>
              <br />
              <a href={`/ucases/${data.id}/review?key=${key}`} target="_blank">
                审核
              </a>
            </span>
          ) : (
            ''
          )}
        </span>
      )
    }
  }
  render() {
    const key = qs.parse(location.search).key
    console.log('sss', this.state)
    const { downloadIds, mode, bulkable, visible, data } = this.state
    const columns = [
      {
        title: '编号',
        key: 'id',
        dataIndex: 'id',
      },
      key == '1'
        ? null
        : {
            title: '指标',
            key: 'standards',
            dataIndex: 'standards',
            render: standards => {
              return standards
                ? standards.reduce((pre, cur) => {
                    cur.forEach((x, i) => {
                      pre.push(<p key={i}>{x}</p>)
                    })
                    return pre
                  }, [])
                : null
            },
          },
      key == '1' || key == '3' || key == '4' || key == '5' || key == '7'
        ? null
        : {
            title: '区域',
            key: 'areas',
            dataIndex: 'areas',
            render: areas => {
              return areas && areas.length != 0
                ? [areas].reduce((pre, cur) => {
                    cur.forEach((x, i) => {
                      pre.push(<p key={i}>{x}</p>)
                    })
                    return pre
                  }, [])
                : null
            },
          },
      {
        title: '区域名称',
        dataIndex: 'area_text',
        key: 'area_text',
      },
      {
        title: '主责单位',
        dataIndex: 'regu',
        key: 'regu',
      },
      // {
      //   title: '台账',
      //   key: 'standing_book',
      //   dataIndex: 'standing_book',
      //   render: standing_book => {
      //     return standing_book ? (
      //       <a href={`/standing_books/${standing_book.id}`}>{standing_book.name || standing_book.artificial_id}</a>
      //     ) : null
      //   },
      // },
      key == '1' || key == '2' || key == '6'
        ? null
        : {
            title: '包干路段',
            key: 'areas',
            dataIndex: 'areas',
            render: areas => {
              return areas && areas.length != 0
                ? [areas].reduce((pre, cur) => {
                    cur.forEach((x, i) => {
                      pre.push(<p key={i}>{x}</p>)
                    })
                    return pre
                  }, [])
                : null
            },
          },
      // key == '1' || key == '2' || key == '6'
      //   ? {
      //       title: '上报人员',
      //       key: 'name',
      //       dataIndex: 'creator.name',
      //     }
      //   : this.props.role == '4'
      //   ? key == '4'
      //     ? null
      //     : {
      //         title: '上报单位',
      //         key: 'name',
      //         dataIndex: 'creator.name',
      //       }
      //   : {
      //       title: '上报单位',
      //       key: 'name',
      //       dataIndex: 'creator.name',
      //     },
      {
        title: '上报时间',
        key: 'time',
        render: data => (
          <span>{data.inspected_at > 0 && moment(data.inspected_at).format('YYYY-MM-DD')}</span>
        ),
      },
      {
        title: '审核通过时间',
        key: 'review_pass_at',
        dataIndex: 'review_pass_at',
        render: time => {
          return <span>{time && moment(time * 1000).format('YYYY-MM-DD  HH:mm:ss')}</span>
        },
      },
      // {
      //   title: '截止时间',
      //   key: 'deadline_at',
      //   dataIndex: 'deadline_at',
      //   render: time => {
      //     return <span>{time && moment(time * 1000).format('YYYY-MM-DD')}</span>
      //   },
      // },
      {
        title: '阶段及审核状态',
        key: 'stage',
        dataIndex: 'current_stage',
        render: current_stage => (
          <span>
            {current_stage == null
              ? null
              : current_stage.current_review_status
              ? `${current_stage.name}-${current_stage.current_review_status.name}`
              : `${current_stage.name}`}
            {/*{current_stage && current_stage.current_review_status*/}
            {/*? `${current_stage.name}-${current_stage.current_review_status.name}`*/}
            {/*: null}*/}
          </span>
        ),
      },
      {
        title: '图片',
        dataIndex: 'images',
        key: 'images',
        width: 100,
        render: images => <Images images={images} height="h_80" />,
      },
      {
        title: '整改后图片',
        dataIndex: 'reformed_images',
        key: 'reformed_images',
        width: 100,
        render: images => <Images images={images} height="h_80" />,
      },
      key == '1' || key == '3' || key == '7'
        ? null
        : {
            title: '问题描述',
            key: 'options',
            dataIndex: 'options',
          },
      {
        title: '问题补充描述',
        key: 'describe',
        dataIndex: 'describe',
        width: 200,
      },
      // {
      //   title: '是否锁定',
      //   key: 'lock',
      //   dataIndex: 'is_locked',
      //   width: 100,
      //   render: data => {
      //     let locked
      //     if (data == true) {
      //       locked = '已锁定'
      //     } else {
      //       locked = '未锁定'
      //     }
      //     return <span>{locked}</span>
      //   },
      // },
      {
        title: '是否延期',
        key: 'delay_flag',
        dataIndex: 'delay_flag',
        render: data => {
          let cn
          if (data == true) {
            cn = 'delay'
          } else {
            cn = 'not-delay'
          }
          return <div className={cn}></div>
        },
      },
      {
        title: '操作',
        width: 100,
        key: 'action',
        render: data => {
          return this.setOperate(data)
        },
      },
    ].filter(x => x)
    return (
      <div>
        <div className="opra_container">
          <div>
            <div style={{ float: 'left' }}>
              <RadioGroup onChange={this.onChange('mode')} value={mode} buttonStyle="solid">
                <RadioButton value="list">列表模式</RadioButton>
                <RadioButton value="map">地图模式</RadioButton>
              </RadioGroup>
              {/* <Button type="primary" onClick={this.newCase} style={{ marginLeft: '15px' }}>
                上报社会监督案件
              </Button> */}
            </div>
            <div style={{ float: 'right' }}>
              {(qs.parse(window.location.search).key === '2' ||
                qs.parse(window.location.search).key === '6') && (
                <Button
                  type="primary"
                  disabled={
                    !qs.parse(window.location.search).review_status ||
                    !qs.parse(window.location.search).stage ||
                    this.state.selectedRowKeys.length === 0
                  }
                  onClick={() => {
                    localStorage.setItem('query', window.location.search)
                    window.location.href = `/ucases/${
                      JSON.parse(localStorage.getItem('ids'))[0]
                    }/batch_review?key=${qs.parse(window.location.search).key}`
                  }}
                  style={{ marginRight: '10px' }}
                >
                  批量操作
                </Button>
              )}
              <Button
                disabled={!this.props.pagis.total}
                type="primary"
                onClick={() => this.handleShowDownloadBox('all')}
              >
                <Icon type="download" theme="outlined" />
                生成全部文件
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button
                disabled={this.state.selectedRowKeys.length === 0}
                onClick={() => this.handleShowDownloadBox('selected')}
              >
                <Icon type="download" theme="outlined" />
                生成所选文件
              </Button>
              &nbsp;&nbsp;
              {this.props.role != '4' ? (
                <Popconfirm
                  title="确认删除吗?"
                  onConfirm={this.onClickDeleteBulk}
                  onCancel={this.onCancel}
                  okText="是"
                  cancelText="否"
                >
                  <Button disabled={this.state.selectedRowKeys.length === 0}>
                    <Icon type="delete" />
                    删除所选
                  </Button>
                </Popconfirm>
              ) : (
                ''
              )}
              <span style={{ lineHeight: '56px', marginLeft: '15px' }}>
                共查询到<span style={{ color: '#008FFF' }}>{this.props.pagis.total}</span> 条
              </span>
            </div>
          </div>
        </div>
        <ModalComponent
          cancel={this.handleDownloadBoxCancel}
          visible={visible}
          ids={downloadIds}
          type="ucases"
        />
        {mode == 'map' ? (
          <div className={'map-list'}>
            <Map data={this.state.data_all} />
          </div>
        ) : (
          <List
            onSelectChange={this.onSelectChange}
            onSelect={this.onSelect}
            onSelectAll={this.onSelectAll}
            columns={columns}
            data={data}
            pagination={this.props.pagis}
            selectedRowKeys={this.state.selectedRowKeys}
          />
        )}
      </div>
    )
  }
}
