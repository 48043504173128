import { Upload, Icon, Modal, message } from 'antd'
import React from 'react'

export default class PicturesWall extends React.Component {
  state = {
    previewVisible: false,
    previewImage: '',
    host: 'https://ugm.oss-cn-beijing.aliyuncs.com/',
    fileList: [],
  }
  constructor(props) {
    super(props)
    // console.log('PicturesWall', props)
    this.state.images = props.images || []
    props.images &&
      this.state.fileList.push(
        ...props.images.map(p => ({
          uid: p,
          name: p,
          status: 'done',
          url: this.state.host + p,
        }))
      )
  }

  handleCancel = () => this.setState({ previewVisible: false })

  handlePreview = file => {
    this.setState({
      previewImage: file.thumbUrl || file.url,
      previewImageUrl: file.url,
      previewVisible: true,
    })
  }

  handleChange = ({ file, fileList, event }) => {
    console.log(file.status)
    if (['done', 'removed'].includes(file.status)) {
      const arr = []
      fileList.forEach(p => {
        if (p.status === 'done') {
          arr.push(p.response ? p.response.data.key : p.name)
          !p.url && (p.url = this.state.host + p.response.data.key)
        }
      })
      this.props.onChange(arr)
    } else if (['error'].includes(file.status)) {
      message.error('上传照片失败')
    }
    this.setState({
      fileList: [...fileList],
    })
  }
  // handleRemove = file => {
  //   console.log(file)
  // }
  render() {
    const { previewVisible, previewImage, previewImageUrl, fileList } = this.state
    return (
      <div className="clearfix">
        <Upload
          accept="image/*"
          action="https://oss.jianchaba.com/oss/putFileToOss"
          name="multipartFile"
          listType="picture-card"
          data={{ json: '', username: 'hexinbiao', password: '123' }}
          fileList={fileList}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
        >
          <div>
            <Icon type="plus" />
            <div className="ant-upload-text">上传</div>
          </div>
        </Upload>
        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
          <a href={previewImageUrl} target="_blank">
            <img alt="example" style={{ width: '100%' }} src={previewImage} />
          </a>
        </Modal>
      </div>
    )
  }
}
