/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Support component names relative to this directory:
import "babel-polyfill"
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
const application = Application.start()
const context = require.context("../stimulus", true, /\.js$/)
application.load(definitionsFromContext(context))

var componentRequireContext = require.context("react", true)
import setupCSRFToken from './javascripts/axios'

var ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(componentRequireContext)
let EventEmitter = require('events')
window.emitter = new EventEmitter
window.emitHandlers = {}

import * as util from './javascripts/util'
window.uugmUtil = util
// setupCSRFToken()
// or
window.addEventListener('DOMContentLoaded', setupCSRFToken)