import { LocaleProvider } from 'antd'
import React from 'react'
import Search from './search'
import List from './list'
import zh_CN from 'antd/lib/locale-provider/zh_CN'

export default class Component extends React.Component {
  render() {
    return (
      <LocaleProvider locale={zh_CN}>
        <div>
          <div>
            <Search
              opts={this.props.opts}
              gbc={this.props.gbc}
              project={this.props.project}
              role={this.props.current_user.role}
              towns={this.props.towns}
            />
            <List
              data={this.props.data}
              pagis={this.props.pagis}
              role={this.props.current_user.role}
              changeMode={this.changeMode}
            />
          </div>
        </div>
      </LocaleProvider>
    )
  }
}
