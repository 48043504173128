import { Button, Icon, Table, Divider } from 'antd'
import List from '../components/list'
import React from 'react'
import moment from 'moment'
import * as qs from 'query-string'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import Axios from 'axios'

export default class Component extends React.Component {
  state = {
    pagination: {
      defaultPageSize: 20,
    },
    dataKeys: null,
    tableColumns: null,
  }
  constructor(props) {
    super(props)
    const { pagination } = this.state
    const { defaultPageSize, dataTemplate, templateStructure } = props
    console.log(templateStructure)
    //默认分页大小
    defaultPageSize != null && (pagination.defaultPageSize = parseInt(props.defaultPageSize))
    //列表模板
    ;({
      dataKeys: this.state.dataKeys,
      tableColumns: this.state.tableColumns,
    } = this.getTableTitles(dataTemplate))
  }

  getTableTitles(dataTemplate) {
    if (!dataTemplate || !dataTemplate.length) {
      return {}
    }

    const dataKeys = []
    const tableColumns = []
    dataTemplate[0].special_part.forEach((p, i) => {
      p.show.includes('list') &&
        (dataKeys.push(i), tableColumns.push({ title: p.name, dataIndex: i, key: 't' + i }))
    })
    this.props.rowOpertion && tableColumns.push(this.props.rowOpertion)
    return { dataKeys, tableColumns }
  }

  componentWillReceiveProps(props) {
    //更新colums
    props.dataTemplate &&
      !this.state.tableColumns &&
      this.setState(this.getTableTitles(props.dataTemplate))
  }

  getDataFromSource() {
    const keys = this.state.dataKeys
    return this.props.dataTemplate.map(p => {
      const obj = {
        key: p.id,
        id: p.id,
        artificial_id: p.artificial_id,
        name: p.name,
        status: p.status,
        url: p.url,
      }
      keys.forEach(key => {
        obj[key] = p.special_part[key].value
      })
      return obj
    })
  }

  onClickDeleteBulk = () => {
    // Turbolinks.visit('/tasks/new')
    console.log('delete')
    if (this.state.selectedRowKeys.length == 0) {
      return
    }
    axios.delete(`/tasks/${this.state.selectedRowKeys.join('-')}.json`).then(({ data }) => {
      console.info(data)
      if (data.code == 200) {
        this.setState({
          data: this.state.data.filter(x => {
            try {
              return data.data.deleted_tasks.indexOf(x.id.toString()) < 0
            } catch (e) {
              console.log(e)
              return this.state.data
            }
          }),
        })
      } else {
        // 错误提示
      }
    })
  }

  defaultOperate(total) {
    return (
      <p style={{ float: 'right', lineHeight: '56px' }}>
        共查询到结果 <span style={{ color: '#008FFF' }}>{total}</span> 条
      </p>
    )
  }
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  }
  render() {
    const pagination = Object.assign(this.state.pagination, this.props.pagination)
    // console.log(pagination)
    const {
      operateBox = this.defaultOperate(pagination.total),
      columns = this.state.tableColumns,
      rowSelection = this.rowSelection,
      tableTitle,
      dataSource = this.getDataFromSource(),
      loading,
      onChange,
      scroll,
    } = this.props

    return (
      <div className={scroll ? 'scroll' : ''}>
        <div className="opra_container">{operateBox}</div>
        <Table
          style={this.props.auto ? this.props.auto : {}}
          rowSelection={columns ? rowSelection : null}
          columns={columns}
          dataSource={dataSource}
          pagination={pagination}
          loading={loading}
          onChange={onChange}
          bordered
          scroll={scroll}
        />
      </div>
    )
  }
}
