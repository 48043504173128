import React from "react"
import { Button,Icon } from 'antd'

export default class Component extends React.Component {
	state={
		arr:[1,2,3,4,5,6],
		count:1
	}
	changeItem = ()=>{
		let newArr=[]
		if(this.state.count == 3){
			this.setState({
				arr:[1,2,3,4,5,6],
				count:1
			})
		}
		else{
			this.state.arr.map((x)=>{
				newArr.push(x+6)
			})
			this.setState({
				arr:newArr,
				count:this.state.count+1
			})
		}	
	}
	render(){
		let title = [
			'垃圾分类第三方检查',
			'餐厨垃圾规范收运第三方检查',
			'海淀区城市管理综合考核评价第三方服务',
			'延庆区河长制管理第三方巡检',
			'顺义区“开墙打洞”整治效果第三方检查',
			'全国旅游市场秩序第三方暗访',				
		]
		let name = [
			'北京市垃圾渣土管理处',
			'北京市城市管理委员会',
			'北京市海淀区城市服务管理指挥中心',
			'北京市延庆区水务局',
			'北京市顺义区工商局',
			'国家旅游局',			
		]
		return(
			<div className="ugm_success">
				{
					this.state.arr.map((item,index)=>{
						return(
							<div key={index} className="ugm_success_section">
								<div className="ugm_success_item">
									<img src={`/assets/iue${item}.png`}/>
									<div className="ugm_success_item_content">
										<div style={{color: '#666666',fontSize: 14}}>{title[item-1]}</div>
										<div style={{color: '#4A4A4A',fontSize: 16}}>{name[item-1]}</div>
									</div>
								</div>
							</div>
						)	
					})
				}
			</div>
		)
	}
}