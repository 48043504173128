import React from 'react'

export default class Component extends React.Component {
  state = {}
  changeTab = target => {
    this.setState({
      target,
    })
  }
  componentWillMount() {
    this.changeTab(0)
  }
  render() {
    let arr = [0, 1, 2]
    let blue_text = ['【农村环境建设检查】', '【农村基础设施普查】', '【农村集体经济清产核资普查】']
    let text = [
      '建立农村环境问题台账，开展农村环境建设质量检查。',
      '开展农村各类农村基础设施普查形成精准普查数据。',
      '全面系统的开展各类农村集体经济清产核资。',
    ]
    return (
      <div className="ugm_plan_content">
        <div className="ugm_plan_title_section">
          <div className="ugm_plan_header">应用领域</div>
          {arr.map((item, index) => {
            return (
              <div
                key={index}
                className={`ugm_plan_title_item ${
                  index == this.state.target ?  'ugm_plan_title_item_active' : ''
                }`}
                style={{height: '112px'}}
                onMouseEnter={() => this.changeTab(index)}
              >
                <span className="blue_text">{blue_text[index]}</span>
                {text[index]}
              </div>
            )
          })}
        </div>
        <div className="ugm_plan_plan_section">
          <div className="ugm_plan_header">解决方案</div>
          {arr.map((item, index) => {
            if (index == this.state.target) {
              return (
                <div key={index} className="ugm_plan_plan_item">
                  <img src={`/assets/ird_tab${index + 1}.png`} />
                </div>
              )
            }
          })}
        </div>
      </div>
    )
  }
}
