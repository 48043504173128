import React from 'react'
import {
  Form,
  Input,
  LocaleProvider,
  Cascader,
  Select,
  Row,
  Col,
  Button,
} from 'antd'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import InsSelect from '../components/inspectors_select'

const { TextArea } = Input
const FormItem = Form.Item
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
}
const Option = Select.Option;
export default class Component extends React.Component {
	state={}
  componentDidMount = () => {
    if (!this.props.project) {
      return
    }
    let project = this.props.project
    let inspectors = []
    let reviewers = []
    project.inspectors.map(x=>{
      inspectors.push(x.id)
    })
    project.reviewers.map(x=>{
      reviewers.push(x.id)
    })
    this.setState({
      name:project.name,
      describe:project.describe,
      area_id:project.area.id,
      standard_id:project.standard.id,
      inspector_ids:inspectors,
      reviewer_ids:reviewers,
      area_type:project.area_type || 1
    })
  }
  onChange = attr => {
    return e => {
      if(!e){ return this.setState({ [attr]: null })}
      let val = e.target ? e.target.value : e
      this.setState({ [attr]: val })
    }
  }
	render(){
		console.log(this.props)
		return(
			<LocaleProvider locale={zh_CN}>
				<div>
					<div className="uugm_title_content" style={{backgroundColor: "#fff",height:"75px",lineHeight: "60px",padding: "0 50px"}}>
					  <div className="uugm_title">项目详情</div>
					  <div className="uugm_back" style={{width:"320px"}}>
					  	<div className="show_btn w-btn" style={{marginTop: "15px",marginLeft: "80px"}}><a href={`/projects/${this.props.project.id}/edit`}>编辑信息</a></div>
					    <a href="/projects" style={{float: "right", marginTop: "15px"}}><div className="show_btn">&lt; 返回列表</div></a>
					  </div>
					</div>
					<Form style={{ backgroundColor: '#fff',paddingLeft: 30,paddingBottom: 30}}>
						<Row>
							<Col span={24}>
								<FormItem {...formItemLayout} label="项目名称">
	                <Input
	                  placeholder="请输入项目名称"
	                  onChange={this.onChange('name')}
	                  value={this.state.name}
	                  disabled={true}
	                />
	              </FormItem>
							</Col>
							<Col span={24}>
								<FormItem {...formItemLayout} label="指标模板">
	                <InsSelect
	                  placeholder="请选择指标模板"
	                  chs={this.props.standards}
	                  onChange={this.onChange('standard_id')}
	                  value={this.state.standard_id}
	                  disabled={true}
	                />
	              </FormItem>
							</Col>
							<Col span={24}>
								<FormItem {...formItemLayout} label="区域类型">
	                {this.state.area_type == 1 ? <span>使用台账</span> : <span>使用区域</span>}
	              </FormItem>
							</Col>
							<Col span={24}>
								<FormItem {...formItemLayout} label="区域模板">
	                <InsSelect
	                  placeholder="请选择区域模板"
	                  chs={this.props.areas}
	                  onChange={this.onChange('area_id')}
	                  value={this.state.area_id}
	                  disabled={true}
	                />
	              </FormItem>
							</Col>
							<Col span={24}>
								<FormItem {...formItemLayout} label="描述">
	                <TextArea placeholder="请输入描述内容" autosize onChange={this.onChange('describe')} value={this.state.describe} disabled={true}/>
	              </FormItem>
							</Col>
							<Col span={24}>
								<FormItem {...formItemLayout} label="审核员">
	                <InsSelect
	                  placeholder="请选择访问员"
	                  chs={this.props.reviewers}
	                  onChange={this.onChange('reviewer_ids')}
	                  value={this.state.reviewer_ids}
	                  multiple={true}
	                  disabled={true}
	                />
	              </FormItem>
							</Col>
							<Col span={24}>
								<FormItem {...formItemLayout} label="检查员">
	                <InsSelect
	                  placeholder="请选择审核员"
	                  chs={this.props.inspectors}
	                  onChange={this.onChange('inspector_ids')}
	                  value={this.state.inspector_ids}
	                  multiple={true}
	                  disabled={true}
	                />
	              </FormItem>
							</Col>
						</Row>
					</Form>
				</div>
			</LocaleProvider>
		)
	}
}