import React from 'react'
import { Breadcrumb } from 'antd'

export default class Component extends React.Component {
  itemRender(route, params, routes, paths) {
    const last = routes.indexOf(route) === routes.length - 1
    return last ? <span>{route.name}</span> : <a href={paths.join('/')}>{route.name}</a>
  }
  render() {
    return <Breadcrumb itemRender={this.itemRender} routes={this.props.routes} />
  }
}
