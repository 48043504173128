import React from 'react'

export default class Component extends React.Component {
  state = {}
  changeTab = target => {
    this.setState({
      target,
    })
  }
  componentWillMount() {
    this.changeTab(0)
  }
  render() {
    let arr = [0, 1, 2, 3]
    let blue_text = ['【园林局、林业站】', '【文明办】', '【农村工作委员会】', '【发改委、城管委】']
    let text = [
      '对城镇绿地和林地的日常管护检查 ',
      '对城市或区县创建文明城市工作进行督促检查',
      '对农村基础设施、农用地台账进行普查和检查',
      '对城市疏解整治工程施工过程监督、对比',
    ]
    return (
      <div className="ugm_plan_content">
        <div className="ugm_plan_title_section">
          <div className="ugm_plan_header">应用领域</div>
          {arr.map((item, index) => {
            return (
              <div
                key={index}
                className={`ugm_plan_title_item ${
                  index == this.state.target ? 'ugm_plan_title_item_active' : ''
                }`}
                onMouseEnter={() => this.changeTab(index)}
              >
                <span className="blue_text">{blue_text[index]}</span>
                {text[index]}
              </div>
            )
          })}
        </div>
        <div className="ugm_plan_plan_section">
          <div className="ugm_plan_header">解决方案</div>
          {arr.map((item, index) => {
            if (index == this.state.target) {
              return (
                <div key={index} className="ugm_plan_plan_item">
                  <img src={`/assets/tab${index + 1}.png`} />
                </div>
              )
            }
          })}
        </div>
      </div>
    )
  }
}
