import React from 'react'
import {
  Form,
  Input,
  LocaleProvider,
  Cascader,
  Select,
  Row,
  Col,
  Button,
  Radio
} from 'antd'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import InsSelect from '../components/inspectors_select'

const { TextArea } = Input
const FormItem = Form.Item
const RadioGroup = Radio.Group
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
}
const Option = Select.Option;
export default class Component extends React.Component {
	state={
    area_type:1
  }
  componentDidMount = () => {
    if (!this.props.project) {
      return
    }
    let project = this.props.project
    let inspectors = []
    let reviewers = []
    let templates = []
    project.inspectors.map(x=>{
      inspectors.push(x.id)
    })
    project.reviewers.map(x=>{
      reviewers.push(x.id)
    })
    project.standing_book_templates.map(x=>{
      templates.push(x.id)
    })
    this.setState({
      name:project.name,
      describe:project.describe,
      area_id:project.area.id,
      standard_id:project.standard.id,
      inspector_ids:inspectors,
      reviewer_ids:reviewers,
      standing_book_template_ids:templates,
      area_type:project.area_type || 1
    })
  }
	onChange = attr => {
    return e => {
      if(!e){ return this.setState({ [attr]: null })}
      let val = e.target ? e.target.value : e
      this.setState({ [attr]: val })
    }
  }
  cancel=()=>{
    Turbolinks.visit('/projects')
  }
  submit=()=>{
    let project = {
      name:this.state.name,
      describe:this.state.describe,
      area_id:this.state.area_id,
      standard_id:this.state.standard_id,
      inspector_ids:this.state.inspector_ids,
      reviewer_ids:this.state.reviewer_ids,
      standing_book_template_ids:this.state.standing_book_template_ids,
      area_type:this.state.area_type
    }
    console.log(project)
    if(this.props.project){
      var res = axios.put(`/projects/${this.props.project.id}.json`, { project }, { withCredentials: true })
    }
    else{
      var res = axios.post("/projects.json", { project }, { withCredentials: true })
    }
    res.then((data)=>{
      if (data.status == 200 || data.status == 201) {
        setTimeout(() => {
          Turbolinks.visit(`/projects`)
        }, 1500)
      } else {
        // 错误提示
      }
    })
  }
	render(){
    console.log(this.props)
		return(
			<LocaleProvider locale={zh_CN}>
				<Form style={{ backgroundColor: '#fff',paddingLeft: 30,paddingBottom: 30}}>
					<Row>
						<Col span={24}>
							<FormItem {...formItemLayout} label="项目名称">
                <Input
                  placeholder="请输入项目名称"
                  onChange={this.onChange('name')}
                  value={this.state.name}
                />
              </FormItem>
						</Col>
						<Col span={24}>
							<FormItem {...formItemLayout} label="指标模板">
                <InsSelect
                  placeholder="请选择指标模板"
                  chs={this.props.standards}
                  onChange={this.onChange('standard_id')}
                  value={this.state.standard_id}
                />
              </FormItem>
						</Col>
						<Col span={24}>
							<FormItem {...formItemLayout} label="区域类型">
                <RadioGroup  value={this.state.area_type} onChange={this.onChange('area_type')}>
                  <Radio value={0}>使用区域</Radio>
                  <Radio value={1}>使用台账</Radio>
                </RadioGroup>
              </FormItem>
						</Col>
            <Col span={24}>
              <FormItem {...formItemLayout} label="区域模板">
                <InsSelect
                  placeholder="请选择区域模板"
                  chs={this.props.areas}
                  onChange={this.onChange('area_id')}
                  value={this.state.area_id}
                />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem {...formItemLayout} label="台账模板">
                <InsSelect
                  placeholder="请选择台账模板"
                  chs={this.props.templates}
                  onChange={this.onChange('standing_book_template_ids')}
                  value={this.state.standing_book_template_ids}
                  multiple={true}
                />
              </FormItem>
            </Col>
						<Col span={24}>
							<FormItem {...formItemLayout} label="描述">
                <TextArea placeholder="请输入描述内容" autosize onChange={this.onChange('describe')} value={this.state.describe}/>
              </FormItem>
						</Col>
						<Col span={24}>
							<FormItem {...formItemLayout} label="审核员选择">
                <InsSelect
                  placeholder="请选择审核员"
                  chs={this.props.reviewers}
                  onChange={this.onChange('reviewer_ids')}
                  value={this.state.reviewer_ids}
                  multiple={true}
                />
              </FormItem>
						</Col>
						<Col span={24}>
							<FormItem {...formItemLayout} label="检查员选择">
                <InsSelect
                  placeholder="请选择检查员"
                  chs={this.props.inspectors}
                  onChange={this.onChange('inspector_ids')}
                  value={this.state.inspector_ids}
                  multiple={true}
                />
              </FormItem>
						</Col>
						<Col span={24}>
              <FormItem className="form_btn">
                <Button type="primary" style={{ marginRight: 20 }} onClick={this.submit}>
                  提交
                </Button>
                <Button onClick={this.cancel}>取消</Button>
              </FormItem>
            </Col>
					</Row>
				</Form>
			</LocaleProvider>
		)
	}
}
