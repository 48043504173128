import { TreeSelect, Row, Col, Button, DatePicker, Form, Radio, LocaleProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import 'moment/locale/zh-cn';
import React from "react"
import * as qs from "query-string"
import moment from "moment"
const FormItem = Form.Item
const RadioGroup = Radio.Group

export default class Component extends React.Component {

  state = {
    value: undefined,
    startValue: null,
    endValue: null,
    endOpen: false,
    statusValue: -1,
    standardsValue: undefined
  }

  componentDidMount = ()=>{
    // this.state = {}
    let query = qs.parse(location.search)
    console.log(query)
    if(query.st) { this.setState({startValue: moment(query.st)}) }
    if(query.et) { this.setState({endValue: moment(query.et)}) }
    if(query.status) { this.setState({statusValue: parseInt(query.status)}) }
    if(query.standards) { this.setState({standardsValue: query.standards}) }
    console.log(this.state)
  }

  disabledStartDate = (startValue) => {
    const endValue = this.state.endValue;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  }

  disabledEndDate = (endValue) => {
    const startValue = this.state.startValue;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  }

  onDateChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  }

  onStartChange = (value) => {
    this.onDateChange('startValue', value);
  }

  onEndChange = (value) => {
    this.onDateChange('endValue', value);
  }

  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  }

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  }

  onStandardsChange = (value) => {
    this.setState({ standardsValue: value });
  }

  onStatusChange = (e) => {
    console.log('radio checked', e.target.value);
    this.setState({
      statusValue: e.target.value,
    });
  }
  submit = () => {
    let query = {
      standards: this.state.standardsValue
    }
    if(this.state.statusValue != -1) {
      query.status = this.state.statusValue
    }
    if(this.state.startValue) { 
      query.st = this.state.startValue.format("YYYY-MM-DD")
    }
    if(this.state.endValue) {
      query.et = this.state.endValue.format("YYYY-MM-DD")
    }
    // qs.stringify(query)

    Turbolinks.visit('?' + qs.stringify(query))
  }

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const { startValue, endValue, endOpen } = this.state;
    return (
      <LocaleProvider locale={zh_CN}><div>
        <Row>
          <Col span={24}>
            <FormItem
            {...formItemLayout}
              label={t.case + "状态"}
            >
              <RadioGroup onChange={this.onStatusChange} value={this.state.statusValue}>
                <Radio value={-1}>全部</Radio>
                <Radio value={0}>待审核</Radio>
                <Radio value={1}>审核通过</Radio>
                <Radio value={2}>未通过审核</Radio>
              </RadioGroup>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <FormItem
              {...formItemLayout}
              label="选择指标"
            >
              <TreeSelect
                showSearch
                treeData={ this.props.standards }
                style={{ width: 256 }}
                value={this.state.standardsValue}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder="请选择指标"
                allowClear
                multiple
                treeDefaultExpandAll
                onChange={this.onStandardsChange}
              />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              {...formItemLayout}
              label="上报时间"
            >
              <DatePicker
                disabledDate={this.disabledStartDate}
                showTime
                format="YYYY-MM-DD"
                value={startValue}
                placeholder="开始"
                onChange={this.onStartChange}
                style={{ width: 144 }}
                onOpenChange={this.handleStartOpenChange}
              /> - <DatePicker
                disabledDate={this.disabledEndDate}
                showTime
                format="YYYY-MM-DD"
                value={endValue}
                placeholder="结束"
                onChange={this.onEndChange}
                open={endOpen}
                style={{ width: 144 }}
                onOpenChange={this.handleEndOpenChange}
              />
            </FormItem>
          </Col>
          <Col span={2}>
            <FormItem
              {...formItemLayout}
            >
              <Button type="primary"
                onClick={this.submit}
              >查 询</Button>
            </FormItem>
          </Col>
        </Row>
        </div></LocaleProvider>
    );
  }
}
