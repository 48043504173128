import { Menu, Dropdown, Button, Icon, message } from 'antd'
import React from 'react'

export default class Component extends React.Component {
  state = {}
  handleMenuClick = e => {
    // message.info('Click on left button.')
    // console.log('click left button', e, f)
    document.cookie = `project_id=${e.key};path=/`
    location.href = '/'
  }
  render() {
    const menu = (
      <Menu onClick={this.handleMenuClick}>
        {this.props.projects.map(project => {
          return (
            <Menu.Item key={project.id}>
              <Icon type="user" />
              {project.name}
            </Menu.Item>
          )
        })}
      </Menu>
    )
    let project = this.props.currentProject
    return (
      <Dropdown overlay={menu} trigger={['click']}>
        <div style={{ cursor: 'pointer' }}>
          <div className="header_logo_container" />
          <span>{project && project.name}</span>
          <Icon type="caret-down" style={{ color: '#fff', marginLeft: '10px' }} />
        </div>
      </Dropdown>
    )
  }
}
