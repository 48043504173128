import { Button, Row, Col, Divider, Table, LocaleProvider, Switch, Form, Input, Select } from 'antd'
import React from 'react'
import InsSelect from '../components/inspectors_select'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import qs from 'query-string'
const FormItem = Form.Item
const Option = Select.Option

export default class Component extends React.Component {
  state = {}
  onChange = attr => {
    return e => {
      if (!e) {
        return this.setState({ [attr]: null })
      }
      let val = e.target ? e.target.value : e
      this.setState({ [attr]: val })
    }
  }
  componentDidMount = () => {
    console.log(this.props)
    if (!this.props.user) {
      return
    }
    let user = this.props.user
    this.setState({
      mobile: user.mobile,
      active: user.active,
      name: user.name,
      role: user.role,
      organization_id: user.organization_id,
    })
  }
  cancel = () => {
    Turbolinks.visit(`/users?type=${qs.parse(window.location.href).type}`)
  }
  submit = () => {
    if (!this.state.mobile) {
      this.setState({
        mobilevalidateStatus: 'error',
        mobilehelp: '请输入账号',
      })
      return
    } else {
      this.setState({
        mobilevalidateStatus: 'success',
        mobilehelp: '',
      })
    }
    if (this.state.mobile.length < 3) {
      this.setState({
        mobilevalidateStatus: 'error',
        mobilehelp: '请至少输入三个字符',
      })
      return
    } else {
      this.setState({
        mobilevalidateStatus: 'success',
        mobilehelp: '',
      })
    }
    if (location.href.indexOf('new') > 0 && !this.state.password) {
      this.setState({
        validateStatus: 'error',
        help: '请输入不少于8位的密码',
      })
      return
    } else {
      this.setState({
        validateStatus: 'success',
        help: '',
      })
    }
    if (
      (this.state.password && !this.state.password_confirmation) ||
      this.state.password != this.state.password_confirmation
    ) {
      this.setState({
        confirmvalidateStatus: 'error',
        confirmhelp: '请输入一致密码',
      })
      return
    } else {
      this.setState({
        confirmvalidateStatus: 'success',
        confirmhelp: '',
      })
    }
    let user = {
      mobile: this.state.mobile,
      password: this.state.password,
      password_confirmation: this.state.password_confirmation,
      active: this.state.active,
      name: this.state.name,
      role: this.props.current_user.role == 4 ? 4 : this.state.role,
      organization_id: this.state.organization_id,
    }
    console.log(user)
    if (this.props.user) {
      var res = axios.put(`/users/${this.props.user.id}.json`, { user }, { withCredentials: true })
    } else {
      var res = axios.post('/users.json', { user }, { withCredentials: true })
    }
    res.then(data => {
      if (data.status == 200 || data.status == 201) {
        setTimeout(() => {
          Turbolinks.visit(`/users`)
        }, 1500)
      } else if (data.status == 422) {
        this.setState({
          mobilevalidateStatus: 'error',
          mobilehelp: '该账号已被占用，请重新输入',
        })
      } else {
        //错误提示
      }
    })
  }
  onBlur = e => {
    if (e.target.value.length < 8 && e.target.value.length > 0) {
      this.setState({
        validateStatus: 'error',
        help: '请输入不少于8位的密码',
      })
    } else if (!/^(?=.*[a-z])(?=.*\d)[\s\S]{8,100}$/.test(e.target.value)) {
      this.setState({
        validateStatus: 'error',
        help: '密码必须包含数字和字母',
      })
    } else if (
      this.state.password_confirmation &&
      this.state.password_confirmation != e.target.value
    ) {
      this.setState({
        validateStatus: 'success',
        help: '',
      })
    } else {
      this.setState({
        validateStatus: 'success',
        help: '',
        confirmvalidateStatus: 'success',
        confirmhelp: '',
      })
    }
  }
  onConfirmBlur = e => {
    if (e.target.value != this.state.password && this.state.password) {
      this.setState({
        confirmvalidateStatus: 'error',
        confirmhelp: '请输入一致密码',
      })
    } else {
      this.setState({
        confirmvalidateStatus: 'success',
        confirmhelp: '',
      })
    }
  }
  selectRole = () => {
    if (
      this.props.current_user &&
      this.props.current_user.role == '5' &&
      qs.parse(window.location.search).type == 'volunteers'
    ) {
      return (
        <Select
          value={this.state.role}
          onChange={this.onChange('role')}
          placeholder="请选择角色权限"
        >
          <Option value="6">志愿者</Option>
        </Select>
      )
    }
    if (
      this.props.current_user &&
      this.props.current_user.role == '5' &&
      qs.parse(window.location.search).type == 'districtors'
    ) {
      return (
        <Select
          value={this.state.role}
          onChange={this.onChange('role')}
          placeholder="请选择角色权限"
        >
          <Option value="5">区账号</Option>
        </Select>
      )
    }
    if (this.props.current_user && this.props.current_user.role == '2') {
      return (
        <Select
          value={this.state.role}
          onChange={this.onChange('role')}
          placeholder="请选择角色权限"
        >
          <Option value="1">检查员</Option>
          <Option value="2">审核员</Option>
        </Select>
      )
    }
    // if (this.props.current_user && this.props.current_user.role == '4') {
    //   return (
    //     <Select
    //       value={this.state.role}
    //       onChange={this.onChange('role')}
    //       placeholder="请选择角色权限"
    //     >
    //       <Option value="4">责任单位</Option>
    //     </Select>
    //   )
    // }
  }
  render() {
    console.log('props', this.props)
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      },
    }
    return (
      <LocaleProvider locale={zh_CN}>
        <Form>
          <Row>
            <Col span={24}>
              <FormItem
                {...formItemLayout}
                label="账号"
                validateStatus={this.state.mobilevalidateStatus}
                help={this.state.mobilehelp}
              >
                <Input
                  placeholder="请输入账号"
                  onChange={this.onChange('mobile')}
                  value={this.state.mobile}
                />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem
                {...formItemLayout}
                label="密码"
                validateStatus={this.state.validateStatus}
                help={this.state.help}
              >
                <Input
                  type="password"
                  placeholder="请输入密码"
                  onChange={this.onChange('password')}
                  onBlur={e => {
                    this.onBlur(e)
                  }}
                />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem
                {...formItemLayout}
                label="确认密码"
                validateStatus={this.state.confirmvalidateStatus}
                help={this.state.confirmhelp}
              >
                <Input
                  type="password"
                  placeholder="再输入一次"
                  onChange={this.onChange('password_confirmation')}
                  onBlur={e => {
                    this.onConfirmBlur(e)
                  }}
                />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem {...formItemLayout} label="是否激活">
                <Switch
                  defaultChecked
                  onChange={this.onChange('active')}
                  checked={this.state.active}
                />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem {...formItemLayout} label="名称">
                <Input
                  placeholder="请输入名称"
                  onChange={this.onChange('name')}
                  value={this.state.name}
                />
              </FormItem>
            </Col>
            {this.props.current_user && this.props.current_user.role == '4' ? null : (
              <Col span={24}>
                <FormItem {...formItemLayout} label="角色权限">
                  {this.selectRole()}
                </FormItem>
              </Col>
            )}
            {this.state.role && this.state.role == 6 ? (
              <Col span={24}>
                <FormItem {...formItemLayout} label="所属街道">
                  <InsSelect
                    chs={this.props.towns}
                    onChange={this.onChange('organization_id')}
                    value={this.state.organization_id}
                    placeholder="请选择所属街道"
                  />
                </FormItem>
              </Col>
            ) : (
              ''
            )}
            <Col span={24}>
              <FormItem className="form_btn">
                <Button type="primary" style={{ marginRight: 20 }} onClick={this.submit}>
                  提交
                </Button>
                <Button onClick={this.cancel}>取消</Button>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </LocaleProvider>
    )
  }
}
