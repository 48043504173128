import { Table, LocaleProvider } from 'antd'
import React from 'react'
import { Resizable } from 'react-resizable'
import * as qs from 'query-string'

const ResizeableTitle = props => {
  const { onResize, width, ...restProps } = props

  if (!width) {
    return <th {...restProps} />
  }

  return (
    <Resizable width={width} height={0} onResize={onResize}>
      <th {...restProps} />
    </Resizable>
  )
}

export default class Component extends React.Component {
  state = {
    columns: [],
    current: this.props.pagination.current,
  }

  components = {
    header: {
      cell: ResizeableTitle,
    },
  }

  handleResize = index => (e, { size }) => {
    this.setState(({ columns }) => {
      const nextColumns = [...columns]
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      }
      return { columns: nextColumns }
    })
  }

  handleTableChange = pagis => {
    let query = qs.parse(location.search)
    query.page = pagis.current
    Turbolinks.visit('?' + qs.stringify(query))
  }

  onChangePage = current => {
    this.setState({
      current,
    })
  }
  render() {
    const columns = this.props.columns.map((col, index) => ({
      ...col,
      onHeaderCell: column => ({
        width: column.width,
        onResize: this.handleResize(index),
      }),
    }))
    const rowSelection = {
      selectedRowKeys: this.props.selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        this.props.onSelectChange(selectedRowKeys, selectedRows)
      },
      onSelect: record => {
        this.props.onSelect(record)
      },
      onSelectAll: (selected, selectedRows, changeRows) => {
        this.props.onSelectAll(selected, selectedRows, changeRows)
      },
      getCheckboxProps: record => ({
        disabled: record.name === 'Disabled User', // Column configuration not to be checked
        name: record.name,
      }),
    }
    const data = this.props.data
    const attrs = this.props.attrs
    const pagination = {
      showQuickJumper: true,
      pageSize: this.props.pagination.pageSize,
      total: this.props.pagination.total,
      current: this.state.current,
      onChange: current => this.onChangePage(current),
    }
    return (
      <Table
        bordered
        className="mytable"
        rowSelection={rowSelection}
        components={this.components}
        columns={columns}
        dataSource={data}
        onChange={this.handleTableChange}
        pagination={pagination}
      />
    )
  }
}
