import { Button, Table, Icon, Radio, Popconfirm, message, Divider, Modal, Spin } from 'antd'
import React from 'react'
import * as qs from 'query-string'
import axios from 'axios'
import moment from 'moment'

export default class Component extends React.Component {
  state = {
    dataList: [],
    pagination: {
      total: 0,
      current: 1,
      pageSize: 10,
    },
  }
  getQuery = () => {
    const obj = {
      ids: JSON.parse(localStorage.getItem('ids')),
      key: qs.parse(window.location.search).key,
    }
    return obj
  }
  getData = query => {
    axios.get(`/ucases.json?${query}`).then(({ data }) => {
      console.log('rrrr===', data)
      this.setState({
        dataList: data.data,
        pagination: data.pagis,
      })
    })
  }
  componentDidMount() {
    this.getData(qs.stringify(this.getQuery(), { arrayFormat: 'bracket' }))
  }
  onChangePage = current => {
    const obj = Object.assign(this.getQuery(), { page: current })
    this.getData(qs.stringify(obj, { arrayFormat: 'bracket' }))
  }
  render() {
    const columns = [
      {
        title: '编号',
        key: 'id',
        dataIndex: 'id',
      },
      {
        title: '区域',
        key: 'areas',
        dataIndex: 'areas',
        render: areas => {
          return areas && areas.length != 0
            ? [areas].reduce((pre, cur) => {
                cur.forEach((x, i) => {
                  pre.push(<p key={i}>{x}</p>)
                })
                return pre
              }, [])
            : null
        },
      },
      {
        title: '指标',
        key: 'standards',
        dataIndex: 'standards',
        render: standards => {
          return standards
            ? standards.reduce((pre, cur) => {
                cur.forEach((x, i) => {
                  pre.push(<p key={i}>{x}</p>)
                })
                return pre
              }, [])
            : null
        },
      },
      {
        title: '上报时间',
        key: 'time',
        render: data => (
          <span>{data.inspected_at > 0 && moment(data.inspected_at).format('YYYY-MM-DD')}</span>
        ),
      },
      {
        title: '上报人',
        key: 'name',
        dataIndex: 'creator.name',
      },
    ]
    const pagination = {
      showQuickJumper: true,
      pageSize: this.state.pagination.pageSize,
      total: this.state.pagination.total,
      current: this.state.pagination.current,
      onChange: current => this.onChangePage(current),
    }
    return (
      <div>
        <div>
          <span style={{ fontSize: '18px', marginRight: '20px' }}>批量操作</span>
          <Button
            type="primary"
            onClick={() => {
              Turbolinks.visit(`/ucases${localStorage.getItem('query')}`)
            }}
          >
            返回案件列表
          </Button>
        </div>
        <div style={{ marginTop: '20px' }}>
          <div style={{ overflow: 'hidden' }}>
            <div style={{ float: 'left' }}>案件列表</div>
            <div style={{ float: 'right' }}>共选择了{this.state.pagination.total}条数据</div>
          </div>
          <div style={{ marginTop: '10px' }}>
            <Table
              bordered
              className="mytable"
              columns={columns}
              dataSource={this.state.dataList}
              pagination={pagination}
            />
          </div>
        </div>
        <div style={{ marginTop: '30px' }}>
          <div style={{ margin: '20px 0' }}>案件审核</div>
        </div>
      </div>
    )
  }
}
