import React from 'react'
import { Table, Divider, Modal, Button } from 'antd'
import ScoreDetail from './scoreDetail'
import LogDetail from './logDetail'

export default class Component extends React.Component {
  constructor() {
    super()
  }
  state = {
    scoreVisible: false,
    logVisible: false,
    scoreList: [],
    logList: [],
  }
  getScoreDetail = id => {
    axios.get(`/assessment/logs?assessmentLogsId=${id}`).then(res => {
      console.log('detail==', res)
      this.setState({
        scoreList: res.data.data,
      })
    })
  }
  getLogs = id => {
    axios.get(`/assessment/revise_score_logs?month=${this.props.month}&unitId=${id}`).then(res => {
      console.log('logs==', res)
      this.setState({
        logList: res.data.data,
      })
    })
  }
  render() {
    const columns = [
      {
        title: '排名',
        width: 150,
        key: 'order',
        render: (text, record, index) => {
          return <span>{index + 1}</span>
        },
      },
      {
        title: '单位名称',
        dataIndex: 'unitName',
        key: 'unitName',
      },
      {
        title: '总分',
        width: 100,
        dataIndex: 'totalScore',
        key: 'totalScore',
        sorter: (a, b) => a.totalScore - b.totalScore,
        render: score => {
          return <span>{score && score.toFixed(2)}</span>
        },
      },
      {
        title: '修正分',
        width: 100,
        dataIndex: 'reviseScore',
        key: 'reviseScore',
        render: score => {
          return <span>{score && score.toFixed(2)}</span>
        },
      },
      {
        title: '汇总得分',
        width: 150,
        dataIndex: 'summaryScore',
        key: 'summaryScore',
        sorter: (a, b) => a.summaryScore - b.summaryScore,
        render: score => {
          return <span>{score && score.toFixed(2)}</span>
        },
      },
      {
        title: '操作',
        width: 300,
        key: 'action',
        render: data => {
          return (
            <div style={{ color: '#1890FF' }}>
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  this.setState({
                    scoreVisible: true,
                    name: data.unitName,
                    total: data.totalScore,
                  })
                  this.getScoreDetail(data.assessmentLogsId)
                }}
              >
                得分详情
              </span>
              <Divider type="vertical" />
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  this.props.editScore(
                    data.unitId,
                    data.reviseScore,
                    data.unitName,
                    data.totalScore
                  )
                }}
              >
                编辑
              </span>
              <Divider type="vertical" />
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  this.setState({
                    logVisible: true,
                    name: data.unitName,
                    total: data.totalScore,
                  })
                  this.getLogs(data.unitId)
                }}
              >
                修改记录
              </span>
            </div>
          )
        },
      },
    ]
    const columns2 = [
      {
        title: '排名',
        width: 150,
        key: 'order',
        render: (text, record, index) => {
          return <span>{index + 1}</span>
        },
      },
      {
        title: '单位名称',
        dataIndex: 'unitName',
        key: 'unitName',
      },
      {
        title: '汇总得分',
        width: 500,
        dataIndex: 'summaryScore',
        key: 'summaryScore',
        sorter: (a, b) => a.summaryScore - b.summaryScore,
        render: score => {
          return <span>{score && score.toFixed(2)}</span>
        },
      },
    ]
    return (
      <div>
        <Table
          columns={this.props.role === '5' ? columns2 : columns}
          dataSource={this.props.data || []}
          pagination={false}
          style={{ width: '80%' }}
          rowKey={record => {
            record.id
          }}
          scroll={{ y: 780 }}
        />
        <Modal
          title="得分详情"
          visible={this.state.scoreVisible}
          onCancel={() => {
            this.setState({ scoreVisible: false })
          }}
          width={1000}
          footer={
            <Button
              type="primary"
              onClick={() => {
                this.setState({ scoreVisible: false })
              }}
            >
              确定
            </Button>
          }
        >
          <ScoreDetail
            scoreList={this.state.scoreList}
            name={this.state.name}
            total={this.state.total}
          />
        </Modal>
        <Modal
          title="修改记录"
          visible={this.state.logVisible}
          onCancel={() => {
            this.setState({ logVisible: false })
          }}
          width={800}
          footer={
            <Button
              type="primary"
              onClick={() => {
                this.setState({ logVisible: false })
              }}
            >
              确定
            </Button>
          }
        >
          <LogDetail logList={this.state.logList} name={this.state.name} total={this.state.total} />
        </Modal>
      </div>
    )
  }
}
