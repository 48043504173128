import { Carousel } from 'antd'
import React from 'react'
import Login from './login'

export default class Component extends React.Component {
  state = {
    login: {
      help: '',
      mobile: '',
      password: '',
      remember_me: '',
      validateStatus: null,
    },
  }

  handleLoginHelp = state => {
    this.setState({
      login: Object.assign(this.state.login, state),
    })
  }

  handleLoginChange = attr => {
    // console.log(attr)
    return e => {
      // console.log(e);
      if (!e) {
        return
      }
      let val = e.target ? e.target.value : e
      this.setState({
        login: Object.assign(this.state.login, {
          [attr]: val,
          validateStatus: null,
          help: '',
          remember_me: e.target.checked,
        }),
      })
    }
  }

  render() {
    return (
      <div>
        <Carousel autoplay>
          <div className="intro_login_bg1">
            <div className="intro_content" style={{ padding: 30 }}>
              <div className="intro_text_content">
                <p style={{ fontSize: 26 }}>专业的农村发展与环境建设智能巡检系统</p>
                <p style={{ fontsize: 16, lineHeight: '26px' }}>
                  一站式精细化全覆盖农村发展与环境建设巡检，支持在线分配巡检任务、手机上报巡检问题、在线审核问题、微信整改问题、支持增加个性化测评模块
                </p>
                <a data-action="anchor#toContact">
                  <div className="intro_btn">免费体验系统 ></div>
                </a>
              </div>
              <div className="intro_login_content">
                <div style={{ fontSize: 18, marginBottom: 10 }}>登录</div>
                <Login
                  login={this.state.login}
                  setHelp={this.handleLoginHelp}
                  onChange={this.handleLoginChange}
                />
              </div>
            </div>
          </div>
          <div className="intro_login_bg2">
            <div className="intro_content" style={{ padding: '10px 30px 30px 30px',height: 300 }}>
              <div className="intro_text_content">
                <p style={{ fontSize: 26,lineHeight: '30px' }}>丰富的农村发展与环境建设检查应用场景，近百个成功项目检验</p>
                <p style={{ fontsize: 16, lineHeight: '26px' }}>
                  丰富的农村发展与环境建设巡检应用场景、全面支撑各类细分巡检应用，灵活支撑巡检指标，满足各类农村发展与环境建设巡检需求，历经近百个项目检验，为农村发展与环境建设巡检提供卓越系统支撑
                </p>
                <a data-action="anchor#toContact">
                  <div className="intro_btn" style={{ color: '#FF8700' }}>
                    免费体验系统 >
                  </div>
                </a>
              </div>
              <div className="intro_login_content" style={{marginTop: '20px'}}>
                <div style={{ fontSize: 18, marginBottom: 10 }}>登录</div>
                <Login
                  login={this.state.login}
                  setHelp={this.handleLoginHelp}
                  onChange={this.handleLoginChange}
                />
              </div>
            </div>
          </div>
          <div className="intro_login_bg3 intro_login_bg3_ilm">
            <div className="intro_content" style={{ padding: 30 }}>
              <img src="/assets/iue_banner3-text.png" />
            </div>
          </div>
        </Carousel>
      </div>
    )
  }
}