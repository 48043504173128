import React from 'react'
import CommonTable from './commonTable'

export default class Component extends React.Component {
  constructor() {
    super()
  }
  render() {
    return (
      <div>
        <div style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '15px' }}>
          重点指标任务部门
        </div>
        <CommonTable
          data={this.props.dataList.important}
          month={this.props.month}
          editScore={(id, score, name, total) => {
            this.props.editScore(id, score, name, total)
          }}
          role={this.props.role}
        />
        <div style={{ fontSize: '16px', fontWeight: 'bold', margin: '15px 0' }}>
          一般指标任务部门
        </div>
        <CommonTable
          data={this.props.dataList.notImportant}
          month={this.props.month}
          editScore={(id, score, name, total) => {
            this.props.editScore(id, score, name, total)
          }}
          role={this.props.role}
        />
      </div>
    )
  }
}
