import { Select } from 'antd'
import React from 'react'

const Option = Select.Option

export default class Component extends React.Component {
  state = {
    children: [],
    value: [],
  }

  componentDidMount() {
    let children = (this.props.chs || []).map(ch => {
      return (
        <Option key={ch.id} value={ch.id}>
          {ch.name || ch.mobile}
        </Option>
      )
    })
    this.setState({
      children,
    })
  }

  handleChange = value => {
    if (this.props.onChange) {
      // this.setState({value})
      this.props.onChange(value)
    }
  }

  onSelect = value => {
    console.log(value)
  }

  render() {
    // console.log(this.props)
    // this.setState({value: this.props.value})
    return (
      <Select
        showSearch
        optionFilterProp="children"
        mode={this.props.multiple ? 'multiple' : null}
        style={{ width: '100%' }}
        placeholder={this.props.placeholder}
        onChange={this.handleChange}
        onSelect={this.onSelect}
        value={this.props.value ? this.props.value : []}
        allowClear
        disabled={this.props.disabled ? true : false}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {this.state.children}
      </Select>
    )
  }
}
