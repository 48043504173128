import React from 'react'

export default class Component extends React.Component {
  state = {}
  changeTab = target => {
    this.setState({
      target,
    })
  }
  componentWillMount() {
    this.changeTab(0)
  }
  render() {
    let arr = [0, 1, 2]
    let blue_text = ['【供电服务质量测评】', '【充电设施服务质量明察暗访】', '【公共服务质量第三方测评】']
    let text = [
      '对供电服务质量进行全方位立体化的明察暗访。',
      '对充电桩（站）等充电设施运行服务质量进行明察暗访。',
      '对各类公共服务质量质量进行明察暗访。',
    ]
    return (
      <div className="ugm_plan_content">
        <div className="ugm_plan_title_section">
          <div className="ugm_plan_header">应用领域</div>
          {arr.map((item, index) => {
            return (
              <div
                key={index}
                className={`ugm_plan_title_item ${
                  index == this.state.target ? 'ugm_plan_title_item_active' : ''
                }`}
                style={{height: '112px'}}
                onMouseEnter={() => this.changeTab(index)}
              >
                <span className="blue_text">{blue_text[index]}</span>
                {text[index]}
              </div>
            )
          })}
        </div>
        <div className="ugm_plan_plan_section">
          <div className="ugm_plan_header">解决方案</div>
          {arr.map((item, index) => {
            if (index == this.state.target) {
              return (
                <div key={index} className="ugm_plan_plan_item">
                  <img src={`/assets/isq_tab${index + 1}.png`} />
                </div>
              )
            }
          })}
        </div>
      </div>
    )
  }
}
