import { Carousel } from 'antd'
import React from 'react'
import Login from './login'

export default class Component extends React.Component {
  state = {
    login: {
      help: '',
      mobile: '',
      password: '',
      remember_me: '',
      validateStatus: null,
    },
  }

  handleLoginHelp = state => {
    this.setState({
      login: Object.assign(this.state.login, state),
    })
  }

  handleLoginChange = attr => {
    // console.log(attr)
    return e => {
      // console.log(e);
      if (!e) {
        return
      }
      let val = e.target ? e.target.value : e
      this.setState({
        login: Object.assign(this.state.login, {
          [attr]: val,
          validateStatus: null,
          help: '',
          remember_me: e.target.checked,
        }),
      })
    }
  }

  render() {
    return (
      <div>
        <Login
          login={this.state.login}
          setHelp={this.handleLoginHelp}
          onChange={this.handleLoginChange}
        />
      </div>
    )
  }
}
