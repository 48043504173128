import {
  Form,
  Input,
  DatePicker,
  Col,
  TimePicker,
  Select,
  Cascader,
  InputNumber,
  Button,
} from 'antd'
import React from 'react'
import InsSelect from '../components/inspectors_select'
import moment from 'moment'
import { pick } from 'lodash'
const FormItem = Form.Item
const Option = Select.Option

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
}
const { TextArea } = Input
export default class Component extends React.Component {
  state = {}

  disabledStartDate = value => {
    // const endValue = this.state.endValue;
    if (!value) {
      return false
    }
    // console.log(value.valueOf() > Date.now())
    return value.valueOf() > Date.now()
  }

  onChange = attr => {
    return e => {
      if(!e){ return this.setState({ [attr]: null })}
      let val = e.target ? e.target.value : e
      this.setState({
        [attr]: val,
        [`${attr}_validateStatus`]: null,
        [`${attr}_help`]: null,
      })
    }
  }

  submit = () => {
    console.log(this.state)
    if (!this.state.inspector_ids || this.state.inspector_ids.length == 0) {
      this.setState({
        inspector_ids_validateStatus: 'error',
        inspector_ids_help: '请选择检查员',
      })
      return
    }

    let task = {
      area: this.state.area,
      describe: this.state.describe,
      frequency: this.state.frequency,
      leader_id: this.state.leader_id,
      standard: this.state.standard,
      inspector_ids: this.state.inspector_ids,
      deadline: this.state.deadline,
      uc_type: this.state.uc_type,
      name: this.state.name,
    }
    task
    if (this.state.task_id) {
      var res = axios.put(`/tasks/${this.state.task_id}.json`, { task }, { withCredentials: true })
    } else {
      var res = axios.post('/tasks.json', { task }, { withCredentials: true })
    }
    res.then(({ data }) => {
      console.log(data)
      if (data.code == 200) {
        // emitter.emit('alert', {
        //   message: "更新成功!"
        // })
        setTimeout(() => {
          Turbolinks.visit(`/tasks/${data.data.id}`)
        }, 1500)
      } else {
        emitter.emit('alert', {
          message: '更新失败,请检查参数!',
          type: 'error',
        })
      }
    })
  }

  cancel = () => {
    Turbolinks.visit(`/tasks`)
  }

  componentDidMount = () => {
    if (!this.props.task) {
      return
    }
    let task = this.props.task
    console.info(task)
    this.setState({
      task_id: task.id,
      area: task.area,
      leader_id: task.leader ? task.leader.id : null,
      inspector_ids: task.inspectors.map(x => x.id),
      frequency: task.frequency,
      deadline: task.deadline ? moment(task.deadline) : null,
      standard: task.standard,
      describe: task.describe,
      name: task.name,
      uc_type: task.uc_type,
    })
  }
  disabledDate = (current)=>{
    // Can not select days before today and today
    return current < moment().add(-1, 'd');
  }
  render() {
    console.log('props',this.props)
    return (
      <Form>
        <FormItem {...formItemLayout} label="任务名称">
          <Input
            placeholder="请输入任务名称"
            onChange={this.onChange('name')}
            value={this.state.name}
          />
        </FormItem>
        <FormItem {...formItemLayout} label="任务类型">
          <Select value={this.state.uc_type ? this.state.uc_type+'' : []} onChange={this.onChange('uc_type')} placeholder="请选择任务类型">
            <Option value='1'>日常检查</Option>
            <Option value='2'>季度考核</Option>
            <Option value='3'>社会监督案件</Option>
          </Select>
        </FormItem>
        <FormItem {...formItemLayout} label="组长" hasFeedback>
          <InsSelect
            chs={this.props.inspectors}
            onChange={this.onChange('leader_id')}
            value={this.state.leader_id}
            placeholder="请选择组长"
          />
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="检查员"
          hasFeedback
          validateStatus={this.state.inspector_ids_validateStatus}
          help={this.state.inspector_ids_help}
        >
          <InsSelect
            chs={this.props.inspectors}
            multiple={true}
            onChange={this.onChange('inspector_ids')}
            value={this.state.inspector_ids}
            placeholder="请选择检查员"
          />
        </FormItem>

        <FormItem {...formItemLayout} label="检查频率">
          <Input
            placeholder="请输入检查频率"
            onChange={this.onChange('frequency')}
            value={this.state.frequency}
          />
        </FormItem>

        <FormItem
          {...formItemLayout}
          disabledDate={this.disabledStartDate}
          label="截止时间"
          hasFeedback
        >
          <DatePicker
            onChange={this.onChange('deadline')}
            value={this.state.deadline}
            format={'YYYY-MM-DD'}
            style={{ width: '100%' }}
            placeholder="请选择截止时间"
            disabledDate={this.disabledDate}
          />
        </FormItem>
        <FormItem {...formItemLayout} label="检查区域" hasFeedback>
          <TextArea
            autosize={{ minRows: 3, maxRows: 6 }}
            onChange={this.onChange('area')}
            value={this.state.area}
          />
        </FormItem>
        <FormItem {...formItemLayout} label="检查标准" hasFeedback>
          <TextArea
            autosize={{ minRows: 3, maxRows: 6 }}
            onChange={this.onChange('standard')}
            value={this.state.standard}
          />
        </FormItem>
        <FormItem {...formItemLayout} label="描述" hasFeedback>
          <TextArea
            autosize={{ minRows: 3, maxRows: 6 }}
            onChange={this.onChange('describe')}
            value={this.state.describe}
          />
        </FormItem>
        <FormItem className="form_btn">
          <Button type="primary" onClick={this.submit} style={{ marginRight: 20 }}>
            提交
          </Button>
          <Button onClick={this.cancel}>取消</Button>
        </FormItem>
      </Form>
    )
  }
}
