import { Row, Col, Icon } from 'antd';
import React from "react"

export default class Component extends React.Component {
	render(){
		return(
			<div className="gutter-example">
		    <Row gutter={16}>
		      <Col className="gutter-row" span={6}>
		        <div className="gutter-box intro_function_item">
		        	<div className="intro_function_item_title">
		        		<Icon type="radar-chart" theme="outlined" style={{marginRight: 10}} />现场报案
		        	</div>
		        	<div className="intro_function_item_text">
		        		检查员使用手机APP，到现场检查并拍照上报{t.case}，系统自动记录检查路径、{t.case}定位，支持离线报案。
		        	</div>
		        </div>
		      </Col>
		      <Col className="gutter-row" span={6}>
		        <div className="gutter-box intro_function_item">
		        	<div className="intro_function_item_title">
		        		<Icon type="user" theme="outlined" style={{marginRight: 10}} />{t.case}审核
		        	</div>
		        	<div className="intro_function_item_text">
		        		审核员通过PC端管理后台实时审核{t.case}，把控检查质量，审核通过的{t.case}系统自动转发至责任单位。
		        	</div>
		        </div>
		      </Col>
		      <Col className="gutter-row" span={6}>
		        <div className="gutter-box intro_function_item">
		        	<div className="intro_function_item_title">
		        		<Icon type="warning" theme="outlined" style={{marginRight: 10}} />现场整改
		        	</div>
		        	<div className="intro_function_item_text">
		        		责任单位使用微信小程序到现场整改，将整改结果拍照反馈至平台，系统自动提醒检查员进行复核
		        	</div>
		        </div>
		      </Col>
		      <Col className="gutter-row" span={6}>
		        <div className="gutter-box intro_function_item">
		        	<div className="intro_function_item_title">
		        		<Icon type="file-text" theme="outlined" style={{marginRight: 10}} />报告生成
		        	</div>
		        	<div className="intro_function_item_text">
		        		可定制化的报告生成模块，自动根据检查员上报的{t.case}，以及责任单位整改情况，生成检查报告。
		        	</div>
		        </div>
		      </Col>
		    </Row>
		  </div>
		)
	}
}