import { Select } from 'antd';
import React from "react"

const Option = Select.Option;

export default class Component extends React.Component {
  state = {
    children: []
  }

  componentDidMount() {
    let children = (this.props.watch || []).map((x)=>{
      return (<Option key={x.id} value={x.id} >{x.name}</Option>)
    })
    this.setState({
      children
    })
  }

  handleChange = (value) => {
    if(this.props.onChange) {
      this.props.onChange(value)
    }
  }

  render() {
    console.log(this.props)
    return (
      <Select
        showSearch
        optionFilterProp="children"
        style={{ width: '100%' }}
        placeholder={this.props.placeholder}
        onChange={this.handleChange}
        mode={ this.props.multiple ? "" : null }
        allowClear={true}
        value={this.props.value? this.props.value:[]}
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {this.state.children}
      </Select>
    );
  }
}