import React from "react"
import { Button,Icon } from 'antd'

export default class Component extends React.Component {
	state={
		arr:[1,2,3,4,5,6],
		count:1
	}
	changeItem = ()=>{
		let newArr=[]
		if(this.state.count == 3){
			this.setState({
				arr:[1,2,3,4,5,6],
				count:1
			})
		}
		else{
			this.state.arr.map((x)=>{
				newArr.push(x+6)
			})
			this.setState({
				arr:newArr,
				count:this.state.count+1
			})
		}	
	}
	render(){
		let title = [
			'北京市农村生活垃圾第三方检查',
			'“国家农产品质量安全县”创建中期评估',
			'巾帼脱贫行动第三方评估',
			'拉萨市农村低保第三方核查',
			'北京市农村危房改造第三方检查',
			'海淀区农村基础设施第三方普查',				
		]
		let name = [
			'北京市城市管理委员会',
			'北京市农业局',
			'全国妇联妇女发展部',
			'拉萨市民政局',
			'北京市住房和城乡建设委员会',
			'北京市海淀区农村工作委员会',			
		]
		return(
			<div className="ugm_success">
				{
					this.state.arr.map((item,index)=>{
						return(
							<div key={index} className="ugm_success_section">
								<div className="ugm_success_item">
									<img src={`/assets/ird${item}.png`}/>
									<div className="ugm_success_item_content">
										<div style={{color: '#666666',fontSize: 14}}>{title[item-1]}</div>
										<div style={{color: '#4A4A4A',fontSize: 16}}>{name[item-1]}</div>
									</div>
								</div>
							</div>
						)	
					})
				}
			</div>
		)
	}
}