import React from "react"
import { Button,Icon } from 'antd'

export default class Component extends React.Component {
	state={
		arr:[1,2,3,4,5,6],
		count:1
	}
	changeItem = ()=>{
		let newArr=[]
		if(this.state.count == 3){
			this.setState({
				arr:[1,2,3,4,5,6],
				count:1
			})
		}
		else{
			this.state.arr.map((x)=>{
				newArr.push(x+6)
			})
			this.setState({
				arr:newArr,
				count:this.state.count+1
			})
		}	
	}
	render(){
		let title = [
			'北京市电动汽车充电桩运营质量监测',
			'供电营业厅服务质量明察暗访',
			'顺义区园林服务中心绿地第三方专业检查',
			'养老服务质量明察暗访',
			'全国创建无烟卫生计生系统暗访评估服务',
			'供电服务质量第三方监测',				
		]
		let name = [
			'北京市城市管理委员会',
			'国网北京市电力公司',
			'北京四季景艺园林绿化中心',
			'北京市民政局',
			'国家卫计委',
			'国网北京市电力公司',			
		]
		return(
			<div className="ugm_success">
				{
					this.state.arr.map((item,index)=>{
						return(
							<div key={index} className="ugm_success_section">
								<div className="ugm_success_item">
									<img src={`/assets/isq${item}.png`}/>
									<div className="ugm_success_item_content">
										<div style={{color: '#666666',fontSize: 14}}>{title[item-1]}</div>
										<div style={{color: '#4A4A4A',fontSize: 16}}>{name[item-1]}</div>
									</div>
								</div>
							</div>
						)	
					})
				}
			</div>
		)
	}
}