import { Form, Input, DatePicker, Radio, Select, Cascader, Checkbox, Button, Row, Col } from 'antd'
import React from 'react'
import InsSelect from '../components/inspectors_select'
import * as qs from 'query-string'
import moment from 'moment'
import { assign, castArray } from 'lodash'
const FormItem = Form.Item
const Option = Select.Option
const { RangePicker } = DatePicker
const RadioButton = Radio.Button
const RadioGroup = Radio.Group
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}
const yanqi = ['首次通报', '二次通报', '三次通报']
const anjian = ['紧急', '一般']
const zhongdian = [`重点案件`, `普通案件`]

export default class Component extends React.Component {
  state = {
    yanqi: [],
    anjian: [],
    zhongdian: [],
    optionList: [],
  }
  onChange = (attr, attr2 = null) => {
    return e => {
      if (!e) {
        return this.setState({ [attr]: null })
      }
      let val = e.target ? e.target.value : e
      attr2 ? this.setState({ [attr]: val[0], [attr2]: val[1] }) : this.setState({ [attr]: val })
    }
  }
  componentDidMount = () => {
    axios.get('/standards/options').then(res => {
      console.log('options', res)
      if (res.data.code === 200) {
        this.setState({
          optionList: res.data.data,
        })
      }
    })
    let query = qs.parse(location.search, { arrayFormat: 'bracket' })
    if (query.ids) {
      this.setState({ ids: query.id })
    }
    if (query.standard_ids) {
      this.setState({ standard_ids: castArray(query.standard_ids) })
    }
    if (query.area_ids) {
      this.setState({ area_ids: castArray(query.area_ids) })
    }

    if (query.creator_id) {
      this.setState({ creator_id: parseInt(query.creator_id) })
    }
    if (query.regulator_id) {
      this.setState({ regulator_id: parseInt(query.regulator_id) })
    }
    if (query.responsibler_id) {
      this.setState({ responsibler_id: parseInt(query.responsibler_id) })
    }

    if (query.inspected_at_start) {
      this.setState({ inspected_at_start: moment(query.inspected_at_start) })
    }
    if (query.inspected_at_end) {
      this.setState({ inspected_at_end: moment(query.inspected_at_end) })
    }
    if (query.reformed_at_start) {
      this.setState({ reformed_at_start: moment(query.reformed_at_start) })
    }
    if (query.reformed_at_end) {
      this.setState({ reformed_at_end: moment(query.reformed_at_end) })
    }
    if (query.deadline_st) {
      this.setState({ deadline_st: moment(query.deadline_st) })
    }
    if (query.deadline_et) {
      this.setState({ deadline_et: moment(query.deadline_et) })
    }
    if (query.status != undefined) {
      this.setState({ status: query.status })
    }

    if (query.stage != undefined) {
      this.setState({ stage: query.stage })
    }

    if (query.review_status != undefined) {
      this.setState({ review_status: query.review_status })
    }

    if (query.standing_book_name != undefined) {
      this.setState({ standing_book_name: query.standing_book_name })
    }
    if (query.is_delayed) {
      this.setState({ is_delayed: query.is_delayed })
    }
    if (query.options) {
      this.setState({ options: query.options })
    }
    if (query.tags) {
      return query.tags.map(x => {
        if (yanqi.indexOf(x) > -1) {
          this.state.yanqi.push(x)
          return this.setState({
            yanqi: this.state.yanqi,
          })
        } else if (anjian.indexOf(x) > -1) {
          this.state.anjian.push(x)
          return this.setState({
            anjian: this.state.anjian,
          })
        } else if (zhongdian.indexOf(x) > -1) {
          this.state.zhongdian.push(x)
          return this.setState({
            zhongdian: this.state.zhongdian,
          })
        }
      })
    }
  }
  search = () => {
    console.log('state==', this.state)
    let query = qs.parse(location.search, { arrayFormat: 'bracket' })
    if (query.page) {
      query.page = 1
    }
    let params = assign(query, {
      ids: this.state.id && this.state.id.split('/'),
      standard_ids: this.state.standard_ids,
      area_ids: this.state.area_ids,
      creator_id: this.state.creator_id,
      regulator_id: this.state.regulator_id,
      responsibler_id: this.state.responsibler_id,
      advance_search: true,
      status: this.state.status,
      stage: this.state.stage,
      review_status: this.state.review_status,
      tags: this.state.yanqi.concat(this.state.anjian, this.state.zhongdian),
      standing_book_name: this.state.standing_book_name,
      is_delayed: this.state.is_delayed,
      options: this.state.options,
    })
    // if(this.state.status) { params.status = this.state.status}
    params.inspected_at_start =
      this.state.inspected_at_start && this.state.inspected_at_start.format('YYYY-MM-DD')
    if (
      this.state.inspected_at_start &&
      this.state.inspected_at_start.format('YYYY-MM-DD') ==
        this.state.inspected_at_end.format('YYYY-MM-DD')
    ) {
      let inspected_at_end =
        this.state.inspected_at_end &&
        new Date((new Date(this.state.inspected_at_start) / 1000 + 86400) * 1000)
      params.inspected_at_end =
        this.state.inspected_at_end &&
        `${inspected_at_end.getFullYear()}-${inspected_at_end.getMonth() +
          1}-${inspected_at_end.getDate()}`
    } else {
      params.inspected_at_end =
        this.state.inspected_at_end && this.state.inspected_at_end.format('YYYY-MM-DD')
    }
    params.reformed_at_start =
      this.state.reformed_at_start && this.state.reformed_at_start.format('YYYY-MM-DD')
    if (
      this.state.reformed_at_start &&
      this.state.reformed_at_end.format('YYYY-MM-DD') ==
        this.state.reformed_at_start.format('YYYY-MM-DD')
    ) {
      let reformed_at_end =
        this.state.reformed_at_end &&
        new Date((new Date(this.state.reformed_at_start) / 1000 + 86400) * 1000)
      params.reformed_at_end =
        this.state.reformed_at_end &&
        `${reformed_at_end.getFullYear()}-${reformed_at_end.getMonth() +
          1}-${reformed_at_end.getDate()}`
    } else {
      params.reformed_at_end =
        this.state.reformed_at_end && this.state.reformed_at_end.format('YYYY-MM-DD')
    }

    params.deadline_st = this.state.deadline_st && this.state.deadline_st.format('YYYY-MM-DD')
    if (
      this.state.deadline_st &&
      this.state.deadline_st.format('YYYY-MM-DD') == this.state.deadline_et.format('YYYY-MM-DD')
    ) {
      let deadline_et =
        this.state.deadline_et && new Date((new Date(this.state.deadline_st) / 1000 + 86400) * 1000)
      params.deadline_et =
        this.state.deadline_et &&
        `${deadline_et.getFullYear()}-${deadline_et.getMonth() + 1}-${deadline_et.getDate()}`
    } else {
      params.deadline_et = this.state.deadline_et && this.state.deadline_et.format('YYYY-MM-DD')
    }
    Turbolinks.visit('?' + qs.stringify(uugmUtil.filterObj(params), { arrayFormat: 'bracket' }))
  }

  reset() {
    const key = qs.parse(window.location.search).key
    Turbolinks.visit(`/ucases?key=${key}`)
  }
  allCount = () => {
    let count = 0
    for (let k in this.props.gbc) {
      count += this.props.gbc[k]
    }
    return count
  }

  onTabChange = e => {
    let key = e.target.value
    let query = qs.parse(location.search, { arrayFormat: 'bracket' })
    if (query.page) {
      query.page = 1
    }
    Turbolinks.visit(
      '?' + qs.stringify(Object.assign(query, { stage: key }), { arrayFormat: 'bracket' })
    )

    if (key != 'all') {
      let [stage, review_status] = key.split('_')
      Turbolinks.visit(
        '?' +
          qs.stringify(Object.assign(query, { stage, review_status }), { arrayFormat: 'bracket' })
      )
    } else {
      Turbolinks.visit(
        '?' +
          qs.stringify(Object.assign(query, { stage: null, review_status: null }), {
            arrayFormat: 'bracket',
          })
      )
    }
  }
  changeTab = () => {
    console.log('111')
  }
  setSearchList = () => {
    const query = qs.parse(location.search)
    if (query.key == '1') {
      return (
        <Row>
          <Col span={12}>
            <FormItem {...formItemLayout} label={t.case + '编号'}>
              <Input
                placeholder={`请输入${t.case}编号`}
                onChange={this.onChange('id')}
                value={this.state.id}
              />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem {...formItemLayout} label="上报时间">
              <RangePicker
                onChange={this.onChange('inspected_at_start', 'inspected_at_end')}
                value={[this.state.inspected_at_start, this.state.inspected_at_end]}
              />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem {...formItemLayout} label="截止时间">
              <RangePicker
                onChange={this.onChange('deadline_st', 'deadline_et')}
                value={[this.state.deadline_st, this.state.deadline_et]}
              />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem {...formItemLayout} label="案件阶段">
              <Select
                allowClear
                onChange={this.onChange('stage')}
                value={this.state.stage}
                placeholder="请选择案件阶段"
              >
                {this.props.project.stage_spec.stages.map(stage => (
                  <Option value={`${stage.id}`}>{stage.name}</Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          {this.props.role == '5' ? (
            <span>
              <Col span={12}>
                <FormItem {...formItemLayout} label="街道">
                  <InsSelect
                    placeholder="请选择街道"
                    chs={this.props.opts.responsiblers}
                    onChange={this.onChange('responsibler_id')}
                    value={this.state.responsibler_id}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="志愿者">
                  <InsSelect
                    chs={this.props.opts.inspectors}
                    onChange={this.onChange('creator_id')}
                    placeholder="请选择志愿者"
                    value={this.state.creator_id}
                  />
                </FormItem>
              </Col>
            </span>
          ) : (
            ''
          )}
          <Col span={12}>
            <FormItem {...formItemLayout} label="是否延期">
              <Radio.Group onChange={this.onChange('is_delayed')} value={this.state.is_delayed}>
                <Radio value={'true'}>是</Radio>
                <Radio value={'false'}>否</Radio>
              </Radio.Group>
            </FormItem>
          </Col>
        </Row>
      )
    } else {
      return (
        <Row>
          <Col span={12}>
            <FormItem {...formItemLayout} label={t.case + '编号'}>
              <Input
                placeholder={`请输入${t.case}编号`}
                onChange={this.onChange('id')}
                value={this.state.id}
              />
            </FormItem>
          </Col>
          {this.props.role != '4' ? (
            query.key == '2' || query.key == '6' ? (
              <Col span={12}>
                <FormItem {...formItemLayout} label="上报人">
                  <InsSelect
                    chs={this.props.opts.inspectors}
                    onChange={this.onChange('creator_id')}
                    placeholder="请选择上报人员"
                    value={this.state.creator_id}
                  />
                </FormItem>
              </Col>
            ) : (
              <Col span={12}>
                <FormItem {...formItemLayout} label="上报单位">
                  <InsSelect
                    chs={this.props.opts.responsiblers}
                    onChange={this.onChange('creator_id')}
                    placeholder="请选择上报单位"
                    value={this.state.creator_id}
                  />
                </FormItem>
              </Col>
            )
          ) : query.key == '2' || query.key == '6' ? (
            <Col span={12}>
              <FormItem {...formItemLayout} label="上报人">
                <InsSelect
                  chs={this.props.opts.inspectors}
                  onChange={this.onChange('creator_id')}
                  placeholder="请选择上报人员"
                  value={this.state.creator_id}
                />
              </FormItem>
            </Col>
          ) : query.key == '4' ? (
            ''
          ) : (
            <Col span={12}>
              <FormItem {...formItemLayout} label="上报单位">
                <InsSelect
                  chs={this.props.opts.responsiblers}
                  onChange={this.onChange('creator_id')}
                  placeholder="请选择上报单位"
                  value={this.state.creator_id}
                />
              </FormItem>
            </Col>
          )}
          <Col span={12}>
            <FormItem {...formItemLayout} label="指标">
              <Cascader
                placeholder="请选择指标"
                options={this.props.opts.standards}
                onChange={this.onChange('standard_ids')}
                changeOnSelect
                value={this.state.standard_ids}
              />
            </FormItem>
          </Col>
          {query.key !== '2' && query.key !== '6' ? (
            <Col span={12}>
              <FormItem {...formItemLayout} label="包干路段">
                <Cascader
                  placeholder="请选择包干路段"
                  options={this.props.opts.areas}
                  onChange={this.onChange('area_ids')}
                  changeOnSelect
                  value={this.state.area_ids}
                />
              </FormItem>
            </Col>
          ) : (
            <Col span={12}>
              <FormItem {...formItemLayout} label="区域">
                <Cascader
                  placeholder="请选择区域"
                  options={this.props.opts.areas}
                  onChange={this.onChange('area_ids')}
                  changeOnSelect
                  value={this.state.area_ids}
                />
              </FormItem>
            </Col>
          )}
          <Col span={12}>
            <FormItem {...formItemLayout} label="上报时间">
              <RangePicker
                onChange={this.onChange('inspected_at_start', 'inspected_at_end')}
                value={[this.state.inspected_at_start, this.state.inspected_at_end]}
              />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem {...formItemLayout} label="整改时间">
              <RangePicker
                onChange={this.onChange('reformed_at_start', 'reformed_at_end')}
                value={[this.state.reformed_at_start, this.state.reformed_at_end]}
              />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem {...formItemLayout} label="截止时间">
              <RangePicker
                onChange={this.onChange('deadline_st', 'deadline_et')}
                value={[this.state.deadline_st, this.state.deadline_et]}
              />
            </FormItem>
          </Col>
          {this.props.role != '4' ? (
            <span>
              <Col span={12}>
                <FormItem {...formItemLayout} label="责任单位">
                  <InsSelect
                    placeholder="请选择责任单位"
                    chs={this.props.opts.responsiblers}
                    onChange={this.onChange('responsibler_id')}
                    value={this.state.responsibler_id}
                  />
                </FormItem>
              </Col>
            </span>
          ) : (
            ''
          )}
          <Col span={12}>
            <FormItem {...formItemLayout} label="案件阶段">
              <Select
                allowClear
                onChange={this.onChange('stage')}
                value={this.state.stage}
                placeholder="请选择案件阶段"
              >
                {this.props.project.stage_spec.stages
                  // .filter(x => (this.props.role == '5' ? x.id != 1 : true))
                  .map(stage => (
                    <Option value={`${stage.id}`}>{stage.name}</Option>
                  ))}
              </Select>
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem {...formItemLayout} label="案件状态">
              <Select
                allowClear
                onChange={this.onChange('review_status')}
                value={this.state.review_status}
                placeholder="请选择案件状态"
              >
                {this.props.project.stage_spec.review_status
                  // .filter(x => (this.props.role == '5' ? ![9, 10].includes(x.id) : true))
                  .map(rs => (
                    <Option value={`${rs.id}`}>{rs.name}</Option>
                  ))}
              </Select>
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem {...formItemLayout} label="案件是否延期">
              <Radio.Group onChange={this.onChange('is_delayed')} value={this.state.is_delayed}>
                <Radio value={'true'}>是</Radio>
                <Radio value={'false'}>否</Radio>
              </Radio.Group>
            </FormItem>
          </Col>
          {query.key !== '3' && (
            <Col span={12}>
              <FormItem {...formItemLayout} label="问题描述">
                <Select
                  showSearch
                  onChange={this.onChange('options')}
                  value={this.state.options}
                  placeholder="请选择问题描述"
                  filterOption={(input, option) => {
                    return option.props.children.indexOf(input) >= 0
                  }}
                >
                  {this.state.optionList.map(list => (
                    <Option value={`${list.value}`}>{list.label}</Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
          )}
        </Row>
      )
    }
  }
  render() {
    console.log('props', this.props)
    let query = qs.parse(location.search)
    let stageStatus = `${query.stage}_${query.review_status}`
    let isStageStatus = query.review_status || query.stage
    return (
      <div>
        <RadioGroup
          onChange={this.onTabChange}
          style={{ marginBottom: '20px' }}
          value={isStageStatus ? stageStatus : 'all'}
        >
          <RadioButton value="all">{`全部(${this.allCount()})`}</RadioButton>
          {(query.key === '2' || query.key === '6') &&
          (this.props.role === '5' || this.props.role === '2') ? (
            <RadioButton value="2_1">{`区确认待审核(${this.props.gbc['2,1'] || 0})`}</RadioButton>
          ) : this.props.role != '4' ? (
            <RadioButton value="1_1">{`上报待审核(${this.props.gbc['1,1'] || 0})`}</RadioButton>
          ) : (
            ''
          )}
          {this.props.role === '4' && (query.key == 2 || query.key == 6) ? (
            <RadioButton value="3_4">{`待整改(${this.props.gbc['3,4'] || 0})`}</RadioButton>
          ) : (
            ''
          )}
          {query.key !== '2' && query.key !== '6' ? (
            <RadioButton value="3_4">{`整改中(${this.props.gbc['3,4'] || 0})`}</RadioButton>
          ) : (
            <RadioButton value="3_1">{`整改待审核(${this.props.gbc['3,1'] || 0})`}</RadioButton>
          )}
          {query.key == '1' ? (
            <RadioButton value="1_8">{`审核仲裁(${this.props.gbc['1,8'] || 0})`}</RadioButton>
          ) : (
            <RadioButton value="3_8">{`审核仲裁(${this.props.gbc['3,8'] || 0})`}</RadioButton>
          )}
          {query.key == '1' || query.key == '2' || query.key == '6' ? (
            <RadioButton value="5_5">{`待复核(${this.props.gbc['5,5'] || 0})`}</RadioButton>
          ) : (
            ''
          )}
          <RadioButton value="6">{`已结案(${this.props.gbc['6,'] || 0})`}</RadioButton>
        </RadioGroup>
        <Form>
          {this.setSearchList()}
          <Row>
            <Col span={4} offset={19}>
              <FormItem>
                <Col span={10}>
                  <Button type="primary" onClick={this.search}>
                    查询
                  </Button>
                </Col>
                <Button onClick={this.reset}>重置</Button>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }
}
