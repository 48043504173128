import React from "react"
import { Button, Table } from 'antd'
import moment from "moment"
import * as qs from "query-string"
import 'moment/locale/zh-cn'
moment.locale("zh-cn")
const reportType = {
	date: {
		name: '日报',
	}
}

function genReports(st, type = 'days') {
	let i = 0
	let list = []
	let time
	switch (type) {
		case "days":
			time = moment(st).startOf('day')
			break;
		case "weeks":
			time = moment(st).day(1)
			break;
		case "months":
			time = moment(st).date(1)
			break;
		case "quarters":
			// 再说
			time = moment(st).quarter(moment(st).quarter())
			break;
		case "years":
			// 再说
			time = moment(st).year(moment(st).year())
			break;
		default:
			time = moment(st)
			break;
	}
	console.log(time)
	// 确定起始日期
	while(moment(time.toDate().getTime()).add(17,"hour").add(3,"minute").toDate().getTime() <= moment(Date.now()).toDate().getTime()) {
		console.log(moment(Date.now()).startOf('day').toDate().getTime())
		let _sst = time.format("YYYY年M月D日")
		let _st = time.toDate()
		// if(moment(time).startOf('day').add(2,"hour").add(4,"minute").toDate().getTime() > Date.now()) {	break }
		list.push(
			{
				key: i,
				id: `第${i += 1}期`,
				name: `${_sst}日报`,
				date: time.format('LL'),
				time: _sst
			}
		)
		time.add(1, type)
	}
	return list.reverse()
}

export default class Component extends React.Component {
	columns = [{
	  	title: '日报期数',
	  	dataIndex: 'id'
	}, {
		title: '报告名称',
		dataIndex: 'name',
		width:600
	}, {
	  	title: '上报日期',
	  	dataIndex: 'date'
	},{
		title:'操作',
		render: (x) => { return location.search.includes("canUpdate") ?  (<div> <a href="javascript:;" onClick={this.getReport(x.time)} >下载查看</a>
		<a  href="javascript:;"  onClick={(e) => this.onClickDownload(x.time,e)}> 更新报告 </a> </div>) : (<a href="javascript:;" onClick={this.getReport(x.time)} >下载查看</a>)
		}
	}];

	getReport(st) {
		console.log(st)
		return ()=>{
			let url = `/ucases/dailyReportGet?date=${st}`
			axios.get(url).then(({ data }) => {
				console.info(data)
				if (data.code == 200) {
					// console.log(data)
					uugmUtil.openDownloadDialog(`http://ugm.oss-cn-beijing.aliyuncs.com/${data.data}`, '报告导出.docx')
				} else {
					console.log(data)
					alert(data.msg)
					// 错误提示
				}
			})
		}
	}

	onClickDownload(st,e) {
		// e.target.innerHTML = "更新中"
		console.log(st)
		let url = `/ucases/dailyReport?date=${st}`
		console.log(e)
		let target = e.target
		axios.get(url).then(({ data }) => {
			console.info(data)
			if (data.code == 200) {
				target.innerHTML = "更新完毕"
				uugmUtil.openDownloadDialog(`http://ugm.oss-cn-beijing.aliyuncs.com/${data.data}`, '报告导出.docx')
			} else {
				console.log(data)
				// 错误提示
			}
		})
		// return ()=>{
		// 	let url = `/ucases/dailyReport?date=${st}`
		// 	axios.get(url).then(({ data }) => {
		// 		console.info(data)
		// 		if (data.code == 200) {
		// 			// console.log(data)
		// 			console.log(e.getTarget())
		// 			e.target.innerHTML = "更新完毕"
		// 			uugmUtil.openDownloadDialog(`http://ugm.oss-cn-beijing.aliyuncs.com/${data.data.key}`, '报告导出.docx')
		// 		} else {
		// 			console.log(data)
		// 			// 错误提示
		// 		}
		// 	})
		// }
	}

  reports() {
		let query = qs.parse(location.search)
		let list = []
		let st = new Date('2019-04-15')
		list = genReports(st, query.type)
		// console.log(list)
		return list
	}

	render(){
		console.log(this.props)
		return(
			<Table bordered columns={this.columns} dataSource={this.reports()} />
		)	
	}		 
}





