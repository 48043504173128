import { Table } from 'antd'
import React from 'react'

export default class Component extends React.Component {
  render() {
    const columns = [
      {
        title: '排名',
        width: 150,
        key: 'order',
        render: (text, record, index) => {
          return <span>{index + 1}</span>
        },
      },
      {
        title: '指标',
        dataIndex: 'standardLabel',
        key: 'standardLabel',
      },
      {
        title: '案件数',
        dataIndex: 'caseCount',
        key: 'caseCount',
        width: 150,
      },
      {
        title: '问题案件数',
        dataIndex: 'questionCaseCount',
        key: 'questionCaseCount',
        width: 150,
      },
      {
        title: '扣分',
        dataIndex: 'score',
        key: 'score',
        width: 150,
        render: score => {
          return <span>{score && score.toFixed(2)}</span>
        },
      },
    ]
    return (
      <div>
        <div>单位名称: {this.props.name}</div>
        <div style={{ margin: '20px 0' }}>
          总分: {this.props.total && this.props.total.toFixed(2)}
        </div>
        <div>
          分数详情
          <Table
            columns={columns}
            dataSource={this.props.scoreList}
            pagination={false}
            scroll={{ y: 300 }}
            style={{ marginTop: '10px' }}
          />
        </div>
      </div>
    )
  }
}
