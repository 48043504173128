import { Table } from 'antd'
import React from 'react'

const columns = [{
  title:'名称（账号）',
  dataIndex:'user',
  render:(u)=>{
    return <span>{u.name}</span>
  }
},{
  title:'操作时间',
  dataIndex:'created_at',
  render:(time)=>{
    return <span>{time.split("T")[0]} {time.split("T")[1].split('.')[0]}</span>
  }
},{
  title:'操作类型',
  dataIndex:'ltype',
},{
  title:'当前案件状态',
  dataIndex:'current_stage',
},{
  title:'备注',
  dataIndex:'describe',
  width:500,
  render:(d)=>{
    if(d == ''){
      return <span>无</span>
    }
    return <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{d}</div>
  }
}]
export default class Component extends React.Component {
  render(){
    const data=this.props.action_logs
    console.log("props",this.props)
    return(
      <div>
        <div style={{fontSize:'16px',margin:'20px 0',fontWeight:'bold'}}>问题日志</div>
        <Table columns={columns} dataSource={data} />
      </div>
    )
  }
}