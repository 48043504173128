import { Form } from 'antd'
import React from 'react'

class Component extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return <div />
  }
}
