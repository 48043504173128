import { Controller } from 'stimulus'

export default class extends Controller {
	static targets = ['item','contact','ugm','plan']
	connect(){}
	toFunction(){
		this.itemTarget.scrollIntoView()
	}
	toContact(){
		this.contactTarget.scrollIntoView()
	}
	toUgm(){
		this.ugmTarget.scrollIntoView()
	}
	toPlan(){
		this.planTarget.scrollIntoView()
	}
}