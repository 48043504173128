import { Map, Marker, InfoWindow, TileLayer } from 'react-amap'
import React from 'react'
import 'moment/locale/zh-cn'
import Axios from 'axios'
import * as queryString from 'query-string'

import {getAssembleData} from './quick_get_standing_books'


export default class Component extends React.Component {
  state = { queryString: '', targetArr: [], disabled: true }

  constructor(props) {
    // console.log('geo map 启动', props)
    super(props)
    this.mapEvents = {
      created: map => {
        this.map = map
        this.geoJSON(queryString.stringify(queryString.parse(window.location.search)), {
          arrayFormat: 'bracket',
        })
      },
    }
  }
  componentWillReceiveProps(nextProps) {
    const props = this.props
    console.log(props, nextProps)
    if (!props.disabled && nextProps.disabled) {
      this.handleUndo()
    }
    if (nextProps.queryString == this.state.queryString) {
      return
    }
    this.setState({ queryString: nextProps.queryString })
    // this.geoJSON(props.queryString)
    if (this.state.geojson && this.state.geojson.clearOverlays) {
      this.state.geojson.clearOverlays()
    }
    if (this.map) {
	    this.map.clearMap()
    }
    this.loadData(nextProps.queryString).then(v => {
      // this.state.geojson.importData(v.data)
      this.state.geojson.on('click', this.geoClick)
    })
  }
  loadData = async (query = '') => {
    const res = await axios.get('/standing_books/geo_quick.json?' + query)
    // const res = await getAssembleData(query)
    const admistrativeArea  = await axios.get('/standing_books/getAdministrativeArea')
    console.log(admistrativeArea)
    admistrativeArea.data.data.features.map(adaFeature => {
	    let adaPolygon  = new AMap.Polygon({
		    path: adaFeature.geometry.coordinates[0][0],
		    fillOpacity: 0,
		    strokeColor: 'yellow',
        strokeStyle: 'dashed',
		    fillColor: 'red',
		    strokeWeight: '2.5'
	    })
	    this.map.add(adaPolygon)
    })
    let outLintClick = e => {
      e.target.setOptions({ strokeColor: 'red' })
    }
    let outLineMouseout = e => {
      e.target.setOptions({ strokeColor: '#66A6F6' })
    }
    let pointAndLineProcess = feature => {
      let pointsArray = feature.polygon_set.points || []
      let icon = new AMap.Icon({
        size: new AMap.Size(35, 48), // 图标尺寸
        image: this.props.icon ? this.props.icon : `/assets/marker.png`, // Icon的图像
        imageSize: new AMap.Size(35, 48), // 根据所设置的大小拉伸或压缩图片
      })
      pointsArray.map(points =>
        points.map(point => {
          let marker = new AMap.Marker({
            position: new AMap.LngLat(point.position.longitude, point.position.latitude),
            icon: icon,
          })
          this.map.add(marker)
          let specialData = feature.properties
          let pointClick = e => {
            this.openInfo(e.lnglat, specialData, e.target)
          }
          marker.on('click', pointClick)
        })
      )
      let linesArray = feature.polygon_set.lines || []
      linesArray.map(line => {
        var polyline = new AMap.Polyline({
          path: line,
          borderWeight: 1, // 线条宽度，默认为 1
          strokeColor: '#66A6F6', // 线条颜色
          lineJoin: 'round', // 折线拐点连接处样式
        })
        this.map.add(polyline)
        let specialData = feature.properties
        let pointClick = e => {
          this.openInfo(e.lnglat, specialData, e.target)
        }

        // polyline.on('click', pointClick)
        polyline.on('click', pointClick)
        polyline.on('mouseover', outLintClick)
        polyline.on('mouseout', outLineMouseout)
      })
      let polygonArray = feature.polygon_set.polygon || []
      polygonArray.map(polygon => {
        var polygon = new AMap.Polygon({
          path: polygon,
          fillOpacity: 0.4,
          strokeColor: '#66A6F6',
          fillColor: '#D1E6EE',
        })
        this.map.add(polygon)
        this.setState({ geojson : polygon })
        let specialData = feature.properties
        let polygonClick = e => {
          this.openInfo(e.lnglat, specialData, e.target)
        }
        polygon.on('click', polygonClick)
      })
    }
    res.data.features.map(pointAndLineProcess)
    return res.data
  }

  geoJSON = async query => {
    let geoJSON = await this.loadData(query)
    // console.log(geoJSON.data.features)

    // let geojson = new AMap.GeoJSON({
    //   geoJSON: geoJSON,
    //   //还可以自定义getMarker和getPolyline
    //   getPolygon: function(geojson, lnglats) {
    //     return new AMap.Polygon({
    //       path: lnglats,
    //       fillOpacity: 0.4,
    //       strokeColor: '#66A6F6',
    //       fillColor: '#D1E6EE',
    //     })
    //   },
    // })
    //geojson.setMap(this.map)
    //geojson.on('click', this.geoClick)
    //this.setState({ geojson })
  }


  geoClick = e => {
    let data = e.target.getExtData()._geoJsonProperties
    this.openInfo(e.lnglat, data, e.target)
  }
  handleUndo = () => {
    this.state.targetArr.map(x => {
      x.show()
    })
    this.setState({
      targetArr: [],
      disabled: true,
    })
  }
  openInfo = (location, data, target) => {
    //构建信息窗体中显示的内容
    let targetArr = []
    let info = []
    let datacontent = ''
    let detailBtn = ''
    if (data.data == null || data.data == '') {
      datacontent = '暂无数据'
      detailBtn = ''
    } else {
      data.data.map(x => {
        datacontent = datacontent + `<div>${x.name}: ${x.value == null ? '暂无数据' : x.value}</div>`
      })
      detailBtn = `<a href="/standing_books/${
        data.id
      }" target='_blank'><Button class="geo_detail">查看详情</Button></a>`
    }

    info.push(
      `<div class="amap-ext-info">
        <div class="box">
          <div class="arrow">
            <em></em><span></span>
          </div>
          <div class="content"></div>
          ${datacontent}
          <div>
            <div class="show-btn" style="margin-left:80px"> 
              ${detailBtn}
              <Button class="geo_close" style="margin-right:10px;" type="primary" onClick="javascript:hide()">隐 藏</Button>
              <Button class="geo_close" type="primary" onClick="javascript:closeInfo()">关 闭</Button>
            </div>
          </div>
        </div>
      </div>`
    )
    let infoWindow = new AMap.InfoWindow({
      isCustom: true,
      content: info.join(''),
      offset: new AMap.Pixel(-4, -20),
    })
    infoWindow.open(this.map, location)
    window.closeInfo = () => {
      infoWindow.close()
    }
    window.hide = () => {
      this.state.targetArr.push(target)
      this.setState({
        targetArr: this.state.targetArr,
        disabled: false,
      })
      this.props.getAble(false)
      target.hide()
      infoWindow.close()
    }
  }
  componentWillUnmount() {
    // this.setState({
    // })
  }
  render() {
    const pluginProps = {
      enableHighAccuracy: true,
      timeout: 10000,
      showButton: true,
      zoomToAccuracy: true,
    }
    // const points = this.props.points
    let Location = this.location
    // var satellite = new TileLayer.Satellite()
    return (
      <div style={{ height: '100%' }}>
        <Map
          amapkey={'d57b050e830566009d1332a076231223'}
          // mapStyle={'amap://styles/a59593ff89ee3597dbc63eb987f5a36f'}
          zoom={12}
          events={this.mapEvents}
          plugins={['MapType', 'Scale']}
          center={{longitude:116.792529 ,latitude: 39.759776}}
          version={'1.4.9'}
        />
      </div>
    )
  }
}
