import React from 'react'
import { DatePicker, Radio, Row, Table, Select, Button, Icon, Tooltip } from 'antd'
import Map from '../cases/map'
// 引入 ECharts 主模块
import echarts from 'echarts/lib/echarts'
// 引入饼图
import 'echarts/lib/chart/line'
// 引入标题组件
import 'echarts/lib/component/tooltip'
import { getDays, firstDate, lastDate, firstWeek, lastWeek } from './getdays'
import moment from 'moment'

const { RangePicker } = DatePicker
const { Option } = Select

export default class Component extends React.Component {
  state = {
    mode: 'list',
    type: 'street',
    st: firstDate(),
    et: lastDate(),
    slug: [],
    path: [],
    level: 1,
    name: '全部数据',
    timemode: 'month',
    checkway: 'third',
  }
  setColumns = () => {
    if (this.state.level == 2) {
      return [
        {
          title: '排名',
          dataIndex: 'rank',
          key: 'rank',
        },
        {
          title: () => {
            if (this.state.type === 'street') {
              return '街镇'
            }
            if (this.state.type === 'office') {
              return '委办局'
            }
            if (this.state.type === 'point') {
              return '点位'
            }
            if (this.state.type === 'standard') {
              return '指标'
            }
          },
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: () => {
            if (this.state.checkway === 'third') {
              return '复核数量'
            } else {
              return '整改数量'
            }
          },
          dataIndex: 'number',
          key: 'number',
        },
        {
          title: () => {
            if (this.state.checkway === 'third') {
              return '复核不通过数量'
            } else {
              return '整改不通过数量'
            }
          },
          key: 'failed',
          render: data => {
            if (this.state.checkway === 'third') {
              return `${data.reinspect_failed}`
            } else {
              return `${data.tweak_failed}`
            }
          },
        },
        {
          title: '反弹率',
          dataIndex: 'rate',
          key: 'rate',
        },
      ]
    } else {
      return [
        {
          title: '排名',
          dataIndex: 'rank',
          key: 'rank',
        },
        {
          title: () => {
            if (this.state.type === 'street') {
              return '街镇'
            }
            if (this.state.type === 'office') {
              return '委办局'
            }
            if (this.state.type === 'point') {
              return '点位'
            }
            if (this.state.type === 'standard') {
              return '指标'
            }
          },
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: () => {
            if (this.state.checkway === 'third') {
              return '复核数量'
            } else {
              return '整改数量'
            }
          },
          dataIndex: 'number',
          key: 'number',
        },
        {
          title: () => {
            if (this.state.checkway === 'third') {
              return '复核不通过数量'
            } else {
              return '整改不通过数量'
            }
          },
          key: 'failed',
          render: data => {
            if (this.state.checkway === 'third') {
              return `${data.reinspect_failed}`
            } else {
              return `${data.tweak_failed}`
            }
          },
        },
        {
          title: '反弹率',
          dataIndex: 'rate',
          key: 'rate',
        },
        {
          title: '操作',
          key: 'action',
          render: data => {
            return (
              <span
                style={{ color: '#6190E8', cursor: 'pointer' }}
                onClick={() => {
                  this.setNext(data)
                }}
              >
                继续查看
              </span>
            )
          },
        },
      ]
    }
  }
  setNext = data => {
    let _slug = this.state.slug
    let _path = this.state.path
    _slug.push(data.slug)
    _path.push(this.state.type)
    this.setState({
      slug: _slug,
      path: _path,
      name: data.name,
    })
    if (this.state.level == 1) {
      this.setState({
        level: 2,
      })
    }
    if (this.state.level == 1) {
      if (this.state.type === 'street' || this.state.type === 'office') {
        this.setState({
          type: 'standard',
        })
      }
    }
    this.props.gotoNext(_path, _slug)
  }
  constructor(props) {
    super(props)
  }
  componentDidUpdate() {
    this.setBar()
  }
  handleBarData = () => {
    const { data } = this.props
    const arr = getDays(this.state.st, this.state.et, 0)
    let result = []
    let time = []
    let count = []
    let failed = []
    arr.map(a => {
      result.push({ [a]: { reinspect_count: 0, reinspect_failed: 0 } })
    })
    if (data && data.trend.data.length !== 0) {
      result.map((r, i) => {
        data.trend.data.map(d => {
          if (d.date == Object.keys(r)) {
            result[i] = {
              [d.date]: {
                reinspect_count: d.reinspect_count || d.tweak_count,
                reinspect_failed: d.reinspect_failed || d.tweak_failed,
              },
            }
          }
        })
      })
    }
    result.map(r => {
      time.push(Object.keys(r)[0])
      count.push(Object.values(r)[0].reinspect_count)
      failed.push(Object.values(r)[0].reinspect_failed)
    })
    return { time, count, failed }
  }
  setBar = () => {
    const barData = this.handleBarData()
    const chart = echarts.init(document.getElementById('bar'))
    chart.setOption({
      grid: {
        top: '12%',
        left: '1%',
        right: '6%',
        bottom: '8%',
        containLabel: true,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          lineStyle: {
            opacity: 0.5,
          },
        },
        formatter: p => {
          if (this.state.checkway === 'third') {
            return `复核数量: ${p[0].value}<br/>复核不通过数量: ${p[1].value}`
          } else {
            return `整改数量: ${p[0].value}<br/>整改不通过数量: ${p[1].value}`
          }
        },
      },
      xAxis: [
        {
          name: '日期',
          type: 'category',
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.45)',
            },
          },
          axisLabel: {
            rotate: 45,
            textStyle: {
              color: 'rgba(0, 0, 0, 0.45)',
              margin: 15,
            },
          },
          axisTick: {
            show: false,
          },
          data: barData.time,
        },
      ],
      yAxis: [
        {
          name: '数量',
          type: 'value',
          min: 0,
          splitNumber: 7,
          splitLine: {
            show: false,
            lineStyle: {
              color: '#0a3256',
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.45)',
            },
          },
          axisLabel: {
            margin: 20,
            textStyle: {
              color: 'rgba(0, 0, 0, 0.45)',
            },
          },
          axisTick: {
            show: false,
          },
        },
      ],
      series: [
        {
          name: '复核通过数量',
          type: 'line',
          tooltip: {
            show: true,
          },
          label: {
            show: false,
            position: 'top',
            textStyle: {
              color: '#fff',
            },
          },
          itemStyle: {
            normal: {
              color: '#35B1F6',
              label: {
                show: false,
                textStyle: {
                  color: '#35B1F6',
                },
                position: 'top',
                formatter(p) {
                  return p.value
                },
              },
            },
          },
          data: barData.count,
        },
        {
          name: '复核不通过数量',
          type: 'line',
          tooltip: {
            show: true,
          },
          label: {
            show: false,
            position: 'top',
            textStyle: {
              color: '#fff',
            },
          },
          itemStyle: {
            normal: {
              color: '#FCBE92',
              label: {
                show: false,
                textStyle: {
                  color: '#35B1F6',
                },
                position: 'top',
                formatter(p) {
                  return p.value
                },
              },
            },
          },
          data: barData.failed,
        },
      ],
    })
  }
  setTable = () => {
    const { data } = this.props
    console.log('data====', data)
    if (data) {
      if (this.state.type === 'street') {
        return { data: data.list.data.street.data }
      }
      if (this.state.type === 'office') {
        return { data: data.list.data.office.data }
      }
      if (this.state.type === 'point') {
        return { data: data.list.data.point.data }
      }
      if (this.state.type === 'standard') {
        return { data: data.list.data.standard.data }
      }
    } else {
      return { data: [] }
    }
  }
  changeRange = e => {
    const value = e.target.value
    this.setState({
      timemode: value,
    })
    if (value === 'week') {
      this.setState({
        st: firstWeek(),
        et: lastWeek(),
      })
      this.props.changeTime([firstWeek(), lastWeek()], this.state.level)
    }
    if (value === 'month') {
      this.setState({
        st: firstDate(),
        et: lastDate(),
      })
      this.props.changeTime([firstDate(), lastDate()], this.state.level)
    }
  }
  changeSelect = value => {
    this.setState({
      type: value,
    })
  }
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
    },
  }
  changeMode = e => {
    this.setState({
      mode: e.target.value,
    })
  }
  changeDate = (date, datestring) => {
    this.setState({
      st: datestring[0],
      et: datestring[1],
    })
    this.props.changeTime(datestring, this.state.level)
  }
  changeCheck = e => {
    const { value } = e.target
    this.setState({
      checkway: value,
    })
    this.props.changeCheck(value, this.state.level)
  }
  export = () => {
    this.props.export(
      this.state.st,
      this.state.et,
      this.state.type,
      this.state.level,
      this.state.checkway
    )
  }
  render() {
    const { data } = this.props
    console.log('ll', this.state.level)
    return (
      <div>
        <div>
          时间筛选:{' '}
          <RangePicker
            style={{ margin: '0 10px 0 20px' }}
            value={[moment(this.state.st), moment(this.state.et)]}
            onChange={this.changeDate}
            allowClear={false}
          />
          <Radio.Group onChange={this.changeRange} value={this.state.timemode}>
            <Radio.Button value="month">本月</Radio.Button>
            <Radio.Button value="week">本周</Radio.Button>
          </Radio.Group>
        </div>
        <div style={{ marginTop: '20px' }}>
          检查方式:{' '}
          <Radio.Group
            onChange={this.changeCheck}
            value={this.state.checkway}
            style={{ margin: '0 10px 0 20px' }}
          >
            <Radio.Button value="third">第三方检查</Radio.Button>
            <Radio.Button value="package">包干巡检</Radio.Button>
          </Radio.Group>
        </div>
        <h4 style={{ margin: '20px 0' }}>当前数据为: {this.state.name}</h4>
        <div className="template-container">
          <h3>数据概况</h3>
          <div>
            <Row type="flex" justify="start">
              {data &&
                data.data_info.data.map((d, i) => {
                  return (
                    <div className="template-statistics" key={i}>
                      <div>
                        {d.title}
                        <Tooltip title={d.tips}>
                          <Icon
                            type="question-circle"
                            style={{ width: '10px', marginLeft: '10px' }}
                          />
                        </Tooltip>
                      </div>
                      <div>{d.value}</div>
                    </div>
                  )
                })}
            </Row>
          </div>
        </div>
        <div className="template-container">
          <h3>{this.state.checkway === 'third' ? '复核案件数量趋势图' : '整改案件数量趋势图'}</h3>
          <div id="bar" style={{ width: '100%', height: '500px' }}></div>
        </div>
        {this.state.checkway == 'third' && this.state.level == 2 ? null : (
          <div className="template-container">
            <h3>问题列表</h3>
            <div className="opra_container">
              {/* <div style={{ float: 'left' }}>
                      <Radio.Group onChange={this.changeMode} value={this.state.mode} buttonStyle="solid">
                        <Radio.Button value="list">列表模式</Radio.Button>
                        <Radio.Button value="map">地图模式</Radio.Button>
                      </Radio.Group>
                    </div> */}
              <div style={{ float: 'right' }}>
                <Button type="primary" onClick={this.export}>
                  <Icon type="download" theme="outlined" />
                  生成全部文件
                </Button>
                {/* &nbsp;&nbsp;&nbsp;
                      <Button>
                        <Icon type="download" theme="outlined" />
                        生成所选文件
                      </Button> */}
              </div>
            </div>
            {this.state.mode === 'list' ? (
              <div>
                {this.state.level == 1 ? (
                  <Select
                    style={{ width: 120, margin: '0 0 10px 0' }}
                    onChange={this.changeSelect}
                    value={this.state.type}
                  >
                    <Option value="street">街镇</Option>
                    <Option value="office">委办局</Option>
                  </Select>
                ) : (
                  ''
                )}

                <Table columns={this.setColumns()} dataSource={this.setTable().data} bordered />
              </div>
            ) : (
              <div className={'map-list'}>
                <Map data={[]} />
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}
