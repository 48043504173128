import { Map, Polygon, PolyEditor, MouseTool, Markers, Polyline } from 'react-amap'
import Geolocation from 'react-amap-plugin-geolocation'
import Autocomplete from '../components/mapSearch'
import React from 'react'
import { Button, Icon, Tooltip } from 'antd'
import 'moment/locale/zh-cn'
import * as _ from 'lodash'

const styleIcon = {
  width: '35px',
}
export default class Component extends React.Component {
  state = {
    path: [],
    newpath: this.props.polygon || [],
    mapCenter: [],
    polygonActive: false,
    lineActive: false,
    draggable: false,
    disabled: false,
    linedisabled: false,
    test: false,
    edit: false,
    undo: false,
    done: false,
    search: false,
    markerdrag: true,
    markers: this.props.points || [],
    line: this.props.lines.length == 0 ? [] : this.props.lines[0],
    icon: this.props.icon,
    tooltipvisible: true,
  }
  constructor(props) {
    super(props)
    let self = this
    this.mapPlugins = ['ToolBar']
    var _this = this

	  this.mapEvents = {
		  created: async (map) => {
			  this.map = map
			  console.log("+++")
			  const admistrativeArea  = await axios.get('/standing_books/getAdministrativeArea')
			  console.log(admistrativeArea)
			  console.log("---")
			  admistrativeArea.data.data.features.map(adaFeature => {
				  let adaPolygon  = new AMap.Polygon({
					  path: adaFeature.geometry.coordinates[0][0],
					  fillOpacity: 0,
					  strokeColor: 'black',
					  strokeStyle: 'dashed',
					  fillColor: 'red',
					  strokeWeight: '1.0'
				  })
				  this.map.add(adaPolygon)
			  })
		  }
	  }

    // 点の事件
    this.markersEvents = {
      dragend(ins) {
        _this.state.markers.map((x, idx) => {
          if (ins.target.getExtData().myIndex == x.myIndex) {
            let newPosition = {
              position: { longitude: ins.lnglat.N, latitude: ins.lnglat.Q },
              myIndex: x.myIndex,
            }
            _this.state.markers.splice(x.myIndex, 1, newPosition)
          }
        })
        _this.saveMarkers()
      },
      click: ins => {
        _this.setState({
          tooltipvisible: false,
          markerDelIdx: ins.target.getExtData().myIndex,
          flag: 'marker',
        })
      },
    }

    // 多边形の事件
    this.polygonEvents = {
      dragend: ins => {
        this.setPath(ins)
        this.savePath()
      },
      click: ins => {
        _this.setState({
          tooltipvisible: false,
          delIndex: ins.target.getExtData(),
          flag: 'polygon',
        })
      },
    }

    // 多边形の编辑方法
    this.editorEvents = {
      created(ins) {
        console.log(ins)
      },
      addnode: ins => {
        this.setPath(ins)
      },
      adjust: ins => {
        this.setPath(ins)
      },
      removenode: ins => {
        this.setPath(ins)
      },
      end: ins => {
        this.setPath(ins)
        this.savePath()
      },
    }
    // 生成path
    this.setPath = function(ins) {
      let path = ins.target.getPath()
      _this.state.newpath.map((x, idx) => {
        if (ins.target.getExtData() == idx) {
          if (Array.prototype.isPrototypeOf(path[0])) {
            let newPath = ins.target.getPath()[0].map(x => [x.N, x.Q])
            _this.state.newpath.splice(idx, 1, newPath)
          } else {
            let newPath = ins.target.getPath().map(x => [x.N, x.Q])
            _this.state.newpath.splice(idx, 1, newPath)
          }
        }
      })
    }
    // 保存path
    this.savePath = function() {
      let finalpath = this.state.newpath
      _this.finalpath = finalpath
    }

    // 线の方法
    this.lineEvents = {
      dragend: ins => {
        this.saveLine(ins)
      },
      click: ins => {
        this.setState({
          flag: 'line',
          tooltipvisible: false,
        })
      },
    }
    this.lineEditor = {
      created: ins => {
        console.log(ins)
      },
      end: ins => {
        this.saveLine(ins)
      },
    }
    this.saveLine = function(ins) {
      let line = ins.target.getPath()
      let finalLine
      //这个坐标可以直接存
      if (Array.prototype.isPrototypeOf(line[0])) {
        finalLine = [line[0].map(x => [x.lng, x.lat])]
      } else {
        finalLine = [line.map(x => [x.lng, x.lat])]
      }
      this.finalLine = finalLine
    }
  }
  // 初始化の数据
  finalpath = this.state.newpath || []
  finalmarkers = this.state.markers
  markers = []
  finalLine = this.state.line
  oldpath = _.cloneDeep(this.props.polygon)
  oldline = _.cloneDeep(this.state.line)
  componentDidMount = () => {
    if (
      this.props.polygon.length == 0 &&
      this.props.lines.length == 0 &&
      this.props.points.length == 0
    ) {
      this.setState({
        mapCenter: [116.225836, 39.906344],
      })
    } else if (this.props.polygon.length != 0 && this.props.polygon[0].length != 0) {
      this.setState({
        mapCenter: this.props.polygon[0][0],
      })
    } else if (this.props.points.length != 0) {
      this.setState({
        mapCenter: this.props.points[0][0].position,
      })
    } else if (this.props.lines.length != 0) {
      this.setState({
        mapCenter: this.props.lines[0][0],
      })
    }
    if (this.props.polygon.length != 0 && this.state.line.length == 0) {
      this.setState({
        linedisabled: false,
        done: true,
        undo: true,
        search: true,
      })
    } else if (this.props.polygon.length != 0 && this.state.line.length != 0) {
      this.setState({
        linedisabled: true,
        done: true,
        undo: true,
        search: true,
      })
    } else if (this.props.polygon.length == 0 && this.state.line.length != 0) {
      this.setState({
        linedisabled: true,
        done:true,
        undo:true,
        search:true 
      })
    } else {
      this.setState({
        linedisabled: false,
        edit: true,
        undo: true,
        done: true,
        search: false,
      })
    }
    if (this.props.points) {
      let points = this.props.points
      points.map((x, index) => {
        this.markers.push(points[index][0])
      })
      // this.markers.push(...points)
      this.setState({
        markers: this.markers,
      })
    }
    if (location.pathname.indexOf('edit') == -1) {
      this.setState({
        markerdrag: false,
        tooltipvisible: false
      })
    }
    if (this.state.markers.length !== 0) {
      let markers = []
      this.state.markers.map((x, i) => {
        markers.push({ position: x[0].position, myIndex: i })
      })
      this.setState({
        markers,
      })
    }
  }
  edit = () => {
    this.setState({
      polygonActive: true,
      lineActive: true,
      draggable: false,
      edit: true,
      undo: true,
      done: false,
      markerdrag: true,
    })
  }
  move = () => {
    this.setState({
      polygonActive: false,
      draggable: true,
      edit: true,
      undo: true,
      done: false,
      markerdrag: true,
    })
  }
  finish = () => {
    this.setState({
      polygonActive: false,
      lineActive: false,
      draggable: false,
      undo: false,
      edit: false,
      done: true,
      markerdrag: !this.state.markerdrag,
    })
    this.saveMarkers()
    this.savePath()
  }
  saveMarkers = () => {
    let markers = this.state.markers
    let finalmarkers = []
    markers.map((x, idx) => {
      finalmarkers[idx] = [x]
    })
    this.finalmarkers = finalmarkers
  }
  undo = () => {
    let oldpoints = []
    let points = _.cloneDeep(this.props.points)
    let oldline = _.cloneDeep(this.oldline)
    points.map((x, index) => {
      oldpoints.push(points[index][0])
    })
    if (oldline.length == 0) {
      this.setState({
        linedisabled: false,
      })
    } else {
      this.setState({
        linedisabled: true,
      })
    }
    this.setState({
      newpath: _.cloneDeep(this.oldpath),
      markers: _.cloneDeep(oldpoints),
      line: _.cloneDeep(this.oldline),
      disabled: false,
      edit: false,
      undo: true,
      done: false,
      markerdrag: !this.state.markerdrag,
    })
  }
  del = () => {
    if (this.state.flag == 'polygon') {
      this.state.newpath.map((x, idx) => {
        if (this.state.delIndex == idx) {
          if (Array.prototype.isPrototypeOf(this.state.newpath[0])) {
            this.state.newpath.splice(idx, 1)
            this.setState({
              newpath: this.state.newpath,
              delIndex: 1000000,
              tooltipvisible: true,
              flag: '',
              undo: false,
              done: false,
            })
          } else {
            this.state.newpath.splice(idx, 1)
            this.setState({
              newpath: this.state.newpath,
              delIndex: 1000000,
              tooltipvisible: true,
              flag: '',
              undo: false,
              done: false,
            })
          }
        }
      })
    } else if (this.state.flag == 'line') {
      this.setState({
        line: [],
        tooltipvisible: true,
        flag: '',
        undo: false,
        done: false,
      })
    } else if (this.state.flag == 'marker') {
      this.setState({
        markers: this.state.markers.filter(x => {
          return x.myIndex !== this.state.markerDelIdx
        }),
        tooltipvisible: true,
        flag: '',
        undo: false,
        done: false,
      })
    }
  }
  // 生成随机pathの方法
  randomPath = () => {
    if (this.state.mapCenter[0]) {
      return {
        longitude: this.state.mapCenter[0] + Math.random() / 100,
        latitude: this.state.mapCenter[1] + Math.random() / 100,
      }
    } else {
      return {
        longitude: this.state.mapCenter.longitude + Math.random() / 100,
        latitude: this.state.mapCenter.latitude + Math.random() / 100,
      }
    }
  }
  // 生成随机点の方法
  randomMarker = () => {
    return Array(1)
      .fill(true)
      .map((e, idx) => {
        let position = this.randomPath()
        return {
          position,
          myIndex: this.state.markers.length,
        }
      })
  }
  //新建各种图形の方法
  newPolygon = () => {
    let path = Array(3)
      .fill(true)
      .map(this.randomPath)
    this.setState({
      newpath: this.state.newpath.concat([path].map(x => x)),
      draggable: true,
      polygonActive: true,
      edit: true,
      undo: true,
      done: false,
    })
  }
  newPolyline = () => {
    let line = Array(2)
      .fill(true)
      .map(this.randomPath)
    this.setState({
      line,
      draggable: true,
      lineActive: true,
      linedisabled: true,
      edit: true,
      undo: true,
      done: false,
    })
  }
  newMarker = () => {
    let markers = this.randomMarker()
    this.setState({
      markers: this.state.markers.concat(markers),
      draggable: false,
      done: false,
      markerdrag: true,
    })
  }
  // 搜索の方法
  selectfunc = e => {
    if (e.poi.location) {
      this.setState({ mapCenter: [e.poi.location.lng, e.poi.location.lat] })
    }
  }
  // 点の图标
  renderMarkerLayout = () => {
    return this.props.icon ? (
      <div>
        <img style={styleIcon} src={`/${this.props.icon}`} />
      </div>
    ) : (
      <div>
        <img style={styleIcon} src={`/assets/marker.png`} />
      </div>
    )
  }
  render() {
    console.log('path=====', this.props.polygon)
    let disabled
    if (location.pathname.indexOf('edit') > -1) {
      disabled = false
    } else {
      disabled = true
    }
    const style = {
      width: '500px',
      position: 'absolute',
      top: '10px',
      left: '10px',
    }
    const pluginProps = {
      city: 'beijing',
    }
    return (
      <div>
        <div style={{ width: '100%', height: 500, position: 'relative' }}>
          {this.state.polyline || this.props.polygon || this.props.points ? (
            <Map zoom={15} center={this.state.mapCenter} plugins={['MapType', 'Scale', 'ToolBar']} events={this.mapEvents}>
              {this.state.newpath.length == 0 || this.state.newpath[0].length == 0
                ? ''
                : this.state.newpath.map((x, idx) => {
                    return (
                      <Polygon
                        path={[x]}
                        style={{ strokeColor: '#66A6F6', fillOpacity: 0.4, fillColor: '#D1E6EE' }}
                        draggable={this.state.draggable}
                        events={this.polygonEvents}
                        key={idx}
                        extData={idx}
                      >
                        <PolyEditor active={this.state.polygonActive} events={this.editorEvents} />
                      </Polygon>
                    )
                  })}
              <Polyline
                path={this.state.line}
                style={{ strokeColor: '#66A6F6', fillOpacity: 0.4, fillColor: '#D1E6EE' }}
                draggable={this.state.draggable}
                events={this.lineEvents}
              >
                <PolyEditor active={this.state.lineActive} events={this.lineEditor} />
              </Polyline>
              <Markers
                markers={this.state.markers}
                draggable={this.state.markerdrag}
                events={this.markersEvents}
                render={this.renderMarkerLayout}
              />
              <Autocomplete
                disabled={this.state.search}
                options={pluginProps}
                onSelect={e => this.selectfunc(e)}
                style={style}
                placeholder="搜索地点"
              />
            </Map>
          ) : (
            <Map zoom={15} center={this.state.mapCenter} plugins={['MapType', 'Scale']}>
              <Autocomplete
                disabled={this.state.search}
                options={pluginProps}
                onSelect={e => this.selectfunc(e)}
                style={style}
                placeholder="搜索地点"
              />
            </Map>
          )}
          <div className={`map_btn_group ${disabled ? 'map_btn_group_hide' : ''}`}>
            <Button onClick={this.newMarker} size={'small'} style={{ marginRight: 5 }}>
              <img className="map_btn_img" src="/assets/marker.png" />点
            </Button>
            <Button
              onClick={this.newPolyline}
              style={{ marginRight: 5 }}
              size={'small'}
              disabled={this.state.linedisabled}
            >
              <Icon type="enter" />线
            </Button>
            <Button
              onClick={this.newPolygon}
              style={{ marginRight: 5 }}
              size={'small'}
              disabled={this.state.disabled}
            >
              <Icon type="gateway" />
              区域
            </Button>
            <Button
              onClick={this.move}
              style={{ marginRight: 5 }}
              size={'small'}
              disabled={this.state.edit}
            >
              <Icon type="edit" />
              移动
            </Button>
            <Button
              onClick={this.edit}
              style={{ marginRight: 5 }}
              size={'small'}
              disabled={this.state.edit}
            >
              <Icon type="edit" />
              编辑
            </Button>
            <Button
              type="primary"
              style={{ marginRight: 5 }}
              size={'small'}
              onClick={this.finish}
              disabled={this.state.done}
            >
              <Icon type="download" />
              保存
            </Button>
            <Button
              onClick={this.undo}
              style={{ marginRight: 5 }}
              size={'small'}
              disabled={this.state.undo}
            >
              <Icon type="undo" />
              恢复
            </Button>
            <Tooltip
              placement="topLeft"
              title="请先点击需要删除的地块"
              visible={this.state.tooltipvisible}
            >
              <Button onClick={this.del} size={'small'} type="danger">
                <Icon type="delete" />
                删除
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
    )
  }
}
