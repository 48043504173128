import { Button, Icon, Popconfirm, Divider, Table, LocaleProvider, } from 'antd'
import React from 'react'
import zh_CN from 'antd/lib/locale-provider/zh_CN'

export default class Component extends React.Component {
	state={
  }
  columns = [
    {
      key:'name',
      title: '项目名称',
      align:'center',
      dataIndex: 'name'
    },
    {
      key:'standard',
      title: '指标模板',
      align:'center',
      dataIndex: 'standard',
      render:(standard)=>{
        return <span>{standard.name}</span>
      }
    },
    {
      key:'area',
      title: '区域模板',
      align:'center',
      dataIndex: 'area',
      render:(area)=>{
        return <span>{area.name}</span>
      }
    },
    {
      key:'describe',
    	title: '描述',
      align:'center',
      dataIndex: 'describe'
    },
    {
      key:'reviewers',
    	title: '审核员',
      align:'center',
      dataIndex: 'reviewers',
      render:(reviewers)=>{
        return(
          reviewers.map((x,index)=>{
            return <div key={index}>{x.name}</div>
          })
        )
      }
    },
    {
      key:'inspectors',
    	title: '检查员',
      align:'center',
      dataIndex: 'inspectors',
      render:(inspectors)=>{
        return(
          inspectors.map((x,index)=>{
            return <div key={index}>{x.name}</div>
          })
        )
      }
    },
    {
      title: '操作',
      align:'center',
      key: 'action',
      render: data => {
        return (
          <span>
            <a target="_blank" href={`/projects/${data.id}`}>查看</a>
            <Divider type="vertical" />
            <a target="_blank" href={`/projects/${data.id}/edit`}>编辑</a>
          </span>
        )
      },
    },
  ]
  componentDidMount = () => {
    this.props.projects.map((x,index)=>{
      x.key = index.toString()
    })
  }
  create = ()=>{
    Turbolinks.visit('/projects/new')
  }
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };
  render() {
    console.log(this.props)
    return (
      <LocaleProvider locale={zh_CN}>
        <div>
          <div className="opra_container">
            <div style={{ float: 'left' }}>
              <Button type="primary" onClick={this.create}>
                <Icon type="plus" theme="outlined" />
                新建
              </Button>
            </div>
  	      </div>
  	      <Table rowSelection={this.rowSelection} columns={this.columns} dataSource={this.props.projects} pagination={this.props.pagination}/>
  			</div>
			</LocaleProvider>
		)
	}
}
