import React from "react"
import { Button,Icon } from 'antd'

export default class Component extends React.Component {
	state={
		arr:[1,2,3],
		count:1
	}
	changeItem = ()=>{
		let newArr=[]
		if(this.state.count == 3){
			this.setState({
				arr:[1,2,3,4,5,6],
				count:1
			})
		}
		else{
			this.state.arr.map((x)=>{
				newArr.push(x+6)
			})
			this.setState({
				arr:newArr,
				count:this.state.count+1
			})
		}	
	}
	render(){
		let title = [
			'延庆区河长制管理第三方巡检',
			'昌平区节水设施运行管理',
			'海淀区集中式污水处理单位及入河排污口清查',		
		]
		let name = [
			'北京市延庆区水务局',
			'北京市昌平区水务局',
			'北京市海淀区水务局',	
		]
		return(
			<div className="ugm_success">
				{
					this.state.arr.map((item,index)=>{
						return(
							<div key={index} className="ugm_success_section">
								<div className="ugm_success_item">
									<img src={`/assets/iwm${item}.png`}/>
									<div className="ugm_success_item_content">
										<div style={{color: '#666666',fontSize: 14}}>{title[item-1]}</div>
										<div style={{color: '#4A4A4A',fontSize: 16}}>{name[item-1]}</div>
									</div>
								</div>
							</div>
						)	
					})
				}
			</div>
		)
	}
}