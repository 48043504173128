import React from 'react'
import { List } from 'antd'

export default class Component extends React.Component {
  state = {}
  render() {
    const smallBook = [
      '《小册子-完整版》',
      '《小册子-包干巡检》',
      '《小册子-志愿者检查流程》',
      '《小册子-闭环检查流程》',
    ]
    const samllBookURL = [
      'https://ugm.oss-cn-beijing.aliyuncs.com/mtg/门头沟创城小册子完整版.docx',
      'https://ugm.oss-cn-beijing.aliyuncs.com/mtg/门头沟创城小册子_包干巡检.docx',
      'https://ugm.oss-cn-beijing.aliyuncs.com/mtg/门头沟小册子_志愿者检查流程.docx',
      'https://ugm.oss-cn-beijing.aliyuncs.com/mtg/门头沟创城小册子_第三方检查.docx',
    ]
    const video = [
      // '《第三方检查操作视频》',
      // '《包干巡检操作视频》',
      '《包干巡检-责任单位在后台上报整改视频》',
      '《包干巡检-责任单位在后台审核整改信息视频》',
      '《包干巡检-责任单位在小程序上报案件视频》',
      '《包干巡检-责任单位在小程序中上报整改信息视频》',
      '《包干巡检-责任单位在小程序中审核整改视频》',
      '《第三方检查-责任单位在后台上报整改视频》',
      '《第三方检查-责任单位在小程序中上报整改视频》',
      '《管理后台-基本功能视频》',
      '《责任单位小程序功能介绍视频》',
    ]
    const videoURL = [
      // 'https://ugm.oss-cn-beijing.aliyuncs.com/mtg/第三方检查.mp4',
      // 'https://ugm.oss-cn-beijing.aliyuncs.com/mtg/包干巡检.mp4',
      'https://ugm.oss-cn-beijing.aliyuncs.com/mtg/包干巡检_责任单位在后台上报整改.mp4',
      'https://ugm.oss-cn-beijing.aliyuncs.com/mtg/包干巡检_责任单位在后台审核整改信息.mp4',
      'https://ugm.oss-cn-beijing.aliyuncs.com/mtg/包干巡检_责任单位在小程序上报案件.mp4',
      'https://ugm.oss-cn-beijing.aliyuncs.com/mtg/包干巡检_责任单位在小程序中上报整改信息.mp4',
      'https://ugm.oss-cn-beijing.aliyuncs.com/mtg/包干巡检_责任单位在小程序中审核整改.mp4',
      'https://ugm.oss-cn-beijing.aliyuncs.com/mtg/第三方检查_责任单位在后台上报整改.mp4',
      'https://ugm.oss-cn-beijing.aliyuncs.com/mtg/第三方检查_责任单位在小程序中上报整改.mp4',
      'https://ugm.oss-cn-beijing.aliyuncs.com/mtg/管理后台-基本功能-合并.mp4',
      'https://ugm.oss-cn-beijing.aliyuncs.com/mtg/责任单位小程序功能介绍.mp4',
    ]
    const booksURL = [
      'https://ugm.oss-cn-beijing.aliyuncs.com/mtg/门头沟区项目操作手册完整版.docx',
      'https://ugm.oss-cn-beijing.aliyuncs.com/mtg/门头沟区项目操作手册区后台版.docx',
      'https://ugm.oss-cn-beijing.aliyuncs.com/mtg/门头沟区项目操作手册责任单位版.docx',
      'https://ugm.oss-cn-beijing.aliyuncs.com/mtg/门头沟区项目操作手册领导小程序版.docx',
      'https://ugm.oss-cn-beijing.aliyuncs.com/mtg/门头沟区项目操作手册志愿者版.docx',
    ]
    const books = [
      '《门头沟互联网+创城考核平台操作手册》',
      '《门头沟互联网+创城考核平台操作手册区后台版》',
      '《门头沟互联网+创城考核平台操作手册责任单位版》',
      '《门头沟互联网+创城考核平台操作手册领导小程序版》',
      '《门头沟互联网+创城考核平台操作手册志愿者版》',
    ]
    return (
      <div style={{ backgroundColor: '#fff' }}>
        <div style={{ padding: '50px', lineHeight: '30px' }}>
          <List
            size="small"
            header={<h4>小册子下载</h4>}
            bordered
            dataSource={smallBook}
            style={{ width: '800px', marginBottom: '20px' }}
            renderItem={(item, key) => {
              return <List.Item actions={[<a href={samllBookURL[key]}>下载</a>]}>{item}</List.Item>
            }}
          />
          <List
            size="small"
            header={<h4>操作视频下载</h4>}
            bordered
            dataSource={video}
            style={{ width: '800px', marginBottom: '20px' }}
            renderItem={(item, key) => {
              return (
                <List.Item
                  actions={[
                    <a target="_blank" href={videoURL[key]}>
                      下载
                    </a>,
                  ]}
                >
                  {item}
                </List.Item>
              )
            }}
          />
          <List
            size="small"
            header={<h4>操作手册下载</h4>}
            bordered
            dataSource={books}
            style={{ width: '800px', marginBottom: '20px' }}
            renderItem={(item, key) => {
              return <List.Item actions={[<a href={booksURL[key]}>下载</a>]}>{item}</List.Item>
            }}
          />
        </div>
      </div>
    )
  }
}
