import {
  Form,
  LocaleProvider,
  Select,
  Checkbox,
  Row,
  Col,
  Button,
  Icon,
  Input,
  Divider,
  Cascader,
} from 'antd'
import React from 'react'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import List from '../components/normal_list'
import DeleteBox from '../components/standing_book_delete'
import Geo from './geo'
import * as _ from 'lodash'
import * as queryString from 'query-string'
import SlideBox from '../components/slidebox'
import ModalComponent from '../components/forExportModal'

const FormItem = Form.Item
const Option = Select.Option
const CheckboxGroup = Checkbox.Group
class Component extends React.Component {
  state = {
    // mode: 'map',
    pagination: {
      defaultPageSize: 20,
    },
    loading: false,
    queryString: '',
    searchBoxShow: false,
    gridsShowDisabled: true,
    downLoadBoxVisible: false,
  }
  constructor(props) {
    super(props)
    //获取title
    console.log(props)
    this.state.mode = props.data.mode || 'map'
    this.state.dataSource = props.data.standingBooks
    this.state.templateStructure = props.data.template
    //table
    this.state.pagination = props.data.pagis
    //filter
    this.state.filterList = this.getfilters(props.data.template)
    //deleteBox
    this.state.deleteBox = {
      show: false,
      handleCancel: this.handleHideDeleteBox,
      handleSuccess: this.handleDeleteSuccess,
    }
    console.log('this.state', this.state)
    // console.log(this.props.form)
  }
  /**
   * 获取搜索框的类型列表
   * @param {*} tmpl
   */
  getfilters(tmpl) {
    const list = _.chain(tmpl)
      .filter(p => p.filter)
      .each(p => {
        // console.log(p)
        'Tree' === p.dataType && 'select' === p.filter.type && (p.filter.type = 'tree')
      })
      .map(p => {
        const item = {
          id: p.id.toString(),
          name: p.name,
          type: p.filter.type,
        }
        switch (p.filter.type) {
          case 'match':
            item.sort = 0
            break
          case 'select':
            item.sort = 1
            item.options = [...Object.entries(p.enums)]
            break
          case 'range':
            item.sort = 2
            item.options = p.filter.filters.map((q, i) => {
              return { key: item.id + i.toString(), label: q.name, value: q.value }
            })
            break
          case 'tree':
            item.sort = 2
            item.options = p.data
            break
        }
        return item
      })
      .sort((a, b) => a.sort - b.sort)
      .value()
    // console.log(list)
    return list
  }
  /**
   * 翻页事件
   */
  handleTableChange = (pagination, filters, sorter) => {
    this.handleSearch(null, pagination)
  }
  /**
   * 数据查询
   */
  dataSearch = async (pagination, queryStr = '') => {
    // console.log(pagination, filters, sorter)
    this.setState({
      pagination: { ...this.state.pagination, current: pagination.current },
      loading: true,
    })
    let data = await axios.get('/standing_books.json' + queryStr, {
      params: { page: pagination.current },
    })
    // data = data.data.data
    // console.log(data)
    this.setState({
      dataSource: data.data.data,
      pagination: Object.assign(this.state.pagination, data.data.pagis),
      loading: false,
    })
  }
  /**
   * 查询按钮事件
   */
  handleSearch = (e, pagination = { current: 1 }) => {
    // console.log(this.props.mode)
    // console.log(obj)
    const query = queryString.stringify(this.getSearchParams(), {
      arrayFormat: 'bracket',
    })
    console.log(query)
    this.setState({ queryString: query || '' })
    // setTimeout(() => {
    //   this.handleShowSearchBox()
    // }, 1000)
    'list' === this.state.mode && this.dataSearch(pagination, '?' + query)
  }
  getSearchParams = () => {
    const obj = this.props.form.getFieldsValue()
    Object.keys(obj).forEach(k => {
      const v = obj[k]
      // Array.isArray(obj[k]) && (obj[k] = obj[k].map(p => p.toString()))
      // console.log(Number.isInteger(v), typeof v)
      if (typeof v === 'string') {
        let str = v.trim()
        obj[k] = str ? `"${str}"` : undefined
      }
      // console.log(encodeURI(`"${v}"`))
    })
    return Object.assign(obj, queryString.parse(window.location.search), { mode: undefined })
  }
  /**
   * 台账模板变更事件
   */
  handleBookTypeChange = value => {
    console.log(`selected ${value}`)
    this.setSearchParams('template', value)
  }
  /**
   * 显示模式变更
   */
  handleModeChange = type => {
    type !== this.state.mode && this.setSearchParams('mode', type)
  }
  /**
   * 显示删除box
   */
  handleShowDeleteBox = id => {
    const { deleteBox } = this.state
    deleteBox.show = true
    deleteBox.id = id
    this.setState({ deleteBox })
  }
  /**
   * 删除成功
   */
  handleDeleteSuccess = () => {
    const { deleteBox } = this.state
    deleteBox.show = false
    this.setState({
      deleteBox,
      dataSource: this.state.dataSource.filter(p => p.id !== deleteBox.id),
    })
  }
  /**
   * 隐藏删除box
   */
  handleHideDeleteBox = () => {
    const { deleteBox } = this.state
    deleteBox.show = false
    this.setState({ deleteBox })
  }

  /**
   * url参数变更
   */
  setSearchParams = (key, val) => {
    // new URL(windo)
    const obj = queryString.parse(window.location.search)
    console.log(obj)
    // return
    obj[key] = val
    // console.log('?' + queryString.stringify(obj))
    Turbolinks.visit('?' + queryString.stringify(obj))
  }
  /**
   * 动态生成查询框内容
   */
  getfilterTmplByType = item => {
    const style = { width: '100%' }
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    }
    const { getFieldDecorator } = this.props.form
    function caseElement(item) {
      switch (item.type) {
        case 'match':
          return <Input style={style} placeholder={'请输入' + item.name} />
        case 'select':
          return (
            <Select style={style} placeholder={'请选择' + item.name}>
              {item.options.map(([v, k]) => (
                <Option key={v}>{k}</Option>
              ))}
            </Select>
          )
        case 'tree':
          return (
            <Cascader style={style} options={item.options} placeholder={'请选择' + item.name} />
          )
        case 'range':
          return <CheckboxGroup options={item.options} />
      }
    }
    return (
      <Col xs={{ span: 24 }} sm={'range' === item.type ? { span: 24 } : { span: 6 }} key={item.id}>
        <FormItem {...formItemLayout} label={item.name} style={{ textAlign: 'left' }}>
          {getFieldDecorator('attr_' + item.id)(caseElement(item))}
        </FormItem>
      </Col>
    )
  }
  /**
   * 查询框内容
   */
  getFilterTmpl() {
    return (
      <Row gutter={48}>
        {this.state.filterList.map(p => this.getfilterTmplByType(p))}
        <Col key="btn" span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" style={{ marginRight: 15 }} onClick={this.handleSearch}>
            查询
          </Button>
          <Button
            style={{ marginRight: 15 }}
            onClick={() => {
              this.props.form.resetFields()
            }}
          >
            重置
          </Button>
          <a onClick={this.handleShowSearchBox}>
            收起筛选条件
            <Icon type="double-right" style={{ transform: 'rotate(-90deg)' }} />
          </a>
        </Col>
      </Row>
    )
  }
  /**
   * 生成台帐模板选择框
   */
  getBookTypeTmp() {
    const {
      data: { allBookTemplates, currentTemplateId },
    } = this.props
    // allBookTemplates.push({ name: '台账模板 2', id: 2, describe: null })
    // console.log('getBookTypeTmp', allBookTemplates)
    return (
      <Select
        defaultValue={currentTemplateId.toString()}
        style={{ width: 400 }}
        onChange={this.handleBookTypeChange}
      >
        {allBookTemplates.map(p => (
          <Option value={p.id.toString()} key={p.id}>
            {p.name}
          </Option>
        ))}
      </Select>
    )
  }
  /**
   * 查询box显示
   */
  handleShowSearchBox = () => {
    this.setState({
      searchBoxShow: !this.state.searchBoxShow,
    })
  }
  /**
   * 控制地块可用
   */
  handleGridsShowClick = disable => {
    this.setState({
      gridsShowDisabled: disable,
    })
  }
  /**
   * 下载窗体hide
   */
  handleDownloadBoxCancel = () => {
    this.setState({
      downLoadBoxVisible: false,
    })
  }
  /**
   * downloadBoxShow
   */
  handleShowDownloadBox = type => {
    let downloadIds
    if ('all' === type) {
      downloadIds = type
    } else {
      downloadIds = this.state.selectedRowKeys
    }
    this.setState({
      downLoadBoxVisible: true,
      downloadIds,
    })
  }
  componentDidMount() {
    // this.state.mode === 'list' &&
    //   setTimeout(() => {
    //     this.handleShowSearchBox()
    //   }, 1000)
  }

  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `wisgu1waucebf selectedRowKeys: ${selectedRowKeys}`,
        'selectedRows: ',
        selectedRows
      )
      this.setState({
        selectedRowKeys: selectedRowKeys,
      })
    },
  }

  render() {
    console.log(this.props)
    const {
      dataSource,
      loading,
      pagination,
      deleteBox,
      templateStructure,
      gridsShowDisabled,
      queryString,
      mode,
      searchBoxShow,
      downLoadBoxVisible,
      downloadIds,
      selectedRowKeys,
    } = this.state

    const rowOpertion = {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <span>
          <a href={'/standing_books/' + record.id} target="_blank">
            查看
          </a>
          <Divider type="vertical" />
          <a href={`/standing_books/${record.id}/edit`} target="_blank">
            编辑
          </a>
          <Divider type="vertical" />
          <a href="javascript:void(0)" onClick={this.handleShowDeleteBox.bind(this, record.id)}>
            删除
          </a>
          {/* </Popconfirm> */}
          {/* <Divider type="vertical" /> */}
          {/* <a href="javascript:;">审核</a> */}
        </span>
      ),
    }

    const operateBox = (
      <div>
        {/* <div style={{ float: 'left' }}>
          <Button type="primary">
            <Icon type="plus" theme="outlined" />
            新建
          </Button>
        </div> */}
        <div style={{ float: 'right' }} className="btn-space">
          <Button
            type="primary"
            onClick={() => this.handleShowDownloadBox('all')}
            disabled={!pagination.total}
          >
            <Icon type="download" theme="outlined" />
            生成全部文件
          </Button>
          <Button
            disabled={!(selectedRowKeys && selectedRowKeys.length)}
            onClick={() => this.handleShowDownloadBox('selected')}
          >
            <Icon type="download" theme="outlined" />
            生成所选文件
          </Button>
          {/* <Button disabled={!this.state.bulkable} onClick={this.onClickDeleteBulk}>
            <Icon type="delete" />
            删除所选
          </Button> */}
          <span style={{ lineHeight: '56px' }}>
            共查询到<span style={{ color: '#008FFF' }}>{pagination.total}</span> 条
          </span>
        </div>
      </div>
    )

    return (
      <LocaleProvider locale={zh_CN}>
        <div>
          <div className="grids_search">
            {/* <span style={{ fontWeight: 'bold', marginRight: '10px', fontSize: '16px' }}>
              请先选择台账模板:
            </span> */}
            {/* {this.getBookTypeTmp()} */}
            {searchBoxShow ? null : (
              <a
                style={{ right: 0, marginRight: '3rem', position: 'absolute' }}
                onClick={this.handleShowSearchBox}
              >
                展开筛选条件
                <Icon type="double-right" style={{ transform: 'rotate(90deg)' }} />
              </a>
            )}
          </div>
          <div className={`grids_container ${mode === 'map' ? 'grids_height' : ''}`}>
            <div
              className="grids_modeSelect"
              style={
                mode === 'list' ? { position: 'initial' } : searchBoxShow ? null : { zIndex: 1 }
              }
            >
              <SlideBox show={searchBoxShow}>{this.getFilterTmpl()}</SlideBox>
              <div>
                <Row>
                  <Col span={6}>
                    <div
                      className={`grids_modeBtn ${mode === 'map' ? 'grids_modeBtn_active' : ''}`}
                      onClick={this.handleModeChange.bind(this, 'map')}
                      style={{ marginRight: 15 }}
                    >
                      地图模式
                    </div>
                    <div
                      className={`grids_modeBtn ${mode === 'list' ? 'grids_modeBtn_active' : ''}`}
                      onClick={this.handleModeChange.bind(this, 'list')}
                    >
                      列表模式
                    </div>
                  </Col>
                  {/* <Col span={4}>
                    <div className="grids_count">
                      <p>点位总数</p>
                      <div className="grids_count_line" style={{ backgroundColor: '#36CFC9' }} />
                      <div className="grids_count_num" style={{ color: '#36CFC9' }}>
                        <span style={{ fontSize: '24px' }}>2585</span>个
                      </div>
                    </div>
                  </Col>
                  <Col span={4}>
                    <div className="grids_count">
                      <p>已验收数</p>
                      <div className="grids_count_line" style={{ backgroundColor: '#3189FA' }} />
                      <div className="grids_count_num" style={{ color: '#3189FA' }}>
                        <span style={{ fontSize: '24px' }}>0</span>个
                      </div>
                    </div>
                  </Col>
                  <Col span={4}>
                    <div className="grids_count">
                      <p>未验收数</p>
                      <div className="grids_count_line" style={{ backgroundColor: '#FF8700' }} />
                      <div className="grids_count_num" style={{ color: '#FF8700' }}>
                        <span style={{ fontSize: '24px' }}>2585</span>个
                      </div>
                    </div>
                  </Col>
                  <Col span={3}>
                    <div className="grids_count">
                      <p>完成比率</p>
                      <div className="grids_count_rate">0.0%</div>
                      <img className="grids_count_img" src="/assets/rate" />
                    </div>
                  </Col>
                  <Col span={3}>
                    <div className="grids_count">
                      <p>建设资金</p>
                      <div className="grids_count_rate">0.0元</div>
                    </div>
                  </Col> */}
                </Row>
              </div>
              {mode === 'list' ? null : (
                <Button
                  style={{ marginTop: '1rem' }}
                  disabled={gridsShowDisabled}
                  type="primary"
                  onClick={this.handleGridsShowClick.bind(true)}
                >
                  恢复隐藏地块
                </Button>
              )}
            </div>
            {mode === 'map' ? (
              <div className="grids_map">
                <Geo
                  queryString={queryString}
                  icon={this.props.data.icon}
                  getAble={this.handleGridsShowClick}
                  disabled={gridsShowDisabled}
                />
              </div>
            ) : (
              <div className="grids_list">
                <ModalComponent
                  cancel={this.handleDownloadBoxCancel}
                  visible={downLoadBoxVisible}
                  ids={downloadIds}
                  type="standingBooks"
                  searchParams={this.getSearchParams()}
                />
                <List
                  rowSelection={this.rowSelection}
                  operateBox={operateBox}
                  dataTemplate={dataSource}
                  rowOpertion={rowOpertion}
                  loading={loading}
                  pagination={pagination}
                  onChange={this.handleTableChange}
                  templateStructure={templateStructure}
                  scroll={{ x: true }}
                />
              </div>
            )}
          </div>
          <DeleteBox {...deleteBox} />
        </div>
      </LocaleProvider>
    )
  }
}

export default Form.create()(Component)
