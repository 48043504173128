import {
  Form,
  Input,
  Radio,
  DatePicker,
  LocaleProvider,
  Cascader,
  Select,
  Row,
  Col,
  Button,
  AutoComplete,
} from 'antd'
import React from 'react'
import Images from '../components/images'
import moment from 'moment'
import zh_CN from 'antd/lib/locale-provider/zh_CN'

const Option = Select.Option
const { TextArea } = Input
const FormItem = Form.Item
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
}
const longItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 3 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
}

const RadioGroup = Radio.Group

export default class Component extends React.Component {
  state={

  }
  onChange = attr => {
    return e => {
      if(!e){ return this.setState({ [attr]: null })}
      let val = e.target ? e.target.value : e
      this.setState({ [attr]: val })
    }
  }
  render(){
    return(
       <LocaleProvider locale={zh_CN}>
        <Form style={{ backgroundColor: '#fff',paddingLeft: 30,paddingBottom: 30}}>
          <Row>
            <Col span={12}>
              <FormItem {...formItemLayout} label="腾退类别">
                <Select placeholder="请选择腾退类别" onChange={this.onChange('leibie')}>
                  <Option value="jack">Jack</Option>
                  <Option value="lucy">Lucy</Option>
                  <Option value="disabled" disabled>Disabled</Option>
                  <Option value="Yiminghe">yiminghe</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem {...formItemLayout} label="专指平台序号">
                <Input
                  placeholder="请输入专指平台序号"
                  onChange={this.onChange('id')}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem {...formItemLayout} label="项目名称">
                <Input
                  placeholder="请输入项目名称"
                  onChange={this.onChange('name')}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem {...formItemLayout} label="街道名称">
                <Select placeholder="请选择街道名称" onChange={this.onChange('jiedaoname')}>
                  <Option value="jack">Jack</Option>
                  <Option value="lucy">Lucy</Option>
                  <Option value="disabled" disabled>Disabled</Option>
                  <Option value="Yiminghe">yiminghe</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem {...formItemLayout} label="检查地址">
                <Input
                  placeholder="请输入详细地址"
                  onChange={this.onChange('address')}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem {...formItemLayout} label="街道编号">
                <Input
                  placeholder="请输入街道序号"
                  onChange={this.onChange('addressNum')}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem {...formItemLayout} label="占地面积（平方米）">
                <Input
                  placeholder="请输入占地面积"
                  onChange={this.onChange('area')}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem {...formItemLayout} label="点位实施牵头单位">
                <Select placeholder="请选择点位实施牵头单位" onChange={this.onChange('danwei')}>
                  <Option value="jack">Jack</Option>
                  <Option value="lucy">Lucy</Option>
                  <Option value="disabled" disabled>Disabled</Option>
                  <Option value="Yiminghe">yiminghe</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem {...formItemLayout} label="建筑面积（平方米）">
                <Input
                  placeholder="请输入建筑面积"
                  onChange={this.onChange('jianzhuarea')}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem {...formItemLayout} label="再利用方向">
                <Select placeholder="请选择再利用方向" onChange={this.onChange('liyong')}>
                  <Option value="jack">Jack</Option>
                  <Option value="lucy">Lucy</Option>
                  <Option value="disabled" disabled>Disabled</Option>
                  <Option value="Yiminghe">yiminghe</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem {...formItemLayout} label="联席会议牵头单位">
                <Select placeholder="请选择联席会议牵头单位" onChange={this.onChange('qiantou')}>
                  <Option value="jack">Jack</Option>
                  <Option value="lucy">Lucy</Option>
                  <Option value="disabled" disabled>Disabled</Option>
                  <Option value="Yiminghe">yiminghe</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem {...formItemLayout} label="六个一批类型">
                <Select placeholder="请选择六个一批类型" onChange={this.onChange('leixing')}>
                  <Option value="jack">Jack</Option>
                  <Option value="lucy">Lucy</Option>
                  <Option value="disabled" disabled>Disabled</Option>
                  <Option value="Yiminghe">yiminghe</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem {...formItemLayout} label="土地所属系统">
                <Select placeholder="请选择土地所属系统" onChange={this.onChange('suoshu')}>
                  <Option value="jack">Jack</Option>
                  <Option value="lucy">Lucy</Option>
                  <Option value="disabled" disabled>Disabled</Option>
                  <Option value="Yiminghe">yiminghe</Option>
                </Select>               
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem {...formItemLayout} label="是否验收">
                <Select placeholder="请选择是否验收" onChange={this.onChange('yanshou')}>
                  <Option value="jack">已验收</Option>
                  <Option value="lucy">未验收</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem {...formItemLayout} label="建设资金(万元)">
                <Input
                  placeholder="请输入建设资金"
                  onChange={this.onChange('zijin')}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem {...formItemLayout} label="验收时间">
                <DatePicker placeholder="请选择验收时间" onChange={this.onChange('time')} />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem {...longItemLayout} label="描 述">
                <Input
                  placeholder="请输入描述内容"
                  onChange={this.onChange('area_text')}
                />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem {...longItemLayout} label="四至地图——地理位置">
                <div className="task_table_map" style={{width: '100%'}}></div>
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem {...longItemLayout} label="点位资料">
                <div className="task_table_img_container" style={{width : '100%'}}>
                  <div className="task_table_img">
                    <div className="task_table_item">
                    </div>
                    <div className="task_table_item">
                    </div>
                  </div>
                </div>
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem {...longItemLayout} label="核验照片详情">
                <div className="task_table_img_container" style={{width : '100%'}}>
                  <div className="task_table_img_title">
                    <div className="task_table_item">核验前</div>
                    <div className="task_table_item">核验后</div>
                  </div>
                  <div className="task_table_img">
                    <div className="task_table_item">
                    </div>
                    <div className="task_table_item">
                    </div>
                  </div>
                </div>
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem className="form_btn">
                <Button type="primary" style={{ marginRight: 20 }}>
                  提交
                </Button>
                <Button>取消</Button>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </LocaleProvider>
    )
  }
}






