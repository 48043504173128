import React from "react"
import { Row,Col } from 'antd'

export default class Component extends React.Component {
	render(){
		console.log(this.props)
		return(
			<div className="index_count">
				<Row gutter={16}>
					<Col className="gutter-row" span={6}>
						<div className="index_count_item gutter-box">
							<div className="index_count_icon color-1">
								<img src="/assets/xinzeng.png"/>
							</div>
							<div className="index_count_text">
								<span style={{fontSize:46}}>{this.props.data.ucases_count}</span>个<br/>
								<span>本周新增{t.case}数</span>
							</div>
						</div>
					</Col>
					<Col className="gutter-row" span={6}>
						<div className="index_count_item gutter-box">
							<div className="index_count_icon color-2">
								<img src="/assets/yishenhe.png"/>
							</div>
							<div className="index_count_text">
								<span style={{fontSize:46}}>{this.props.data.ucases_0_count}</span>个<br/>
								<span>本周待审核{t.case}数</span>
							</div>
						</div>
					</Col>
					<Col className="gutter-row" span={6}>
						<div className="index_count_item gutter-box">
							<div className="index_count_icon color-3">
								<img src="/assets/daishenhe.png"/>
							</div>
							<div className="index_count_text">
								<span style={{fontSize:46}}>{this.props.data.ucases_gt0_count}</span>个<br/>
								<span>本周已审核{t.case}数</span>
							</div>
						</div>
					</Col>
					<Col className="gutter-row" span={6}>
						<div className="index_count_item gutter-box">
							<div className="index_count_icon color-4">
								<img src="/assets/yizhenggai.png"/>
							</div>
							<div className="index_count_text">
								<span style={{fontSize:46}}>{this.props.data.ucases_1_count}</span>个<br/>
								<span>本周未通过{t.case}数</span>
							</div>
						</div>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col className="gutter-row" span={6}>
						<div className="index_count_item gutter-box">
							<div className="index_count_icon color-5">
								<img src="/assets/weizhenggai.png"/>
							</div>
							<div className="index_count_text">
								<span style={{fontSize:46}}>{this.props.data.ucases_2_count}</span>个<br/>
								<span>本周待整改{t.case}数</span>
							</div>
						</div>
					</Col>
					<Col className="gutter-row" span={6}>
						<div className="index_count_item gutter-box">
							<div className="index_count_icon color-6">
								<img src="/assets/yifuhe.png"/>
							</div>
							<div className="index_count_text">
								<span style={{fontSize:46}}>{this.props.data.ucases_3_count}</span>个<br/>
								<span>本周待复核{t.case}数</span>
							</div>
						</div>
					</Col>
					<Col className="gutter-row" span={6}>
						<div className="index_count_item gutter-box">
							<div className="index_count_icon color-7">
								<img src="/assets/weifuhe.png"/>
							</div>
							<div className="index_count_text">
								<span style={{fontSize:46}}>{this.props.data.ucases_4_count}</span>个<br/>
								<span>本周已结案{t.case}数</span>
							</div>
						</div>
					</Col>
	
				</Row>
			</div>
		)
	}
}

