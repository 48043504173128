// 获取所选时间内的每一天
export const getDays = (startDate, endDate, dayLength) => {
  var str = startDate
  let arr = []
  arr.push(str)
  for (var i = 0; ; i++) {
    var getDate = getTargetDate(startDate, dayLength)
    startDate = getDate
    if (getDate <= endDate) {
      str += ',' + getDate
      arr.push(getDate)
    } else {
      break
    }
  }
  return arr
}
const getTargetDate = (date, dayLength) => {
  dayLength = dayLength + 1
  var tempDate = new Date(date)
  tempDate.setDate(tempDate.getDate() + dayLength)
  var year = tempDate.getFullYear()
  var month =
    tempDate.getMonth() + 1 < 10 ? '0' + (tempDate.getMonth() + 1) : tempDate.getMonth() + 1
  var day = tempDate.getDate() < 10 ? '0' + tempDate.getDate() : tempDate.getDate()
  return year + '-' + month + '-' + day
}

// 获取当前月份
// export const nowMonth = () => {
//   const date = new Date()
//   const year = date.getFullYear()
//   let month = date.getMonth() + 1
//   if (month >= 1 && month <= 9) {
//     month = '0' + month
//   }
//   const currentdate = `${year}-${month}`
//   return currentdate
// }

var now = new Date() // 当前日期
var nowDayOfWeek = now.getDay() // 今天本周的第几天
var nowDay = now.getDate() // 当前日
var currentMonth = now.getMonth() // 当前月
var nowYear = now.getYear() // 当前年
nowYear += nowYear < 2000 ? 1900 : 0

// 获取月份的第一天
export const firstDate = d => {
  var monthStartDate = new Date(nowYear, currentMonth, 1)
  return formatDate(monthStartDate)
}

// 获取月份最后一天
export const lastDate = d => {
  var monthEndDate = new Date(nowYear, currentMonth, getMonthDays())
  return formatDate(monthEndDate)
}

// 获取本周第一天
export const firstWeek = () => {
  const day = nowDayOfWeek || 7
  return formatDate(new Date(now.getFullYear(), currentMonth, nowDay + 1 - day))
}

// 获取本周最后一天
export const lastWeek = () => {
  var day = nowDayOfWeek || 7
  return formatDate(new Date(now.getFullYear(), currentMonth, nowDay + 7 - day))
}

const formatDate = date => {
  var myyear = date.getFullYear()
  var mymonth = date.getMonth() + 1
  var myweekday = date.getDate()

  if (mymonth < 10) {
    mymonth = '0' + mymonth
  }
  if (myweekday < 10) {
    myweekday = '0' + myweekday
  }
  return myyear + '-' + mymonth + '-' + myweekday
}
const getMonthDays = () => {
  var monthStartDate = new Date(nowYear, currentMonth, 1)
  var monthEndDate = new Date(nowYear, currentMonth + 1, 1)
  var days = (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24)
  return days
}
