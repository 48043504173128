import React from 'react'
import { Modal, Radio, Spin, Icon } from 'antd'
import * as qs from 'query-string'
import { relative } from 'path'

const RadioButton = Radio.Button
const RadioGroup = Radio.Group
export default class Component extends React.Component {
  state = {
    isMark: 'false',
    showType: 0,
  }
  componentWillReceiveProps(nextProps) {
    console.log(nextProps)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      ['showType', 'isMark'].some(p => {
        return this.state[p] !== nextState[p]
      })
    ) {
      return true
    }
    if (
      ['visible'].some(p => {
        return this.props[p] !== nextProps[p]
      })
    ) {
      return true
    }
    return false
  }

  export = async e => {
    const key = qs.parse(location.search).key
    const { type, ids, cancel, searchParams } = this.props
    const { isMark } = this.state
    this.setState({
      showType: 1,
    })
    cancel()
    let res
    if ('ucases' === type) {
      const url =
        'all' === ids
          ? `/ucases/all/download_task.json${location.search}`
          : `/ucases/${ids.join('-')}/download_task.json?key=${key}`
      res = await axios.get(url, {
        params: {
          image: isMark,
        },
      })
      res = res.data
    } else if ('standingBooks' == type) {
      searchParams['ids'] = ids
      searchParams['image'] = isMark
      res = await axios.post('/standing_books/download_task.json', searchParams)
      res = res.data
    }
    console.log(res)
    let fun
    if (res.code === 200) {
      fun = () => {
        this.setState({
          showType: 2,
        })
      }
    } else {
      fun = () => {
        this.setState({
          showType: -1,
          errmsg: res.msg,
        })
      }
    }
    setTimeout(fun, 1000)
  }

  handleCloseBox = () => {
    this.setState({ showType: 0 })
  }

  onChange = e => {
    this.setState({ isMark: e.target.value })
  }

  render() {
    const { showType, isMark, spinning, errmsg } = this.state
    const { visible, cancel, ids } = this.props
    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    }
    let content
    switch (showType) {
      case 2:
        content = (
          <div className="success_info" style={{ position: 'relative' }}>
            <Icon type="close" onClick={this.handleCloseBox} />
            <div style={{ fontSize: '18px', marginBottom: 10 }}>
              <Icon style={{ color: '#52c41a', marginRight: 10 }} type="check-circle" />
              文件已添加到下载队列
            </div>
            <div>
              您可以在
              <a
                style={{ color: '#FFA541', margin: '0 .4rem' }}
                href="/download_tasks"
                target="_blank"
              >
                "文件生成中心"
              </a>
              查看文件生成进度并下载文件
            </div>
          </div>
        )
        break
      case 1:
        content = (
          <div className="spin">
            <Spin spinning={spinning} tip="文件生成中..." />
          </div>
        )
        break
      case -1:
        content = (
          <div className="success_info ant-alert-error" style={{ position: 'relative' }}>
            <Icon type="close" onClick={this.handleCloseBox} />
            {errmsg}
          </div>
        )
        break
      default:
        break
    }

    return (
      <div>
        <Modal
          title="批量生成"
          visible={visible}
          onOk={this.export}
          onCancel={cancel}
          bodyStyle={{ marginLeft: '32%' }}
          okText="确认"
          cancelText="取消"
        >
          <p>
            您已选择
            <span style={{ color: '#008FFF' }}>
              {'all' === ids ? ' 当前查询到的所有数据' : ` ${(ids || '').length}项列表数据 `}
            </span>
          </p>
          <p>请选择图片类型：</p>
          <RadioGroup onChange={this.onChange} value={isMark}>
            <Radio style={radioStyle} value={'mark'}>
              文件带水印
            </Radio>
            <Radio style={radioStyle} value={'no_mark'}>
              文件无水印
            </Radio>
            <Radio style={radioStyle} value={'false'}>
              文件无照片
            </Radio>
          </RadioGroup>
        </Modal>
        {content}
      </div>
    )
  }
}
