import React from 'react'

const setTimeoutAsync = (func, time) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(func())
    }, time)
  })
}

export default class Component extends React.Component {
  state = {}
  constructor(props) {
    super(props)
    console.log(props)
    this.box1 = React.createRef()
    this.box2 = React.createRef()
    const heightVal = props.show ? '' : '0'
    this.state.height = { height: heightVal, marginBottom: heightVal }
  }
  componentWillReceiveProps(nextProps) {
    const props = this.props
    if (props.show !== nextProps.show) {
      this.state.height = { height: this.box1.current.offsetHeight, marginBottom: '' }
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const props = this.props
    // console.log(prevProps, props)
    if (props.show !== prevProps.show) {
      this.handleShow(props.show)
    }
  }
  // shouldComponentUpdate(nextProps, nextState) {
  //   if (nextProps.show === this.props.show) {
  //     return false
  //   }
  //   return true
  // }
  /**
   * 查询box显示
   */
  handleShow = async show => {
    const node = this.box1.current
    const child = this.box2.current
    let h
    if (show) {
      child.style.position = 'abslute'
      child.style.opacity = '0'
      h = await setTimeoutAsync(() => {
        return child.offsetHeight
      }, 0)
      await setTimeoutAsync(() => {
        child.style.position = ''
        child.style.opacity = ''
      })
    } else {
      h = node.offsetHeight
    }

    await setTimeoutAsync(() => {
      this.animate(node, h, show)
    })
  }
  /**
   * slide动画
   * @param {*} node
   * @param {*} height
   * @param {*} show
   */
  async animate(node, height, show) {
    // console.log(height)
    const cssAnimation = {
      start() {
        if (show) {
          node.style.height = '0px'
          node.style.marginBottom = '0px'
          node.style.opacity = '0'
        } else {
          node.style.height = `${height}px`
          node.style.marginBottom = ''
          node.style.opacity = '1'
        }
      },
      active() {
        node.style.height = `${show ? height : 0}px`
        node.style.marginBottom = show ? '' : 0
        node.style.opacity = show ? '1' : '0'
      },
      end() {},
    }
    cssAnimation.start()
    await setTimeoutAsync(() => {
      cssAnimation.active()
    }, 0)
    await setTimeoutAsync(() => {
      cssAnimation.end()
    }, 500)
  }
  componentDidMount() {
    // this.state.height = { height: this.box1.current.offsetHeight, marginBottom: '' }
  }
  render() {
    const { children, style } = this.props
    const { height } = this.state
    const endStyle = { ...style, ...height, position: 'relative' }
    return (
      <div style={endStyle} ref={this.box1} className="grids_selsect">
        <div style={{ padding: '1.2rem', background: '#fff' }} ref={this.box2}>
          {children}
        </div>
      </div>
    )
  }
}
