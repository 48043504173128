import React from 'react'
import {
  LocaleProvider,
  DatePicker,
  Tabs,
  Button,
  Modal,
  Select,
  InputNumber,
  message,
  Tooltip,
  Icon,
} from 'antd'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import Street from './components/street'
import Wbj from './components/wbj'
import Rural from './components/rural'
import Community from './components/community'
import OrganizationList from './components/OrganizationList'
import moment from 'moment'

export default class Component extends React.Component {
  state = {
    dataList: [],
    month: null,
    key: 'jz',
    config: null,
    configVisible: false,
    editconfig: {},
    scoreVisible: false,
    editVisible: false,
    publishVisible: false,
    ableClose: false,
  }
  getCurrentMonth = () => {
    let myDate = new Date()
    let tYear = myDate.getFullYear()
    let tMonth = myDate.getMonth()
    let m = tMonth + 1
    if (m.toString().length == 1) {
      m = '0' + m
    }
    return tYear + '-' + m
  }
  getList = (type, month) => {
    axios.get(`/assessment?result_type=${type}&month=${month}`).then(res => {
      console.log('res====', res)
      this.setState({
        dataList: res.data.data,
      })
    })
  }
  changeTab = key => {
    this.setState({
      key,
    })
    this.getList(key, this.state.month)
  }
  getConfig = month => {
    axios.get(`/assessment/setting?month=${month}`).then(res => {
      console.log('config===', res)
      this.setState({
        config: res.data.data,
        editconfig: res.data.data,
      })
    })
  }
  componentDidMount() {
    this.setState({
      month: this.getCurrentMonth(),
    })
    this.getList('jz', this.getCurrentMonth())
    this.getConfig(this.getCurrentMonth())
  }
  submitConfig = () => {
    const {
      id,
      isSolidStateStandard,
      month,
      ruralCoefficient,
      communityCoefficient,
    } = this.state.editconfig
    const query = {
      id,
      isSolidStateStandard,
      month,
      ruralCoefficient,
      communityCoefficient,
    }
    if (!query.ruralCoefficient || !query.communityCoefficient) {
      message.error('系数必须大于0')
      return
    }
    axios.put('/assessment/setting', query).then(res => {
      if (res.status === 200) {
        message.success('设置成功')
      } else {
        message.error('设置失败')
      }
      this.setState({
        configVisible: false,
      })
      this.getConfig(this.state.month)
    })
  }
  editScore = (id, score, name, total) => {
    this.setState({
      editVisible: true,
      unitId: id,
      score,
      unitName: name,
      totalScore: total,
      reviseScore: score,
    })
  }
  submitEdit = () => {
    if (this.state.reviseScore === null) {
      message.error('请输入修正分')
      return
    }
    const { unitId, month, reviseScore, key } = this.state
    const query = {
      unitId,
      month,
      reviseScore,
      createdById: this.props.current_user.id,
      createdByName: this.props.current_user.name,
    }
    axios.put('/assessment/revise_score', query).then(res => {
      if (res.status === 200) {
        message.success('编辑成功')
      } else {
        message.error('编辑失败')
      }
      this.setState({
        editVisible: false,
      })
      this.getList(key, month)
    })
  }
  publish = () => {
    this.setState({
      publishVisible: true,
    })
    axios.post('/assessment/publish', { month: this.state.month }).then(res => {
      console.log('res---', res)
      if (res.status === 200) {
        this.setState({
          ableClose: true,
        })
      }
    })
  }
  render() {
    console.log('current', this.props)
    const { role } = this.props.current_user
    const { config, key, month, dataList, configVisible, editconfig, editVisible } = this.state
    const { MonthPicker } = DatePicker
    const { TabPane } = Tabs
    const { Option } = Select
    return (
      <LocaleProvider locale={zh_CN}>
        <div>
          <div>
            {role !== '3' && role !== '4' && (
              <div>
                <div>
                  <span style={{ marginRight: '20px' }}>统计月份</span>
                  <MonthPicker
                    onChange={(date, datestring) => {
                      this.setState({ month: datestring })
                      this.getList(key, datestring)
                      this.getConfig(datestring)
                    }}
                    value={month ? moment(month) : null}
                    allowClear={false}
                  />
                </div>
                {role !== '5' && (
                  <div style={{ marginTop: '20px' }}>
                    <span style={{ marginRight: '30px' }}>
                      是否统计固态指标: {config && config.isSolidStateStandard ? '是' : '否'}
                    </span>
                    <span style={{ marginRight: '30px' }}>
                      农村分数统计系数:{' '}
                      {config && config.ruralCoefficient ? config.ruralCoefficient : '未设置'}
                    </span>
                    <span style={{ marginRight: '30px' }}>
                      社区分数统计系数:{' '}
                      {config && config.communityCoefficient
                        ? config.communityCoefficient
                        : '未设置'}
                    </span>
                    <span style={{ marginTop: '-7px' }}>
                      <Button
                        type="primary"
                        style={{ marginRight: '10px' }}
                        onClick={() => {
                          this.setState({
                            configVisible: true,
                          })
                        }}
                      >
                        编辑设置
                      </Button>
                      <Button
                        type="primary"
                        disabled={
                          !config || !config.ruralCoefficient || !config.communityCoefficient
                        }
                        onClick={this.publish}
                      >
                        发布
                      </Button>
                    </span>
                  </div>
                )}
                <div style={{ marginTop: '30px' }}>
                  <Tabs defaultActiveKey="jz" onChange={this.changeTab}>
                    <TabPane tab="镇街分数统计" key="jz">
                      <Street
                        dataList={dataList}
                        month={month}
                        editScore={this.editScore}
                        role={role}
                      />
                    </TabPane>
                    <TabPane tab="委办局分数统计" key="wbj">
                      <Wbj
                        dataList={dataList}
                        month={month}
                        role={role}
                        editScore={this.editScore}
                      />
                    </TabPane>
                    <TabPane tab="农村分数统计" key="rural">
                      <Rural
                        dataList={dataList}
                        month={month}
                        role={role}
                        editScore={this.editScore}
                      />
                    </TabPane>
                    <TabPane tab="社区分数统计" key="community">
                      <Community
                        dataList={dataList}
                        month={month}
                        role={role}
                        editScore={this.editScore}
                      />
                    </TabPane>
                  </Tabs>
                </div>
              </div>
            )}
            {(role === '3' || role === '4') && (
              <div>
                <OrganizationList user={this.props.current_user} />
              </div>
            )}
          </div>
          <Modal
            title="编辑设置"
            visible={configVisible}
            onOk={this.submitConfig}
            onCancel={() => {
              this.setState({
                configVisible: false,
              })
            }}
          >
            <div>
              <div>
                <span style={{ marginRight: '20px' }}>统计固态指标:</span>
                <Select
                  value={editconfig && editconfig.isSolidStateStandard}
                  onChange={value => {
                    const _editconfig = _.cloneDeep(editconfig)
                    this.setState({
                      editconfig: Object.assign(_editconfig, { isSolidStateStandard: value }),
                    })
                  }}
                >
                  <Option value={true}>是</Option>
                  <Option value={false}>否</Option>
                </Select>
              </div>
              <div style={{ margin: '15px 0' }}>
                <span style={{ marginRight: '5px' }}>
                  农村分数统计系数<span style={{ color: 'red' }}>*</span>:
                </span>
                <Tooltip title="系数需满足 x>0 并且是两位小数">
                  <span style={{ marginRight: '20px' }}>
                    <Icon type="question-circle" />
                  </span>
                </Tooltip>
                <InputNumber
                  min={0}
                  step={0.01}
                  value={editconfig && editconfig.ruralCoefficient}
                  onChange={value => {
                    const _editconfig = _.cloneDeep(editconfig)
                    this.setState({
                      editconfig: Object.assign(_editconfig, { ruralCoefficient: value }),
                    })
                  }}
                />
              </div>
              <div>
                <span style={{ marginRight: '5px' }}>
                  社区分数统计系数<span style={{ color: 'red' }}>*</span>:
                </span>
                <Tooltip title="系数需满足 x>0 并且是两位小数">
                  <span style={{ marginRight: '20px' }}>
                    <Icon type="question-circle" />
                  </span>
                </Tooltip>
                <InputNumber
                  min={0}
                  step={0.01}
                  value={editconfig && editconfig.communityCoefficient}
                  onChange={value => {
                    const _editconfig = _.cloneDeep(editconfig)
                    this.setState({
                      editconfig: Object.assign(_editconfig, { communityCoefficient: value }),
                    })
                  }}
                />
              </div>
            </div>
          </Modal>
          <Modal
            title="编辑"
            visible={editVisible}
            onOk={this.submitEdit}
            onCancel={() => {
              this.setState({
                editVisible: false,
              })
            }}
          >
            <div>
              <div>
                <span style={{ marginRight: '20px' }}>单位名称: </span>
                {this.state.unitName}
              </div>
              <div style={{ margin: '20px 0' }}>
                <span style={{ marginRight: '20px' }}>总分: </span>{' '}
                {this.state.totalScore && this.state.totalScore.toFixed(2)}
              </div>
              <div>
                <span style={{ marginRight: '20px' }}>
                  修正分<span style={{ color: 'red' }}>*</span>:
                </span>
                <InputNumber
                  value={this.state.reviseScore}
                  onChange={value => {
                    this.setState({
                      reviseScore: value,
                    })
                  }}
                />
              </div>
            </div>
          </Modal>
          <Modal
            title="发布"
            visible={this.state.publishVisible}
            closable={false}
            maskClosable={false}
            footer={
              <Button
                type="primary"
                disabled={!this.state.ableClose}
                onClick={() => {
                  this.setState({
                    publishVisible: false,
                  })
                }}
              >
                关闭
              </Button>
            }
          >
            {this.state.ableClose ? (
              <div>发布成功!</div>
            ) : (
              <div>
                <p>正在发布</p>
                <p>更新数据需要较长时间，请耐心等待...</p>
              </div>
            )}
          </Modal>
        </div>
      </LocaleProvider>
    )
  }
}
