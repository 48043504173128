import React from 'react'

export default class Component extends React.Component {
  state = {}
  changeTab = target => {
    this.setState({
      target,
    })
  }
  componentWillMount() {
    this.changeTab(0)
  }
  render() {
    let arr = [0, 1, 2, 3, 4, 5]
    let blue_text = ['【城市环境第三方巡检】', '【文明城区创建实地测评】', '【园林绿地养护检查】', '【公共服务质量明察暗访】','【农村发展与环境建设检查】','【河道精细化管理(河长制)第三方巡检】']
    let text = [
      '围绕市容、设施、秩序、生态等城市环境相关领域进行日常检查 ',
      '根据文明城区创建指标进行实地测评',
      '建立园林绿地养护台账，开展园林绿地养护质量检查',
      '对各类公共服务质量质量进行明察暗访',
      '建立农村环境问题台账，开展农村环境建设质量检查' ,
      '根据河道精细化管理巡检指标进行实地巡检'
    ]
    return (
      <div className="ugm_plan_content">
        <div className="ugm_plan_title_section">
          <div className="ugm_plan_header">应用领域</div>
          {arr.map((item, index) => {
            return (
              <div
                key={index}
                className={`ugm_plan_title_item ${
                  index == this.state.target ? 'ugm_plan_title_item_active' : ''
                }`}
                onMouseEnter={() => this.changeTab(index)}
                style={{height: '67px',paddingTop: '10px'}}
              >
                <span className="blue_text">{blue_text[index]}</span>
                {text[index]}
              </div>
            )
          })}
        </div>
        <div className="ugm_plan_plan_section">
          <div className="ugm_plan_header">解决方案</div>
          {arr.map((item, index) => {
            if (index == this.state.target) {
              return (
                <div key={index} className="ugm_plan_plan_item" style={{height: '402px'}}>
                  <img src={`/assets/sis_tab${index + 1}.png`} style={{marginTop: '5%',height: '85%'}}/>
                </div>
              )
            }
          })}
        </div>
      </div>
    )
  }
}