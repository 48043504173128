import * as queryString from 'query-string'

const limit = 10000

let getRequestList = async(query) => {
	let countResult =  await axios.get("/standing_books/getSearchCount?" + query)
	let count = countResult.data.data.count
	let cursor = 0
	let result = []
	while (cursor < count) {
		result.push({offset:cursor, limit })
		cursor += limit
	}
	console.log(result)
	return result
}


let getAssembleData = async (query) => {
	let list = await getRequestList(query)

	let resultList = await Promise.all( list.map(async (x) => {
		let parsedQuery = queryString.parse(query,{
			arrayFormat: 'bracket',
		})
		let queryStr = `/standing_books/geo_quick.json?${queryString.stringify(Object.assign(parsedQuery,x))}`
		let requestResult = await  axios.get(queryStr)
		return requestResult.data
	})
	)
	let result = []

	if (resultList.length > 0) {
		result = resultList[0]
		result.features = resultList.flatMap(x => x.features)
	}
	console.log("+++")
	console.log({data:result})
	return {
		data: result
	}
}

export  {getAssembleData}




