export const taskStatus = i => {
  return ['执行中', '结束'][i]
}

export const ucaseStatus = i => {
  // console.log(i)
  return ['待审核', '审核未通过', '审核通过待整改', '整改完成待复核', '结案'][i]
}

export const taskType = i => {
  return ['日常检查' , '季度考核' , '社会监督案件' , '年度考核'][i]
}

export const filterObj = (obj) => {
  var propNames = Object.getOwnPropertyNames(obj);
  for (var i = 0; i < propNames.length; i++) {
    var propName = propNames[i];
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj
}

export const openDownloadDialog = (url, saveName) => {
  window.location.assign(url)
  // if (typeof url == 'object' && url instanceof Blob) {
  //   url = URL.createObjectURL(url) // 创建blob地址
  // }
  // var aLink = document.createElement('a')
  // aLink.href = url
  // aLink.download = saveName || '' // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
  // var event
  // if (window.MouseEvent) event = new MouseEvent('click')
  // else {
  //   event = document.createEvent('MouseEvents')
  //   event.initMouseEvent(
  //     'click',
  //     true,
  //     false,
  //     window,
  //     0,
  //     0,
  //     0,
  //     0,
  //     0,
  //     false,
  //     false,
  //     false,
  //     false,
  //     0,
  //     null
  //   )
  // }
  // aLink.dispatchEvent(event)
}
