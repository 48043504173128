import {
  Switch,
  Form,
  Input,
  Radio,
  DatePicker,
  LocaleProvider,
  Cascader,
  Select,
  Row,
  Col,
  Button,
  AutoComplete,
} from 'antd'
import React from 'react'
// import Images from '../components/images'
import moment from 'moment'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import Map from '../standing_books/map'
import ImgUpload from '../components/img_upload'
import FileUpload from '../components/file_upload'

const Option = Select.Option
const { TextArea } = Input
const FormItem = Form.Item
const RadioGroup = Radio.Group
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
}
const longItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 3 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
}

export default class Component extends React.Component {
  wrappedMapRef = React.createRef()

  constructor(props) {
    super(props)
    let attrs = {}
    for (let k in this.props.data.special_part) {
      let sp = this.props.data.special_part[k]
      attrs[`standing_${k}`] = sp
    }
    this.state = attrs
    console.log(props)
  }

  onChange = attr => {
    return e => {
      if (!e) {
        return this.setState({ [attr]: null })
      }
      console.log(e)
      let val = e.target ? e.target.value : e
      this.setState({ [attr]: val })
    }
  }

  onChangeDate = attr => {
    return e => {
      if (!e) {
        return this.setState({ [attr]: null })
      }
      let val = e.target ? e.target.value : e
      /* val =  */
      this.setState({ [attr]: val })
    }
  }

  renderTemplate() {
    let template = this.props.template.special_part_template
    let attrs = this.props.data.special_part
    return template.map(t => {
      switch (t.dataType) {
        case 'String':
          return (
            <FormItem {...formItemLayout} label={t.name} key={t.id}>
              <Input
                placeholder={'请输入' + t.name}
                onChange={this.onChange(`standing_${t.id}`)}
                value={this.state[`standing_${t.id}`]}
              />
            </FormItem>
          )
        case 'Text':
          return (
            <FormItem {...formItemLayout} label={t.name} key={t.id}>
              <Input
                placeholder={'请输入' + t.name}
                onChange={this.onChange(`standing_${t.id}`)}
                value={this.state[`standing_${t.id}`]}
              />
            </FormItem>
          )
        case 'Numeric':
          return (
            <FormItem {...formItemLayout} label={t.name} key={t.id}>
              <Input
                placeholder={'请输入' + t.name}
                onChange={this.onChange(`standing_${t.id}`)}
                value={this.state[`standing_${t.id}`]}
              />
            </FormItem>
          )
        case 'Enum':
          return (
            <FormItem {...formItemLayout} label={t.name} key={t.id}>
              <Select
                placeholder={'请选择' + t.name}
                onChange={this.onChange(`standing_${t.id}`)}
                value={this.state[`standing_${t.id}`]}
              >
                {_.map(t.enums, (v, k) => (
                  <Option value={k} key={k}>
                    {v}
                  </Option>
                ))}
              </Select>
            </FormItem>
          )
        case 'Tree':
          return (
            <FormItem {...formItemLayout} label={t.name} key={t.id}>
              <Cascader
                options={t.data}
                placeholder={'请选择' + t.name}
                onChange={this.onChange(`standing_${t.id}`)}
                value={this.state[`standing_${t.id}`]}
              />
            </FormItem>
          )
        case 'Date':
          return (
            <FormItem {...formItemLayout} label={t.name} key={t.id}>
              <DatePicker
                placeholder={'请选择' + t.name}
                onChange={this.onChange(`standing_${t.id}`)}
                value={moment(this.state[`standing_${t.id}`])}
              />
            </FormItem>
          )
        case 'Boolean':
          return (
            <FormItem {...formItemLayout} label={t.name} key={t.id}>
              <Switch
                defaultChecked
                checked={this.state[`standing_${t.id}`]}
                onChange={this.onChange(`standing_${t.id}`)}
              />
            </FormItem>
          )
        case 'Image':
          return (
            <FormItem {...formItemLayout} label={t.name} key={t.id}>
              <ImgUpload
                images={this.state[`standing_${t.id}`]}
                onChange={this.onChange(`standing_${t.id}`)}
              />
            </FormItem>
          )
        case 'File':
          return (
            <FormItem {...formItemLayout} label={t.name} key={t.id}>
              <FileUpload
                accept={t.format}
                files={this.state[`standing_${t.id}`]}
                onChange={this.onChange(`standing_${t.id}`)}
              />
            </FormItem>
          )
        default:
          return <div key={t.id}>{t.dataType}</div>
      }
    })
  }

  submit = async () => {
    let attrs = {}
    for (let attr in this.state) {
      if (_.startsWith(attr, 'standing_')) {
        let id = attr.replace('standing_', '')
        attrs[id] = this.state[attr]
      }
    }
    let finalPath = this.wrappedMapRef.current.finalpath
    let finalmarkers = this.wrappedMapRef.current.finalmarkers
    let finalLine = this.wrappedMapRef.current.finalLine

    console.log('finalLine', finalLine)
    console.log('finalmarkers', finalmarkers)
    console.log('finalPath', finalPath)
    // let to_polygon_str = coor => {
    //   let fcs = coor.map(fc => {
    //     let scs = fc.map(sc => sc.join(' ')).join(',')
    //     return `(${scs})`
    //   })
    //   return `POLYGON(${fcs.join(',')})`
    // }
    // let finalPath2 = to_polygon_str(finalPath)
    // console.log(`finalPath is ${finalPath2}`)
    // console.log(`finalPath is ${finalPath}`)

    let polygon_set = {
      polygon: finalPath,
      points: finalmarkers,
      lines: finalLine,
    }

    let res
    try {
      res = await axios.put(`/standing_books/${this.props.data.id}.json`, {
        standing_book: { special_part: attrs, polygon: finalPath, polygon_set },
      })
      if (res.data.code == 200) {
        Turbolinks.visit('.')
      } else {
        let errors = _.values(res.data.msg).join(';')
        emitter.emit('alert', { message: errors, type: 'error' })
      }
    } catch (error) {
      console.log(error, res)
    }
  }
  cancel = () => {
    Turbolinks.visit('/standing_books')
  }
  render() {
    console.log("form======",this.props)
    return (
      <LocaleProvider locale={zh_CN}>
        <Form style={{ backgroundColor: '#fff', paddingLeft: 30, paddingBottom: 30 }}>
          <Row>
            {this.renderTemplate()}
            <Col span={24}>
              <FormItem {...formItemLayout} label="四至地图——地理位置">
                <Map
                  icon={this.props.template.icon}
                  polygon={this.props.data.polygon_set ? this.props.data.polygon_set.polygon || [] : []}
                  points={this.props.polygon_set ? this.props.polygon_set.points || []: []}
                  lines={this.props.polygon_set ? this.props.polygon_set.lines || [] : []}
                  ref={this.wrappedMapRef}
                />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem className="form_btn">
                <Button type="primary" style={{ marginRight: 20 }} onClick={this.submit}>
                  提交
                </Button>
                <Button onClick={this.cancel}>取消</Button>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </LocaleProvider>
    )
  }
}
