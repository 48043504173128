import React from 'react'
import { Menu, Icon } from 'antd'
import * as qs from 'query-string'

const SubMenu = Menu.SubMenu
const MenuItemGroup = Menu.ItemGroup

export default class Component extends React.Component {
  // submenu keys of first level
  rootSubmenuKeys = ['sub1', 'sub2', 'sub4']
  constructor() {
    super()
    let path = location.pathname
    let query = qs.parse(location.search, { arrayFormat: 'bracket' })
    let keys = Object.keys(query)
    let selectedKeys
    // if (keys.length == 0 || (keys.length == 1 && keys[0] == 'advance_search')) {
    //   selectedKeys = [path, 'all']
    //   keys.push('status')
    // } else
    keys = keys.filter(x => x != 'advance_search')
    if (keys.length > 0) {
      selectedKeys = qs.stringify(query).split('&')
      // .map(x=>x.replace(/ids/g, 'ids[]'))
    } else {
      selectedKeys = [path]
    }
    // keys = keys.map(x=>x.replace(/_ids/g, ''))

    if (path.indexOf('/tasks') == 0) {
      this.state = {
        openKeys: ['sub_task', keys[0]],
        menu: 'tasks',
        selectedKeys,
      }
    } else if (path.indexOf('/ucases') == 0) {
      this.state = {
        openKeys: ['sub_ucase', 'status', keys[0]],
        menu: 'ucases',
        selectedKeys,
      }
    } else if (path.indexOf('/reports') == 0) {
      this.state = {
        openKeys: ['sub_report', keys[0]],
        menu: 'reports',
        selectedKeys,
      }
    } else if (path.indexOf('/statistics') == 0) {
      this.state = {
        openKeys: ['sub_statistics', keys[0]],
        menu: 'statistics',
        selectedKeys,
      }
    } else if (path.indexOf('/download_tasks') == 0) {
      this.state = {
        openKeys: ['sub_downloads', keys[0]],
        menu: 'downloads',
        selectedKeys,
      }
    } else if (path.indexOf('/data_statistics') == 0) {
      this.state = {
        openKeys: ['sub_data', keys[0]],
        menu: 'data',
        selectedKeys,
      }
    } else if (path.indexOf('/users') == 0) {
      this.state = {
        openKeys: ['sub_users', keys[0]],
        menu: 'users',
        selectedKeys,
      }
    } else if (path.indexOf('/projects') == 0) {
      this.state = {
        openKeys: ['sub_projects', keys[0]],
        menu: 'projects',
        selectedKeys,
      }
    } else if (path.indexOf('/volunteer') == 0) {
      this.state = {
        openKeys: ['sub_volunteer', keys[0]],
        menu: 'volunteer',
        selectedKeys,
      }
    } else if (path.indexOf('/score') !== -1) {
      this.state = {
        openKeys: ['sub_score', keys[0]],
        menu: 'score',
        selectedKeys,
      }
    } else {
      this.state = {
        openKeys: [],
        menu: '',
        selectedKeys,
      }
    }
  }

  showMenu = () => {
    if (this[this.state.menu + 'Menu']) {
      return this[this.state.menu + 'Menu']()
    }
  }

  tasksMenu = () => {
    return (
      <SubMenu
        key="sub_task"
        title={
          <span>
            <Icon type="appstore" />
            <span>任务列表</span>
          </span>
        }
      >
        <Menu.Item key="/tasks">全部任务</Menu.Item>
      </SubMenu>
    )
  }
  dataMenu = () => {
    return (
      <SubMenu
        key="sub_data"
        title={
          <span>
            <Icon type="appstore" />
            <span>数据统计</span>
          </span>
        }
      >
        <Menu.Item key="key=1">
          <a href="/data_statistics?key=1">第三方检查</a>
        </Menu.Item>
        <Menu.Item key="key=2">
          <a href="/data_statistics?key=2">包干巡检（上报）</a>
        </Menu.Item>
        <Menu.Item key="key=3">
          <a href="/data_statistics?key=3">包干巡检（整改）</a>
        </Menu.Item>
        <Menu.Item key="key=5">
          <a href="/data_statistics?key=5">整改统计</a>
        </Menu.Item>
        {this.props.current_user.role == '2' ? (
          <Menu.Item key="key=6">
            <a href="/data_statistics?key=6">检查员统计</a>
          </Menu.Item>
        ) : (
          ''
        )}
        <Menu.Item key="key=7">
          <a href="/data_statistics?key=7">区工作人员统计</a>
        </Menu.Item>
      </SubMenu>
    )
  }
  ucasesMenu = () => {
    let stage_review_status_dom = []

    for (let stage of this.props.project.stage_spec.stages) {
      stage_review_status_dom.push(
        this.props.project.stage_spec.review_status.map(rs => {
          return (
            <Menu.Item
              key={`stage=${stage.id}&review_status=${rs.id}`}
            >{`${stage.name}${rs.name}`}</Menu.Item>
          )
        })
      )
    }
    console.log('stage_review_status_dom')
    console.log(stage_review_status_dom)

    return (
      <SubMenu
        key="sub_ucase"
        title={
          <span>
            <Icon type="appstore" />
            <span>{t.case}列表</span>
          </span>
        }
      >
        <SubMenu key="status" title="第三方检查">
          {this.props.current_user.role == '2' && this.props.current_user.extra_status == '3' ? (
            ''
          ) : (
            <Menu.Item key="key=2">建成区</Menu.Item>
          )}

          <Menu.Item key="key=6">非建成区</Menu.Item>
          <Menu.Item key="key=8">建成区工作记录</Menu.Item>
          <Menu.Item key="key=9">非建成区工作记录</Menu.Item>
        </SubMenu>
        {this.props.current_user.role == '4' || this.props.current_user.role == '5' ? (
          <Menu.Item key="key=1">志愿者检查</Menu.Item>
        ) : (
          ''
        )}
        {this.props.current_user.role == '5' ? <Menu.Item key="key=7">动态问题</Menu.Item> : ''}
        {this.props.current_user.role == '5' ? <Menu.Item key="key=3">静态问题</Menu.Item> : ''}
        {this.props.current_user.role == '4' ? (
          <Menu.Item key="key=4">包干巡检(整改)</Menu.Item>
        ) : (
          ''
        )}
        {this.props.current_user.role == '4' ? (
          <Menu.Item key="key=5">包干巡检(上报)</Menu.Item>
        ) : (
          ''
        )}
        {/* <SubMenu key="status" title="按阶段查看">
          {stage_review_status_dom}
        </SubMenu> */}
        {/* <SubMenu key="area_ids" title="按区域查看">
          {this.props.areas.map(line=><Menu.Item key={`area_ids=${line.value}`}>{line.label}</Menu.Item>)}
        </SubMenu>
        <SubMenu key="standard_ids" title="按指标查看">
          {this.props.standards.map(line=><Menu.Item key={`standard_ids=${line.value}`}>{line.label}</Menu.Item>)}
        </SubMenu> */}
      </SubMenu>
    )
  }

  reportsMenu = () => {
    return (
      <SubMenu
        key="sub_report"
        title={
          <span>
            <Icon type="appstore" />
            <span>报告下载</span>
          </span>
        }
      >
        <Menu.Item key="type=days">日报</Menu.Item>
        {/*<Menu.Item key="type=weeks">周报</Menu.Item>*/}
        {/*<Menu.Item key="type=months">月报</Menu.Item>*/}
        {/*<Menu.Item key="type=quarters">季报</Menu.Item>*/}
        {/*<Menu.Item key="type=years">年报</Menu.Item>*/}
      </SubMenu>
    )
  }
  scoreMenu = () => {
    return (
      <SubMenu
        key="sub_score"
        title={
          <span>
            <Icon type="appstore" />
            <span>分数统计</span>
          </span>
        }
      >
        <Menu.Item key="/assessment/score">分数统计</Menu.Item>
      </SubMenu>
    )
  }
  volunteerMenu = () => {
    return (
      <SubMenu
        key="sub_volunteer"
        title={
          <span>
            <Icon type="appstore" />
            <span>志愿者考核</span>
          </span>
        }
      >
        <Menu.Item key="/volunteer/index">志愿者考核</Menu.Item>
      </SubMenu>
    )
  }

  statisticsMenu = () => {
    return (
      <SubMenu
        key="sub_statistics"
        title={
          <span>
            <Icon type="profile" />
            <span>统计</span>
          </span>
        }
      >
        <Menu.Item key="/statistics">案件统计</Menu.Item>
        {/* <Menu.Item key="/statistics">全部案件</Menu.Item>
        <Menu.Item key="type=jiedao">各街道案件</Menu.Item>
        <Menu.Item key="type=weibanju">委办局案件</Menu.Item> */}
      </SubMenu>
    )
  }

  downloadsMenu = () => {
    return (
      <SubMenu
        key="sub_downloads"
        title={
          <span>
            <Icon type="download" />
            <span>下载中心</span>
          </span>
        }
      >
        <Menu.Item key="/download_tasks">下载列表</Menu.Item>
      </SubMenu>
    )
  }

  usersMenu = () => {
    return (
      <SubMenu
        key="sub_users"
        title={
          <span>
            <Icon type="user" />
            <span>用户管理</span>
          </span>
        }
      >
        {this.props.current_user.role == '5' ? (
          <Menu.Item key="type=volunteers">志愿者列表</Menu.Item>
        ) : (
          <Menu.Item key="/user">用户列表</Menu.Item>
        )}
        {this.props.current_user.role == '5' ? (
          <Menu.Item key="type=districtors">区账号列表</Menu.Item>
        ) : (
          ''
        )}
      </SubMenu>
    )
  }

  projectsMenu = () => {
    return (
      <SubMenu
        key="sub_projects"
        title={
          <span>
            <Icon type="hdd" />
            <span>项目管理</span>
          </span>
        }
      >
        <Menu.Item key="/projects">项目列表</Menu.Item>
      </SubMenu>
    )
  }
  onOpenChange = openKeys => {
    console.log('open', openKeys)
    const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1)
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys, latestOpenKey })
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
        latestOpenKey,
      })
    }
  }

  onSelect = item => {
    if (item.key.indexOf('/') == 0) {
      Turbolinks.visit(`/${this.state.menu}`)
    } else {
      Turbolinks.visit(`/${this.state.menu}?${item.key}`)
    }
  }

  render() {
    return (
      <Menu
        mode="inline"
        onOpenChange={this.onOpenChange}
        style={{ width: 200, height: '100%' }}
        theme="dark"
        openKeys={this.state.openKeys}
        selectedKeys={this.state.selectedKeys}
        onSelect={this.onSelect}
      >
        {this.showMenu()}
      </Menu>
    )
  }
}
