import { LocaleProvider, Select, Form, Input, Button, message, Row, Col } from 'antd'
import React from 'react'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import axios from 'axios'

const { TextArea } = Input;
const { Option } = Select;
const FormItem = Form.Item
const longItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
}
export default class Component extends React.Component {
	state={}
	componentDidMount(){
		axios.get('/responsiblers/current.json').then(({data})=>{
			console.log(data)
			if(data.status == 200){
				this.setState({
					transferData: data.data
				})
			}
		})
	}
	onChange = attr => {
    return e => {
      if (!e) {
        return this.setState({ [attr]: null })
      }
      let val = e.target ? e.target.value : e
      this.setState({ [attr]: val })
    }
	}
	onSubmit = ()=>{
		let ucase = {
      responsibler_id: this.state.responsibler_id,
      review_status: 7,
      stage: 1,
      form:{0:this.state.reason}
		}
		var res = axios.put(
      `/ucases/${window.location.pathname.split('/')[2]}/review_update.json`,
      { ucase },
      { withCredentials: true }
    )
    res.then(({ data }) => {
      if (data.code == 200) {
        message.destroy()
        message.success('派发成功！')
        setTimeout(() => {
          Turbolinks.visit('/ucases?key=1')
        }, 300)
      } else {
        message.destroy()
        message.success('派发失败！')
      }
    })
	}
	onCancle = ()=>{
		Turbolinks.visit('/ucases?key=1')
	}
  render() {
    return (
      <LocaleProvider locale={zh_CN}>
				<div>
					<FormItem {...longItemLayout} label='选择派发单位'>
						<Select onChange={this.onChange('responsibler_id')} style={{width:'250px'}} value={this.state.responsibler_id} placeholder="选择派发单位">
							{
								this.state.transferData ? this.state.transferData.map(t=>{
									return <Option value={t.id}>{t.name}</Option>
								}) : ''
							}
						</Select>
					</FormItem>
					<FormItem {...longItemLayout} label='输入派发理由'>
						<TextArea rows={4} onChange={this.onChange('reason')} />
					</FormItem>
					<Row>
						<Col span={24}>
              <FormItem className="form_btn">
                <Button type="primary" style={{ marginRight: 20 }} onClick={this.onSubmit}>
                  提交
                </Button>
                <Button onClick={this.onCancle}>取消</Button>
              </FormItem>
            </Col>
					</Row>						
				</div>
      </LocaleProvider>
    )
  }
}
