import React from "react"

export default class Component extends React.Component {
	state={}
	changeTab = (target)=>{
		this.setState({
			target
		})
	}
	componentWillMount(){
		this.changeTab(0)
	}
	render(){
		let arr=[0,1,2]
		let blue_text=[
			'【文明城区创建实地测评】',
			'【文明城区创建督导管理】',
			'【文明城区创建台账管理】'
		]
		let text=[
			'根据文明城区创建指标进行实地测评',
			'对各党政机构的文明城区创建工作进行督导管理',
			'建立实时更新的文明城区创建问题台账体系'
		]
		return(
			<div className="ugm_plan_content">
				<div className="ugm_plan_title_section">
					<div className="ugm_plan_header">应用领域</div>
					{
						arr.map((item,index)=>{
							return(
								<div key={index} className={`ugm_plan_title_item ${index==this.state.target? 'ugm_plan_title_item_active':''}`} 
									onMouseEnter={()=>this.changeTab(index)}
									style={{height: '112px'}}
								>
									<span className="blue_text">{blue_text[index]}</span>
									{text[index]} 
								</div>
							)
						})
					}	
				</div>
				<div className="ugm_plan_plan_section">
					<div className="ugm_plan_header">解决方案</div>
					{
						arr.map((item,index)=>{
							if(index == this.state.target){
								return(
									<div key={index} className="ugm_plan_plan_item">
										<img src={`/assets/icc_tab${index+1}.png`}/>
									</div>
								)	
							}							
						})											
					}
				</div>
			</div>
		)
	}
}