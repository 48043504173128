import React from 'react'
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import { Modal, Button, Radio, Icon, LocaleProvider, Input, Table, Progress, Divider} from 'antd';
import moment from 'moment'

const Search = Input.Search;

export default class Component extends React.Component {
	constructor (props){
		super(props)
    console.log(this.props)
	}
	state={
		status:'all',
    dataSource : this.props.data
	}
  refresh = ()=>{
    location.reload()
  }
  download = (address)=>{
    console.log(address)
    Modal.info({
      title: '点击下方链接下载文件',
      content: (
        <div>
          {address.map((x,i)=>{
            return(
                <span>
                    <a key={i} href={`https://ugm.oss-cn-beijing.aliyuncs.com/${x}`}>{x}</a>
                    <span> &nbsp; </span>
                </span>
            )
          })}
        </div>
      ),
      onOk() {},
    });
  }
	render(){	
    const columns = [{
      title: '',
      width:'30%',
      key:'id',
      render: (dataSource)=>{
        return(
          <div>
            {/*<img className="down_icon" src={`/assets/${dataSource.type}.png`}/>*/}
            <div style={{float: 'left', width: '85%'}}>
              <p className="down_title">任务 - {dataSource.id}</p>
            </div>
          </div>  
        )   
      }
    }, {
      title: '',
      dataIndex: 'created_at',
      key: 'time',
      width:300,
      render:(time)=>{
        let day = time.split("T")
        let t = day[1].split('.')
        return(
          <div>
            <p>生成时间</p>
            <span>{day[0]} {t[0]}</span>
          </div>
        )
      }     
    },
        {
            title: '',
            dataIndex: 'seed_oss_key',
            key: 'seed_oss_key',
            width:300,
            render:(seed_oss_key)=>{
                return(
                    <div>
                        <p>下载器就绪状态</p>
                        <span>{!seed_oss_key ? "准备中": "已就绪"}</span>
                    </div>
                )
            }
        }

    , {
      title: '',
      key: 'process_ration',
      render:(data)=>{
        let p = data.process_ration*100
        return(
          <Progress percent={data.process_ration == null ? 0 : parseInt(p.toFixed(0))}/>
        )   
      } 
    }, {
      title:'',
      key: 'action',
      width:120,
      render: (dataSource) => {
        return(
          <div style={{textAlign: 'center'}}>
            {dataSource.status == 2 ? <a onClick={()=>this.download(dataSource.result_address)}>下载</a> : <a><Icon onClick={this.refresh} type="redo" style={{color:'#4F91FC',fontSize:'20px'}}/></a>} 
          </div>
        )     
      }   
    }];
		const status = this.state.status
		return(
			<LocaleProvider locale={zh_CN}>
				<div>
					<span style={{fontSize: '16px'}}>下载列表</span>
                    <a style={{float: "right"}} href={"https://ugm.oss-cn-beijing.aliyuncs.com/ele_download/downloadmtg/%E9%97%A8%E5%A4%B4%E6%B2%9F%E4%B8%8B%E8%BD%BD%E5%99%A8%20Setup%201.2.1.exe"}>下载器链接</a>
					{/*<Search
                      className="down_search"
                      placeholder="请输入搜索内容"
                      onSearch={value => console.log(value)}
                      style={{ width: 230 , marginLeft: 20}}
                    />*/}
	        <Table className="down_table" 
            showHeader={false} 
            columns={columns} 
            dataSource={this.state.dataSource} 
            pagination={this.props.pagis}
          />
	      </div>
	    </LocaleProvider>
		)
	}
}