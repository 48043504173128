import { Table } from 'antd'
import React from 'react'

export default class Component extends React.Component {
  render() {
    const columns = [
      {
        title: '日期',
        dataIndex: 'createdAt',
        key: 'createdAt',
      },
      {
        title: '操作人',
        dataIndex: 'createdByName',
        key: 'createdByName',
        width: 150,
      },
      {
        title: '修正分',
        dataIndex: 'reviseScore',
        key: 'reviseScore',
        width: 150,
      },
      {
        title: '总分',
        dataIndex: 'totalScore',
        key: 'totalScore',
        width: 150,
        render: score => {
          return <span>{score && score.toFixed(2)}</span>
        },
      },
    ]
    return (
      <div>
        <div>单位名称: {this.props.name}</div>
        <div style={{ margin: '20px 0' }}>
          总分: {this.props.total && this.props.total.toFixed(2)}
        </div>
        <div>
          记录详情
          <Table
            columns={columns}
            dataSource={this.props.logList}
            pagination={false}
            scroll={{ y: 300 }}
            style={{ marginTop: '10px' }}
          />
        </div>
      </div>
    )
  }
}
