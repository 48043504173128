import React from "react"
import { Button,Icon } from 'antd'

export default class Component extends React.Component {
	state={
		arr:[1,2,3,4,5,6],
		count:1
	}
	changeItem = ()=>{
		let newArr=[]
		if(this.state.count == 3){
			this.setState({
				arr:[1,2,3,4,5,6],
				count:1
			})
		}
		else{
			this.state.arr.map((x)=>{
				newArr.push(x+6)
			})
			this.setState({
				arr:newArr,
				count:this.state.count+1
			})
		}	
	}
	render(){
		let title = [
			'垃圾分类第三方检查',
			'餐厨垃圾规范收运第三方检查',
			'海淀区城市管理综合考核评价第三方服务',
			'首都文明城区实地核查',
			'海淀区文明城区创建监督检查及管理提升',
			'门头沟区文明城区创建实地测评',	
			'春节公园服务管理第三方评价',
			'北京市公园管理第三方检查',
			'顺义区园林服务中心绿地第三方专业检查',
			'北京市电动汽车充电桩运营质量监测',
			'供电营业厅服务质量明察暗访',
			'养老服务质量明察暗访',
			'北京市农村生活垃圾第三方检查',
			'“国家农产品质量安全县”创建中期评估',
			'巾帼脱贫行动第三方评估',
			'北京市延庆区水务局',
			'北京市昌平区水务局',
			'北京市海淀区水务局'
		]
		let name = [
			'北京市垃圾渣土管理处',
			'北京市城市管理委员会',
			'北京市海淀区城市服务管理指挥中心',
			'首都文明办',
			'海淀区文明办',
			'门头沟区文明办',
			'北京市公园管理中心',
			'北京市公园管理中心',
			'北京四季景艺园林绿化中心',
			'北京市城市管理委员会',
			'国网北京市电力公司',
			'北京市民政局',
			'北京市城市管理委员会',
			'北京市农业局',
			'全国妇联妇女发展部',
			'延庆区河长制管理第三方巡检',
			'昌平区节水设施运行管理',
			'海淀区集中式污水处理单位及入河排污口清查'
		]
		return(
			<div className="ugm_success">
				{
					this.state.arr.map((item,index)=>{
						return(
							<div key={index} className="ugm_success_section">
								<div className="ugm_success_item">
									<img src={`/assets/sis${item}.png`}/>
									<div className="ugm_success_item_content">
										<div style={{color: '#666666',fontSize: 14}}>{title[item-1]}</div>
										<div style={{color: '#4A4A4A',fontSize: 16}}>{name[item-1]}</div>
									</div>
								</div>
							</div>
						)	
					})
				}
				<Button type="primary" onClick={()=>this.changeItem()}><Icon type="redo" theme="outlined" />换一批查看</Button>
			</div>
		)
	}
}