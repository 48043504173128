import { Carousel } from 'antd'
import React from 'react'
import Login from './login'

export default class Component extends React.Component {
  state = {
    login: {
      help: '',
      mobile: '',
      password: '',
      remember_me: '',
      validateStatus: null,
    },
  }

  handleLoginHelp = state => {
    this.setState({
      login: Object.assign(this.state.login, state),
    })
  }

  handleLoginChange = attr => {
    // console.log(attr)
    return e => {
      // console.log(e);
      if (!e) {
        return
      }
      let val = e.target ? e.target.value : e
      this.setState({
        login: Object.assign(this.state.login, {
          [attr]: val,
          validateStatus: null,
          help: '',
          remember_me: e.target.checked,
        }),
      })
    }
  }

  render() {
    return (
      <div>
        <Carousel autoplay>
          <div className="intro_login_bg1">
            <div className="intro_content" style={{ padding: 30 }}>
              <div className="intro_text_content">
                <p style={{ fontSize: 26 }}>专业的智慧城市精细化检查管理平台</p>
                <p style={{ fontsize: 16, lineHeight: '26px' }}>
                  一站式精细化闭环检查平台，支持在线分配检查任务、手机上报{t.case}、在线审核{t.case}
                  、微信整改{t.case}，支持针对不同场景不同项目定制化开发。{' '}
                </p>
                <a data-action="anchor#toContact">
                  <div className="intro_btn">免费体验系统 ></div>
                </a>
              </div>
              <div className="intro_login_content">
                <div style={{ fontSize: 18, marginBottom: 10 }}>登录</div>
                <Login
                  login={this.state.login}
                  setHelp={this.handleLoginHelp}
                  onChange={this.handleLoginChange}
                />
              </div>
            </div>
          </div>
          <div className="intro_login_bg2">
            <div className="intro_content" style={{ padding: 30 }}>
              <div className="intro_text_content">
                <p style={{ fontSize: 26 }}>丰富的应用场景，近百个成功项目</p>
                <p style={{ fontsize: 16, lineHeight: '26px' }}>
                  一站式精细化闭环检查平台，支持在线分配检查任务、手机上报{t.case}、在线审核{t.case}
                  、微信整改{t.case}，支持针对不同场景不同项目定制化开发。{' '}
                </p>
                <a data-action="anchor#toContact">
                  <div className="intro_btn" style={{ color: '#FF8700' }}>
                    免费体验系统 >
                  </div>
                </a>
              </div>
              <div className="intro_login_content">
                <div style={{ fontSize: 18, marginBottom: 10 }}>登录</div>
                <Login
                  login={this.state.login}
                  setHelp={this.handleLoginHelp}
                  onChange={this.handleLoginChange}
                />
              </div>
            </div>
          </div>
          <div className="intro_login_bg3">
            <div className="intro_content" style={{ padding: 30 }}>
              <img src="/assets/banner3-text.png" />
            </div>
          </div>
          <div className="intro_login_bg4">
            <div className="intro_content" style={{ padding: 30 }}>
              <div className="intro_text_content">
                <p style={{ fontSize: 30, color: '#000', lineHeight: '60px' }}>
                  UGM智慧城市
                  <br />
                  大数据精细化管理平台
                </p>
                <a data-action="anchor#toContact">
                  <div className="intro_btn intro_btn_blue">免费体验系统 ></div>
                </a>
              </div>
              <div className="intro_login_content">
                <div style={{ fontSize: 18, marginBottom: 10 }}>登录</div>
                <Login
                  login={this.state.login}
                  setHelp={this.handleLoginHelp}
                  onChange={this.handleLoginChange}
                />
              </div>
            </div>
          </div>
        </Carousel>
      </div>
    )
  }
}
