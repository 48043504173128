import { Popconfirm, message, LocaleProvider, Button, Icon } from 'antd'
import List from '../components/list'
import NormalList from '../components/normal_list'
import React from 'react'
import moment from 'moment'
import * as qs from 'query-string'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import Axios from 'axios'

export default class Component extends React.Component {
  state = {
    selectedRowKeys: [],
    bulkable: false,
    data: this.props.data,
  }

  onClickNew() {
    Turbolinks.visit('/tasks/new')
  }
  onClickDeleteBulk = () => {
    // Turbolinks.visit('/tasks/new')
    console.log('delete')
    if (this.state.selectedRowKeys.length == 0) {
      return
    }
    axios.delete(`/tasks/${this.state.selectedRowKeys.join('-')}.json`).then(({ data }) => {
      console.info(data)
      if (data.code == 200) {
        this.setState({
          data: this.state.data.filter(x => {
            try {
              return data.data.deleted_tasks.indexOf(x.id.toString()) < 0
            } catch (e) {
              console.log(e)
              return this.state.data
            }
          }),
        })
      } else {
        // 错误提示
      }
    })
  }

  onClickOpen = rid => {
    return () => this.onStatusChange(1, rid ? [rid] : null)
  }

  onClickClose = rid => {
    return () => this.onStatusChange(0, rid ? [rid] : null)
  }

  onStatusChange = (status, rrows) => {
    let rows = rrows && rrows.length > 0 ? rrows : this.state.selectedRowKeys
    if (rows.length == 0) {
      return
    }
    axios.patch(`/tasks/${rows.join('-')}/start.json`, { close: status == 0 }).then(({ data }) => {
      if (data.code == 200) {
        this.setState({
          data: this.state.data.map(x => {
            try {
              if (data.data.started_tasks.indexOf(x.id.toString()) >= 0) {
                x.status = status
              }
            } catch (e) {
              console.log(e)
            }
            return x
          }),
        })
      } else {
        // 错误提示
      }
    })
  }

  onSelectChange = selectedRowKeys => {
    if (selectedRowKeys.length > 0) {
      this.setState({ bulkable: true, selectedRowKeys })
    } else {
      this.setState({ bulkable: false, selectedRowKeys })
    }
  }

  onDelete = (id) => {
    axios.delete(`/tasks/${id}.json`).then(({ data }) => {
      console.info(data)
      if (data.code == 200) {
        this.setState({
          data: this.state.data.filter(x => {
            try {
              return data.data.deleted_tasks.indexOf(x.id.toString()) < 0
            } catch (e) {
              console.log(e)
              return this.state.data
            }
          }),
        })
      } else {
        // 错误提示
      }
    })
  }


  render() {
    console.log(this.props)
    const columns = [
      {
        title: '项目经理',
        key: 'creator',
        dataIndex: 'creator.name',
      },
      {
        title: '创建时间',
        key: 'created_at',
        render: data => <span>{moment(data.created_at).format('YYYY-MM-DD')}</span>,
      },
      {
        title: '任务名称',
        key: 'name',
        dataIndex: 'name',
      },
      {
        title: '任务类型',
        key: 'uc_type',
        dataIndex: 'uc_type',
        render: type => {
          return <span>{uugmUtil.taskType(type - 1)}</span>
        },
      },
      {
        title: '组长',
        key: 'leader',
        render: data => <span>{data.leader && data.leader.name}</span>,
      },
      {
        title: '检查频率',
        key: 'frequency',
        dataIndex: 'frequency',
      },
      {
        title: '截止时间',
        key: 'endTime',
        render: data => <span>{data.deadline && moment(data.deadline).format('YYYY-MM-DD')}</span>,
      },
      {
        title: '检查区域',
        key: 'area',
        dataIndex: 'area',
      },
      {
        title: '检查标准',
        key: 'standard',
        dataIndex: 'standard',
      },
      {
        title: '描述',
        key: 'describe',
        dataIndex: 'describe',
        width: 200,
      },
      {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        render: status => <span>{uugmUtil.taskStatus(status)}</span>,
      },
      {
        title: '操作',
        width: 80,
        key: 'action',
        render: data => {
          let SwitchBtn
          if (data.status == 0) {
            SwitchBtn = (
              <a href="javascript:void(0)" onClick={this.onClickOpen(data.id)}>
                关闭
              </a>
            )
          } else {
            SwitchBtn = (
              <a href="javascript:void(0)" onClick={this.onClickClose(data.id)}>
                开启
              </a>
            )
          }
          return (
            <span>
              <a href={'/tasks/' + data.id}>查看</a>
              <br />
              <a href={'/tasks/' + data.id + '/edit'}>编辑</a>
              <br />
              <Popconfirm
                title={`任务下的${data.ucases_count}个案件将一并删除?`}
                onConfirm={()=> { this.onDelete(data.id) }}
                okText="是"
                cancelText="否"
              >
                <a href="#">删除</a>
              </Popconfirm>
              <br />
              {SwitchBtn}
            </span>
          )
        },
      },
    ]

    return (
      <LocaleProvider locale={zh_CN}>
        <div>
          {/* <NormalList columns={columns} /> */}
          <div className="opra_container">
            <div>
              <Button type="primary" onClick={this.onClickNew}>
                <Icon type="plus" />
                新建
              </Button>
              &nbsp;&nbsp;
              <Button disabled={!this.state.bulkable} onClick={this.onClickClose()}>
                批量开启
              </Button>
              &nbsp;&nbsp;
              <Button disabled={!this.state.bulkable} onClick={this.onClickDeleteBulk}>
                <Icon type="delete" />
                批量删除
              </Button>
            </div>
          </div>
          <List
            columns={columns}
            data={this.state.data}
            pagination={this.props.pagis}
            onSelectChange={this.onSelectChange}
          />
        </div>
      </LocaleProvider>
    )
  }
}
