import React from 'react'
import { DatePicker, Radio, Row, Table, Select, Button, Icon, Tooltip } from 'antd'
import Map from '../cases/map'
// 引入 ECharts 主模块
import echarts from 'echarts/lib/echarts'
// 引入饼图
import 'echarts/lib/chart/line'
// 引入标题组件
import 'echarts/lib/component/tooltip'
import { getDays, firstDate, lastDate, firstWeek, lastWeek } from './getdays'
import moment from 'moment'
import qs from 'query-string'

const { RangePicker } = DatePicker
const { Option } = Select

export default class Component extends React.Component {
  state = {
    mode: 'list',
    type: 'street',
    value: 'street',
    st: firstDate(),
    et: lastDate(),
    slug: [],
    path: [],
    level: 1,
    name: [],
    timemode: 'month',
  }
  setColumns = () => {
    const key = qs.parse(window.location.search).key
    if (key == '1' || key == '3') {
      if ((this.state.level == 3 && key == '1') || (this.state.level == 2 && key !== '1')) {
        return [
          {
            title: '排名',
            dataIndex: 'rank',
            key: 'rank',
          },
          {
            title: () => {
              if (this.state.type === 'street') {
                return '街镇'
              }
              if (this.state.type === 'office') {
                return '委办局'
              }
              if (this.state.type === 'point') {
                return '点位'
              }
              if (this.state.type === 'standard') {
                return '指标'
              }
            },
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: '区确认案件数量',
            dataIndex: 'number',
            key: 'number',
          },
          {
            title: '整改中数量',
            dataIndex: 'tweak',
            key: 'tweak',
          },
          {
            title: '整改率',
            dataIndex: 'rate',
            key: 'rate',
          },
        ]
      } else {
        return [
          {
            title: '排名',
            dataIndex: 'rank',
            key: 'rank',
          },
          {
            title: () => {
              if (this.state.type === 'street') {
                return '街镇'
              }
              if (this.state.type === 'office') {
                return '委办局'
              }
              if (this.state.type === 'point') {
                return '点位'
              }
              if (this.state.type === 'standard') {
                return '指标'
              }
            },
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: '区确认案件数量',
            dataIndex: 'number',
            key: 'number',
          },
          {
            title: '整改中数量',
            dataIndex: 'tweak',
            key: 'tweak',
          },
          {
            title: '整改率',
            dataIndex: 'rate',
            key: 'rate',
          },
          {
            title: '操作',
            key: 'action',
            render: data => {
              return (
                <span
                  style={{ color: '#6190E8', cursor: 'pointer' }}
                  onClick={() => {
                    this.setNext(data)
                  }}
                >
                  继续查看
                </span>
              )
            },
          },
        ]
      }
    } else {
      if (this.state.level == 1) {
        return [
          {
            title: '排名',
            dataIndex: 'rank',
            key: 'rank',
          },
          {
            title: () => {
              if (this.state.type === 'street') {
                return '街镇'
              }
              if (this.state.type === 'office') {
                return '委办局'
              }
              if (this.state.type === 'point') {
                return '点位'
              }
              if (this.state.type === 'standard') {
                return '指标'
              }
            },
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: '上报案件数量',
            key: 'create',
            render: data => {
              return data.create
            },
          },
          {
            title: '区确认案件数量',
            dataIndex: 'number',
            key: 'number',
          },
          {
            title: '整改中数量',
            dataIndex: 'tweak',
            key: 'tweak',
          },
          {
            title: '整改率',
            dataIndex: 'rate',
            key: 'rate',
          },
          {
            title: '操作',
            key: 'action',
            render: data => {
              return (
                <span
                  style={{ color: '#6190E8', cursor: 'pointer' }}
                  onClick={() => {
                    this.setNext(data)
                  }}
                >
                  继续查看
                </span>
              )
            },
          },
        ]
      } else {
        return [
          {
            title: '排名',
            dataIndex: 'rank',
            key: 'rank',
          },
          {
            title: () => {
              if (this.state.type === 'street') {
                return '街镇'
              }
              if (this.state.type === 'office') {
                return '委办局'
              }
              if (this.state.type === 'point') {
                return '点位'
              }
              if (this.state.type === 'standard') {
                return '指标'
              }
            },
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: '上报案件数量',
            key: 'create',
            render: data => {
              return data.create
            },
          },
          {
            title: '区确认案件数量',
            dataIndex: 'number',
            key: 'number',
          },
          {
            title: '整改中数量',
            dataIndex: 'tweak',
            key: 'tweak',
          },
          {
            title: '整改率',
            dataIndex: 'rate',
            key: 'rate',
          },
        ]
      }
    }
  }
  setNext = data => {
    const key = qs.parse(window.location.search).key
    let _slug = this.state.slug
    let _path = this.state.path
    let _name = this.state.name
    _slug.push(data.slug)
    _path.push(this.state.type)
    _name.push(data.name)
    this.setState({
      slug: _slug,
      path: _path,
      name: _name,
    })
    if (this.state.level == 1) {
      this.setState({
        level: 2,
      })
    }
    if (this.state.level == 2) {
      this.setState({
        level: 3,
      })
    }
    if (this.state.level == 1) {
      if (key === '1') {
        if (
          this.state.type === 'street' ||
          this.state.type === 'office' ||
          this.state.type == 'standard'
        ) {
          this.setState({
            type: 'point',
          })
        }
        if (this.state.type === 'point') {
          this.setState({
            type: 'street',
          })
        }
      } else {
        if (this.state.type === 'street' || this.state.type === 'office') {
          this.setState({
            type: 'standard',
          })
        }
        if (this.state.type === 'standard') {
          this.setState({
            type: 'street',
          })
        }
      }
    }
    if (this.state.level == 2) {
      if (_path[0] == 'point' && _path[1] == 'street') {
        this.setState({
          type: 'standard',
        })
      }
      if ((_path[0] == 'street' || _path[0] == 'office') && _path[1] == 'point') {
        this.setState({
          type: 'standard',
        })
      }
      if (_path[0] == 'standard' && _path[1] == 'point') {
        this.setState({
          type: 'street',
        })
      }
    }
    this.props.gotoNext(_path, _slug)
  }
  constructor(props) {
    super(props)
  }
  componentDidUpdate() {
    this.setBar()
  }
  handleBarData = () => {
    const { data } = this.props
    const arr = getDays(this.state.st, this.state.et, 0)
    let result = []
    let time = []
    let count = []
    arr.map(a => {
      result.push({ [a]: 0 })
    })
    if (data && data.trend.data.length !== 0) {
      result.map((r, i) => {
        data.trend.data.map(d => {
          if (d.date == Object.keys(r)) {
            result[i] = { [d.date]: d.count }
          }
        })
      })
    }
    result.map(r => {
      time.push(Object.keys(r)[0])
      count.push(Object.values(r)[0])
    })
    return { time, count }
  }
  setBar = () => {
    const barData = this.handleBarData()
    const chart = echarts.init(document.getElementById('bar'))
    chart.setOption({
      grid: {
        top: '12%',
        left: '1%',
        right: '6%',
        bottom: '8%',
        containLabel: true,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          lineStyle: {
            opacity: 0.5,
          },
        },
        formatter: p => {
          return `数量 : ${p[0].data}`
        },
      },
      xAxis: [
        {
          name: '日期',
          type: 'category',
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.45)',
            },
          },
          axisLabel: {
            rotate: 45,
            textStyle: {
              color: 'rgba(0, 0, 0, 0.45)',
              margin: 15,
            },
          },
          axisTick: {
            show: false,
          },
          data: barData.time,
        },
      ],
      yAxis: [
        {
          name: '数量',
          type: 'value',
          min: 0,
          splitNumber: 7,
          splitLine: {
            show: false,
            lineStyle: {
              color: '#0a3256',
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.45)',
            },
          },
          axisLabel: {
            margin: 20,
            textStyle: {
              color: 'rgba(0, 0, 0, 0.45)',
            },
          },
          axisTick: {
            show: false,
          },
        },
      ],
      series: [
        {
          name: '数量',
          type: 'line',
          tooltip: {
            show: true,
          },
          label: {
            show: false,
            position: 'top',
            textStyle: {
              color: '#fff',
            },
          },
          itemStyle: {
            normal: {
              color: '#35B1F6',
              label: {
                show: false,
                textStyle: {
                  color: '#35B1F6',
                },
                position: 'top',
                formatter(p) {
                  return p.value
                },
              },
            },
          },
          data: barData.count,
        },
      ],
    })
  }
  setTable = () => {
    const { data } = this.props
    console.log('data====', data)
    if (data) {
      if (this.state.type === 'street') {
        return { data: data.list.data.street.data }
      }
      if (this.state.type === 'office') {
        return { data: data.list.data.office.data }
      }
      if (this.state.type === 'point') {
        return { data: data.list.data.point.data }
      }
      if (this.state.type === 'standard') {
        return { data: data.list.data.standard.data }
      }
    } else {
      return { data: [] }
    }
  }
  changeRange = e => {
    const value = e.target.value
    this.setState({
      timemode: value,
    })
    if (value === 'week') {
      this.setState({
        st: firstWeek(),
        et: lastWeek(),
      })
      this.props.changeTime([firstWeek(), lastWeek()], this.state.level)
    }
    if (value === 'month') {
      this.setState({
        st: firstDate(),
        et: lastDate(),
      })
      this.props.changeTime([firstDate(), lastDate()], this.state.level)
    }
  }
  changeSelect = value => {
    this.setState({
      type: value,
    })
  }
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
    },
  }
  changeMode = e => {
    this.setState({
      mode: e.target.value,
    })
  }
  changeDate = (date, datestring) => {
    this.setState({
      st: datestring[0],
      et: datestring[1],
    })
    this.props.changeTime(datestring, this.state.level)
  }
  export = () => {
    this.props.export(this.state.st, this.state.et, this.state.type, this.state.level)
  }
  render() {
    const { data } = this.props
    console.log('ll', this.state.level)
    return (
      <div>
        <div>
          时间筛选:{' '}
          <RangePicker
            style={{ margin: '0 10px 0 20px' }}
            value={[moment(this.state.st), moment(this.state.et)]}
            onChange={this.changeDate}
            allowClear={false}
          />
          <Radio.Group onChange={this.changeRange} value={this.state.timemode}>
            <Radio.Button value="month">本月</Radio.Button>
            <Radio.Button value="week">本周</Radio.Button>
          </Radio.Group>
        </div>
        <h4 style={{ margin: '20px 0' }}>
          当前数据为: {this.state.name.length == 0 ? '全部数据' : this.state.name.join('-')}
        </h4>
        <div className="template-container">
          <h3>数据概况</h3>
          <div>
            <Row type="flex" justify="start">
              {data &&
                data.inspect_info.data.map((d, i) => {
                  return (
                    <div className="template-statistics" key={i}>
                      <div>
                        {d.title}
                        <Tooltip title={d.tips}>
                          <Icon
                            type="question-circle"
                            style={{ width: '10px', marginLeft: '10px' }}
                          />
                        </Tooltip>
                      </div>
                      <div>{d.value}</div>
                    </div>
                  )
                })}
              {data &&
                data.ucases_info.data.map((d, i) => {
                  return (
                    <div className="template-statistics" key={i}>
                      <div>
                        {d.title}{' '}
                        <Tooltip title={d.tips}>
                          <Icon
                            type="question-circle"
                            style={{ width: '10px', marginLeft: '10px' }}
                          />
                        </Tooltip>
                      </div>
                      <div>{d.value}</div>
                    </div>
                  )
                })}
            </Row>
          </div>
        </div>
        <div className="template-container">
          <h3>问题数量趋势图</h3>
          <div id="bar" style={{ width: '100%', height: '500px' }}></div>
        </div>
        <div className="template-container">
          <h3>问题列表</h3>
          <div className="opra_container">
            {/* <div style={{ float: 'left' }}>
              <Radio.Group onChange={this.changeMode} value={this.state.mode} buttonStyle="solid">
                <Radio.Button value="list">列表模式</Radio.Button>
                <Radio.Button value="map">地图模式</Radio.Button>
              </Radio.Group>
            </div> */}
            <div style={{ float: 'right' }}>
              <Button type="primary" onClick={this.export}>
                <Icon type="download" theme="outlined" />
                生成全部文件
              </Button>
              {/* &nbsp;&nbsp;&nbsp;
              <Button>
                <Icon type="download" theme="outlined" />
                生成所选文件
              </Button> */}
            </div>
          </div>
          {this.state.mode === 'list' ? (
            <div>
              {this.state.level == 1 ? (
                <Select
                  style={{ width: 120, margin: '0 0 10px 0' }}
                  onChange={this.changeSelect}
                  value={this.state.type}
                >
                  <Option value="street">街镇</Option>
                  <Option value="office">委办局</Option>
                  {qs.parse(window.location.search).key === '1' ? (
                    <Option value="point">点位</Option>
                  ) : null}
                  <Option value="standard">指标</Option>
                </Select>
              ) : (
                ''
              )}
              <Table columns={this.setColumns()} dataSource={this.setTable().data} bordered />
            </div>
          ) : (
            <div className={'map-list'}>
              <Map data={[]} />
            </div>
          )}
        </div>
      </div>
    )
  }
}
