import React from 'react'
import axios from 'axios'
import { LocaleProvider } from 'antd'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import Template from './template'
import Template2 from './template2'
import Template3 from './template3'
import Statistics from './statistics'
import { firstDate, lastDate } from './getdays'
import qs from 'query-string'

export default class Component extends React.Component {
  state = {
    data: null,
    check: 'third',
    date: [firstDate(), lastDate()],
    list: [],
    inspector: 'all',
  }
  componentDidMount() {
    this.getData(firstDate(), lastDate(), this.state.check)
    this.getList()
  }
  getList = () => {
    axios.get(`/users/inspectors`).then(res => {
      this.setState({
        list: res.data.data,
      })
    })
  }
  getData = (st, et, value) => {
    const key = qs.parse(window.location.search).key
    if (key == 1) {
      axios.get(`/statistics/third_statistics?st1=${st}&et1=${et}`).then(res => {
        if (res.status === 200) {
          this.setState({ data: res.data.data })
        }
      })
    }
    if (key == 2 || key == 3) {
      axios.get(`/statistics/package_statistics?key=${key}&st1=${st}&et1=${et}`).then(res => {
        if (res.status === 200) {
          this.setState({ data: res.data.data })
        }
      })
    }
    if (key == 5) {
      if (value === 'third') {
        axios.get(`/statistics/tweak_statistics?third=true&st1=${st}&et1=${et}`).then(res => {
          if (res.status === 200) {
            this.setState({ data: res.data.data })
          }
        })
      }
      if (value === 'package') {
        axios.get(`/statistics/tweak_package_statistics?st1=${st}&et1=${et}`).then(res => {
          if (res.status === 200) {
            this.setState({ data: res.data.data })
          }
        })
      }
    }
    if (key == 6) {
      axios.get(`/statistics/inspect_statistics?st1=${st}&et1=${et}`).then(res => {
        console.log('rrr', res)
        if (res.status === 200) {
          this.setState({ data: res.data.data })
        }
      })
    }
  }
  getData2 = (path, slug, st, et, value) => {
    const key = qs.parse(window.location.search).key
    let _slug = []
    slug.map(s => {
      if (s instanceof Array) {
        _slug.push(`[${s.join(',')}]`)
      } else {
        _slug.push(`${s}`)
      }
    })
    let slugs = _slug.join(',')
    this.setState({
      slugs,
    })
    if (key == 1) {
      axios
        .get(
          `/statistics/third_statistics_info?path=[${path}]&slugs=[${slugs}]&st1=${st}&et1=${et}`
        )
        .then(res => {
          console.log('nextdata==', res)
          if (res.status === 200) {
            this.setState({
              data: res.data.data,
            })
          }
        })
    }
    if (key == 2 || key == 3) {
      axios
        .get(
          `/statistics/package_statistics_info?key=${key}&path=[${path}]&slugs=[${slugs}]&st1=${st}&et1=${et}`
        )
        .then(res => {
          console.log('nextdata==', res)
          if (res.status === 200) {
            this.setState({
              data: res.data.data,
            })
          }
        })
    }
    if (key == 5) {
      if (value === 'third') {
        axios
          .get(
            `/statistics/tweak_statistics_info?path=[${path}]&slugs=[${slugs}]&st1=${st}&et1=${et}`
          )
          .then(res => {
            console.log('nextdata==', res)
            if (res.status === 200) {
              this.setState({
                data: res.data.data,
              })
            }
          })
      }
      if (value === 'package') {
        axios
          .get(
            `/statistics/tweak_package_statistics_info?path=[${path}]&slugs=[${slugs}]&st1=${st}&et1=${et}`
          )
          .then(res => {
            console.log('nextdata==', res)
            if (res.status === 200) {
              this.setState({
                data: res.data.data,
              })
            }
          })
      }
    }
    if (key == 6) {
      axios
        .get(`/statistics/inspect_statistics_info?slugs=[${slug}]&st1=${st}&et1=${et}`)
        .then(res => {
          console.log('nextdata==', res)
          if (res.status === 200) {
            this.setState({
              data: res.data.data,
            })
          }
        })
    }
  }
  getInspectorData = () => {
    axios
      .get(
        `/statistics/inspect_statistics?st1=${this.state.date[0]}&et1=${
          this.state.date[1]
        }&inspector=${value}`
      )
      .then(res => {
        console.log('rrr===', res)
        if (res.status === 200) {
          this.setState({ data: res.data.data })
        }
      })
  }
  changeTime = (datestring, level) => {
    this.setState({
      date: datestring,
    })
    if (level == 1) {
      this.getData(datestring[0], datestring[1], this.state.check)
    }
    if (level == 2 || level == 3) {
      this.getData2(
        this.state.path,
        this.state.slug,
        datestring[0],
        datestring[1],
        this.state.check
      )
    }
  }
  changeTime2 = (datestring, level) => {
    this.setState({
      date: datestring,
    })
    if (level == 1) {
      if (this.state.inspector == 'all') {
        this.getData(datestring[0], datestring[1])
      } else {
        this.getInspectorData()
      }
    }
    if (level == 2) {
      this.getData2(null, this.state.slug, datestring[0], datestring[1])
    }
  }
  changeCheck = (value, level) => {
    this.setState({
      check: value,
    })
    if (level == 1) {
      this.getData(this.state.date[0], this.state.date[1], value)
    }
    if (level == 2) {
      this.getData2(this.state.path, this.state.slug, this.state.date[0], this.state.date[1], value)
    }
  }
  gotoNext = (path, slug) => {
    console.log('path', path)
    console.log('slug', slug)
    this.setState({
      path,
      slug,
      data: null,
    })
    this.getData2(path, slug, this.state.date[0], this.state.date[1], this.state.check)
  }
  changeInspector = value => {
    this.setState({
      inspector: value,
    })
    if (value === 'all') {
      this.getData(this.state.date[0], this.state.date[1])
    } else {
      this.getInspectorData()
    }
  }
  export = (st, et, type, level, checkway) => {
    const key = qs.parse(window.location.search).key
    if (level == 1) {
      if (key == 1) {
        window.location.href = `/statistics/third_statistics.xlsx?st1=${st}&et1=${et}&export=${type}`
      }
      if (key == 2 || key == 3) {
        window.location.href = `/statistics/package_statistics.xlsx?st1=${st}&et1=${et}&export=${type}&key=${key}`
      }
      if (key == 5) {
        if (checkway == 'third') {
          window.location.href = `/statistics/tweak_statistics.xlsx?st1=${st}&et1=${et}&export=${type}`
        }
        if (checkway == 'package') {
          window.location.href = `/statistics/tweak_package_statistics.xlsx?st1=${st}&et1=${et}&export=${type}`
        }
      }
    }
    if (level == 2 || level == 3) {
      if (key == 1) {
        window.location.href = `/statistics/third_statistics_info.xlsx?st1=${st}&et1=${et}&path=[${this.state.path}]&slugs=[${this.state.slugs}]&export=${type}`
      }
      if (key == 2 || key == 3) {
        window.location.href = `/statistics/package_statistics_info.xlsx?st1=${st}&et1=${et}&path=[${this.state.path}]&slugs=[${this.state.slugs}]&export=${type}&key=${key}`
      }
      if (key == 5) {
        if (checkway == 'package') {
          window.location.href = `/statistics/tweak_package_statistics_info.xlsx?st1=${st}&et1=${et}&path=[${this.state.path}]&slugs=[${this.state.slugs}]&export=${type}`
        }
      }
    }
    if (key == 6) {
      window.location.href = `/statistics/inspect_statistics.xlsx?st1=${st}&et1=${et}`
    }
  }
  renderTemplate = () => {
    const key = qs.parse(window.location.search).key
    if (key == 1 || key == 2 || key == 3) {
      return (
        <Template
          data={this.state.data}
          changeTime={this.changeTime}
          gotoNext={this.gotoNext}
          export={this.export}
        />
      )
    }
    if (key == 5) {
      return (
        <Template2
          data={this.state.data}
          changeTime={this.changeTime}
          gotoNext={this.gotoNext}
          changeCheck={this.changeCheck}
          export={this.export}
        />
      )
    }
    if (key == 6) {
      return (
        <Template3
          data={this.state.data}
          list={this.state.list}
          changeInspector={this.changeInspector}
          changeTime={this.changeTime2}
          gotoNext={this.gotoNext}
          export={this.export}
        />
      )
    }
    if (key == 7) {
      return <Statistics />
    }
  }
  render() {
    return (
      <LocaleProvider locale={zh_CN}>
        <div>{this.renderTemplate()}</div>
      </LocaleProvider>
    )
  }
}
