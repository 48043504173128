import React from 'react'
import { Form, Input, Row, Col, Button, AutoComplete, Checkbox } from 'antd'
const FormItem = Form.Item

export default class Component extends React.Component {
  state = {}
  submit = () => {
    if (!this.props.login.mobile || !this.props.login.password) {
      this.props.setHelp({
        validateStatus: 'error',
        help: '请填写账号密码',
      })
      return
    }
    // console.log(this.state)
    axios
      .post(
        '/user_sessions.json',
        {
          user_session: Object.assign(
            {
              login: 'web',
            },
            this.props.login
          ),
        },
        { withCredentials: true }
      )
      .then(({ data }) => {
        console.log(data)
        if (data.code == 200) {
          setTimeout(() => {
            Turbolinks.visit('/ucases?key=2')
          }, 1500)
        } else {
          this.props.setHelp({
            validateStatus: 'error',
            help: '账号或密码错误!',
          })
        }
      })
  }

  onChange = attr => {
    return e => {
      if (!e) {
        return this.setState({ [attr]: null })
      }
      let val = e.target ? e.target.value : e
      this.setState({ [attr]: val, validateStatus: null, help: '', remember_me: e.target.checked })
    }
  }

  render() {
    return (
      <div>
        <FormItem validateStatus={this.props.login.validateStatus} style={{ marginBottom: 10 }}>
          <Input
            placeholder="请输入手机号"
            value={this.props.login.mobile}
            onChange={this.props.onChange('mobile')}
            onPressEnter={this.submit}
          />
        </FormItem>
        <FormItem
          validateStatus={this.props.login.validateStatus}
          help={this.props.login.help}
          style={{ marginBottom: 2 }}
        >
          <Input
            type="password"
            placeholder="请输入密码"
            value={this.props.login.password}
            onChange={this.props.onChange('password')}
            onPressEnter={this.submit}
          />
        </FormItem>
        <FormItem style={{ marginBottom: 2 }}>
          <Checkbox onChange={this.props.onChange('remember_me')}>记住密码</Checkbox>
        </FormItem>
        <Button
          help={this.props.login.help}
          style={{ width: 170, backgroundColor:this.props.color, border:`solid 1px ${this.props.color}` }}
          type="primary"
          className="login_btn"
          onClick={this.submit}
        >
          登录
        </Button>
      </div>
    )
  }
}
