import React from "react"
import { Button,Icon } from 'antd'

export default class Component extends React.Component {
	state={
		arr:[1,2,3,4,5,6],
		count:1
	}
	changeItem = ()=>{
		let newArr=[]
		if(this.state.count == 3){
			this.setState({
				arr:[1,2,3,4,5,6],
				count:1
			})
		}
		else{
			this.state.arr.map((x)=>{
				newArr.push(x+6)
			})
			this.setState({
				arr:newArr,
				count:this.state.count+1
			})
		}	
	}
	render(){
		let title = [
			'2018年北京市民公共行为文明指数调查项目',
			'2018年垃圾分类日常检查',
			'北京市农村生活垃圾治理第三方检查',
			'延庆区疏解整治促提升专项行动第三方检查',
			'海淀区农村基础设施第三方考评服务',
			'海淀区河湖管护第三方巡查考核项目',	
			'海淀区文明城区创建监督检查及管理提升',
			'北京市电动汽车充电桩运营质量监测',
			'北京市供电营业窗口服务质量监测',
			'房山区环境建设综合检查',
			'餐厨垃圾规范收运第三方检查',
			'文明城区实地考察和问卷调查测评工作项目',
			'石景山区疏解腾退空间再利用核验项目',
			'顺义区园林绿地第三方专业检查服务',
			'石景山区绿地养护第三方专业检查服务',
			'通州区平原造林养护第三方专业检查服务',
			'门头沟区创建全国文明城区现场检查项目',
			'石景山区全国文明城区创建第三方测评'
		]
		let name = [
			'首都文明办',
			'北京市环境卫生管理与事务中心',
			'北京市城市管理委员会',
			'延庆区发展改革委员会',
			'海淀区农委',
			'海淀区水务局',
			'海淀区文明办',
			'北京市城市管理委员会',
			'国网北京市电力公司',
			'房山城管委',
			'北京市城市管理委员会',
			'首都文明办',
			'石景山区发改委',
			'顺义区园林服务中心',
			'石景山区园林绿化局',
			'通州区林业工作总站',
			'门头沟区文明办',
			'石景山文明办'
		]
		return(
			<div className="ugm_success">
				{
					this.state.arr.map((item,index)=>{
						return(
							<div key={index} className="ugm_success_section">
								<div className="ugm_success_item">
									<img src={`/assets/${item}.png`}/>  
									<div className="ugm_success_item_content">
										<div style={{color: '#666666',fontSize: 14}}>{title[item-1]}</div>
										<div style={{color: '#4A4A4A',fontSize: 16}}>{name[item-1]}</div>
									</div>
								</div>
							</div>
						)	
					})
				}
				<Button type="primary" onClick={()=>this.changeItem()}><Icon type="redo" theme="outlined" />换一批查看</Button>
			</div>
		)
	}
}