import React from 'react'

export default class Component extends React.Component {
  state = {}
  changeTab = target => {
    this.setState({
      target,
    })
  }
  componentWillMount() {
    this.changeTab(0)
  }
  render() {
    let arr = [0, 1, 2]
    let blue_text = ['【城镇绿地养护检查】', '【生态林地养护检查】', '【森林资源普查】']
    let text = [
      '建立城镇绿地养护台账，开展绿地养护质量检查。',
      '根据生态林地养护标准建立指标体系开展检查。',
      '支持全覆盖小班调查、样地调查、蓄积量调查以及面积核查等工作。',
    ]
    return (
      <div className="ugm_plan_content">
        <div className="ugm_plan_title_section">
          <div className="ugm_plan_header" style={{backgroundColor: '#00C466'}}>应用领域</div>
          {arr.map((item, index) => {
            return (
              <div
                key={index}
                className={`ugm_plan_title_item_ilm ${
                  index == this.state.target ?  'ugm_plan_title_item_ilm_active' : ''
                }`}
                style={{height: '112px'}}
                onMouseEnter={() => this.changeTab(index)}
              >
                <span className="blue_text" style={{color: '#00C466'}}>{blue_text[index]}</span>
                {text[index]}
              </div>
            )
          })}
        </div>
        <div className="ugm_plan_plan_section">
          <div className="ugm_plan_header" style={{backgroundColor: '#00C466'}}>解决方案</div>
          {arr.map((item, index) => {
            if (index == this.state.target) {
              return (
                <div key={index} className="ugm_plan_plan_item">
                  <img src={`/assets/ilm_tab${index + 1}.png`} />
                </div>
              )
            }
          })}
        </div>
      </div>
    )
  }
}
