import React from "react"
import { Button,Icon } from 'antd'

export default class Component extends React.Component {
	state={
		arr:[1,2,3,4,5,6],
		count:1
	}
	changeItem = ()=>{
		let newArr=[]
		if(this.state.count == 3){
			this.setState({
				arr:[1,2,3,4,5,6],
				count:1
			})
		}
		else{
			this.state.arr.map((x)=>{
				newArr.push(x+6)
			})
			this.setState({
				arr:newArr,
				count:this.state.count+1
			})
		}	
	}
	render(){
		let title = [
			'春节公园服务管理第三方评价',
			'北京市公园管理第三方检查',
			'顺义区园林服务中心绿地第三方专业检查',
			'海淀区绿地养护第三方监理',
			'通州区平原造林工程林木资源养护管理检查',
			'石景山区园林绿化局第三方现场检查',				
		]
		let name = [
			'北京市公园管理中心',
			'北京市公园管理中心',
			'北京四季景艺园林绿化中心',
			'北京市海淀区园林绿化局',
			'北京市通州区园林绿化局',
			'北京市石景山区园林绿化局',			
		]
		return(
			<div className="ugm_success">
				{
					this.state.arr.map((item,index)=>{
						return(
							<div key={index} className="ugm_success_section">
								<div className="ugm_success_item">
									<img src={`/assets/ilm${item}.png`}/>
									<div className="ugm_success_item_content">
										<div style={{color: '#666666',fontSize: 14}}>{title[item-1]}</div>
										<div style={{color: '#4A4A4A',fontSize: 16}}>{name[item-1]}</div>
									</div>
								</div>
							</div>
						)	
					})
				}
			</div>
		)
	}
}