import React from 'react'
import { Button, Input, Row, Col, Form } from 'antd'
import moment from 'moment'
import 'moment/locale/zh-cn'
const FormItem = Form.Item

const { TextArea } = Input

export default class Component extends React.Component {
  state = {
    content: '',
    messages: [],
  }

  componentDidMount() {
    this.setState({
      messages: this.props.messages || [],
    })
  }

  messages = () => {
    if(!this.state.messages || this.state.messages.length == 0) {
      return <div>暂无留言</div>
    }
    return this.state.messages.map(msg => {
      return (
        <div className="words_record_item" key={msg.id}>
          <div className="words_record_text">
            <Col span={24}>
              {msg.creator_name} <span className="blue">留言</span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span className="index_words_time">{moment(msg.created_at).format('llll')}</span>
            </Col>
            <Col span={24}>{msg.content}</Col>
          </div>
          {/* <div className="words_record_btn">
						<Button className="words_report_btn" size={'small'} type="primary">回复</Button>
					</div> */}
        </div>
      )
    })
  }

  report = () => {
    if (!this.state.content || this.state.content == 0) {
      this.setState({
        validateStatus: 'error',
        help: '请输入内容',
      })
      return
    }

    let uc_message = {
      content: this.state.content,
    }
    axios
      .post(`/ucases/${this.props.ucase_id}/uc_messages.json`, { uc_message })
      .then(({ data }) => {
        if (data.code == 200) {
          this.setState({
            messages: [data.data, ...this.state.messages],
          })
          console.info('success')
        } else {
          // 错误提示
        }
      })
  }

  onChange = e => {
    this.setState({
      content: e.target.value,
      validateStatus: null,
      help: null,
    })
  }

  render() {
    return (
      <div>
        <div className="words_report">
          <label>留言回复:</label>
          <Row>
            <Col span={18}>
              <FormItem validateStatus={this.state.validateStatus} help={this.state.help}>
                <TextArea
                  placeholder="请输入回复内容..."
                  className="words_report_content"
                  autosize={{ minRows: 5, maxRows: 5 }}
                  value={this.state.content}
                  onChange={this.onChange}
                />
              </FormItem>
            </Col>
            <Col span={6}>
              <Button className="words_report_btn" type="primary" onClick={this.report}>
                回复
              </Button>
            </Col>
          </Row>
        </div>
        <div className="words_record">
          <label>留言记录:</label>
          <Row>
            <div className="words_record_content">{this.messages()}</div>
          </Row>
        </div>
      </div>
    )
  }
}
