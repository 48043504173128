import { Icon, Row, Col } from 'antd';
import React from "react"

export default class Component extends React.Component{
	state={
		status: this.props.status,
	}

	cols = ()=>{
		let statusAry;
		if(this.state.status == 1) {
			statusAry = [0, 1]
		} else {
			statusAry = [0, 2, 3, 4]
		}
		return statusAry.map((status, i) => { 
			let numClass = '' , textClass = ''
			if(status < this.state.status ) {
				numClass = 'black_num'
				textClass = 'ucase_process_now'
			} else if(this.state.status == status) {
				numClass = 'blue_num'
				textClass = 'ucase_process_ever'
			}
			return (<Col span={6} className="item" key={i}>
			<div className={`ucase_process_num ${numClass}`}>{i + 1}</div>
				<span className={textClass}>{uugmUtil.ucaseStatus(status)}</span>
				{ status == statusAry[statusAry.length - 1] ? '' : <Icon type="right" className="ucase_process_icon" /> }
			</Col>)
		})
	}

	componentDidMount=()=>{

	}
	
	render(){		
		return(
			<div className="ucase_process">
				<Row>
					{this.cols()}
				</Row>
			</div>
		)
	}
}