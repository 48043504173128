import { Controller } from 'stimulus'
import { each } from 'lodash'
// import * as qs from "query-string"

export default class extends Controller {
  static targets = ['name', 'output']

  connect() {
    let items = this.element.querySelectorAll('.header_nav_item')
    let path = location.pathname
    path = path.indexOf('/home') == 0 ? '/' : path
    let aitems = this.element.querySelectorAll('header_nav_item_line')
    each(aitems, x => x.remove())
    each(items, x => {
      let a = x.children[0]
      let cpath = (a.getAttribute('href') || '').split('?')[0]
      if (!cpath || cpath.length == 0) {
        return
      }
      if ('/' == path && '/' == cpath) {
        x.classList.add('header_nav_item_active')
        x.insertAdjacentHTML('beforeend', '<div class="header_nav_item_line"></div>')
      } else if ('/' != cpath && path.indexOf(cpath) == 0) {
        x.classList.add('header_nav_item_active')
        x.insertAdjacentHTML('beforeend', '<div class="header_nav_item_line"></div>')
      }
    })
  }
}
