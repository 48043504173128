import React from 'react'
import { DatePicker, Button, Row, Col, Table, Pagination } from 'antd'
import moment from 'moment'

const { RangePicker } = DatePicker
export default class Component extends React.Component {
  state = {
    data: [],
    pagis: null,
    st: null,
    et: null,
  }
  getList = (st = null, et = null, per = 10, page = 1) => {
    axios
      .get(
        `/statistics/district_statistic?page=${page}&per=${per}${st ? `&st=${st}&et=${et}` : ''}`
      )
      .then(res => {
        console.log('res', res)
        if (res.data.code === 200) {
          this.setState({
            data: res.data.data,
            pagis: res.data.pagis,
          })
        }
      })
  }
  componentDidMount() {
    this.getList()
  }
  onChange = (date, datestring) => {
    this.setState({
      st: datestring[0],
      et: datestring[1],
    })
  }
  search = () => {
    this.getList(this.state.st, this.state.et)
  }
  reset = () => {
    this.setState({
      st: null,
      et: null,
    })
    this.getList()
  }
  changePage = page => {
    this.getList(this.state.st, this.state.et, 10, page)
  }
  render() {
    const columns = [
      {
        title: '时间',
        dataIndex: 'date',
        key: 'date',
      },
      {
        title: 'mtg04',
        dataIndex: 'mtg04',
        key: 'mtg04',
      },
      {
        title: 'mtg05',
        dataIndex: 'mtg05',
        key: 'mtg05',
      },
      {
        title: 'mtg06',
        dataIndex: 'mtg06',
        key: 'mtg06',
      },
      {
        title: 'mtg07',
        dataIndex: 'mtg07',
        key: 'mtg07',
      },
      {
        title: 'mtg08',
        dataIndex: 'mtg08',
        key: 'mtg08',
      },
      {
        title: 'mtg09',
        dataIndex: 'mtg09',
        key: 'mtg09',
      },
      {
        title: 'mtg10',
        dataIndex: 'mtg10',
        key: 'mtg10',
      },
    ]
    return (
      <div>
        <div>
          <Row>
            <Col span={10}>
              <span style={{ marginRight: '20px' }}>时间筛选:</span>
              <RangePicker
                onChange={this.onChange}
                value={this.state.st ? [moment(this.state.st), moment(this.state.et)] : ''}
              />
            </Col>
            <Col span={6}>
              <Button type="primary" style={{ marginRight: '20px' }} onClick={this.search}>
                查询
              </Button>
              <Button onClick={this.reset}>重置</Button>
            </Col>
          </Row>
        </div>
        <div style={{ marginTop: '25px' }}>
          <Table columns={columns} dataSource={this.state.data} pagination={false} />
          {this.state.pagis && (
            <Pagination
              style={{ marginTop: '15px', float: 'right' }}
              current={this.state.pagis.page}
              total={this.state.pagis.total}
              onChange={this.changePage}
            />
          )}
        </div>
      </div>
    )
  }
}
