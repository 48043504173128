import { Menu, Dropdown, Icon } from 'antd'
import React from 'react'
export default class Component extends React.Component {
  menu = (
    <Menu>
      <Menu.Item>
        <a rel="noopener noreferrer" href={`${window.location.origin}/login`}>
          退出登录
        </a>
      </Menu.Item>
    </Menu>
  )
  render() {
    return (
      <Dropdown overlay={this.menu}>
        <a className="header_user ant-dropdown-link" href="javascript:void(0);">
          用户
        </a>
      </Dropdown>
    )
  }
}
