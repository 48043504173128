

import { Controller } from 'stimulus'
// import * as qs from "query-string"

export default class extends Controller {
  static targets = ['ucase']

  connect() {
    // console.log('ccc')
  }

  to_excel() {
    console.log(this.element.dataset)
    axios.get(`/ucases/${this.element.dataset.ucase}/to_excel.json`).then(({ data }) => {
      if (data.code == 200) {
        uugmUtil.openDownloadDialog(data.data,'批量导出.xlsx')
      } else {
        // 错误提示
        console.log(data)
      }
    })
  }

}
