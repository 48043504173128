import React, { useLayoutEffect, useState } from 'react'
import { Row, Col, DatePicker, LocaleProvider, Button, Table } from 'antd'
import { Map, Polyline, Marker, Markers, InfoWindow } from 'react-amap'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import * as qs from 'query-string'
import moment from 'moment'

const { RangePicker } = DatePicker
const columns = [
  {
    title: '时间',
    align: 'center',
    dataIndex: 'time',
    key: 'time',
    render: time => {
      let ntime = moment(time).format('YYYY-MM-DD, HH:mm:ss')
      return <span>{ntime}</span>
    },
  },
  {
    title: '坐标',
    align: 'center',
    dataIndex: 'lnglat',
    key: 'lnglat',
    render: lnglat => {
      return <span>{`${lnglat[0]} , ${lnglat[1]}`}</span>
    },
  },
]

export default class Component extends React.Component {
  state = {
    visible: false,
    position: {},
  }
  componentDidMount() {
    const query = qs.parse(location.search)
    const st = query.st ? query.st : null
    const et = query.et ? query.et : null
    let { active_paths } = this.props
    let lineArr
    // if (active_paths.length === 0) {
    //   lineArr = [
    //     [116.478935, 39.997761],
    //     [116.478939, 39.997825],
    //     [116.478912, 39.998549],
    //     [116.478912, 39.998549],
    //     [116.478998, 39.998555],
    //     [116.478998, 39.998555],
    //     [116.479282, 39.99856],
    //     [116.479658, 39.998528],
    //     [116.480151, 39.998453],
    //     [116.480784, 39.998302],
    //     [116.480784, 39.998302],
    //     [116.481149, 39.998184],
    //     [116.481573, 39.997997],
    //     [116.481863, 39.997846],
    //     [116.482072, 39.997718],
    //     [116.482362, 39.997718],
    //     [116.483633, 39.998935],
    //     [116.48367, 39.998968],
    //     [116.484648, 39.999861],
    //   ]
    // } else {
    //   lineArr = active_paths.map(x => x.lnglat)
    // }
    lineArr = active_paths.map(x => x.lnglat)

    const markerArr = active_paths.map(x => {
      return [
        {
          longitude: x.lnglat[0],
          latitude: x.lnglat[1],
        },
        {
          time: x.time,
        },
      ]
    })
    const markers = markerArr.map(x => {
      return {
        position: x[0],
        time: moment(x[1].time).format('YYYY-MM-DD, HH:mm:ss'),
      }
    })
    this.setState({
      st,
      et,
      lineArr,
      markers,
    })
  }
  onChange = (date, datestring) => {
    if (datestring[0] !== '') {
      this.setState({
        st: datestring[0],
        et: datestring[1],
      })
      const queryStr = qs.stringify({
        st: datestring[0],
        et: datestring[1],
      })
      document.location.href = `?${queryStr}`
    } else {
      this.setState({
        st: null,
        et: null,
      })
      const queryStr = qs.stringify({
        st: null,
        et: null,
      })
      document.location.href = `?`
    }
  }
  startAnimation = () => {
    this.state.marker.moveAlong(this.buildLineArr(), 200)
  }

  pauseAnimation = () => {
    this.state.marker.pauseMove()
  }

  resumeAnimation = () => {
    this.state.marker.resumeMove()
  }

  stopAnimation = () => {
    this.state.marker.stopMove()
  }

  buildLineArr = () => {
    return this.state.lineArr.map(x => {
      return new AMap.LngLat(x[0], x[1])
    })
  }
  render() {
    console.log('state', this.state)
    let passedPolyline
    const _this = this
    const events = {
      created(m) {
        console.log('m', m)
        _this.setState({
          marker: m,
        })
        // marker = m
        // startAnimation = () => {
        // }
      },
      moving: e => {
        if (passedPolyline) {
          passedPolyline.setPath(e.passedPath)
        }
      },
    }

    const lineEvents = {
      created: l => {
        passedPolyline = l
      },
    }

    const markerEvents = {
      created: allMarkers => {
        console.log('All Markers Instance Are Below')
      },
      click: e => {
        this.setState({
          visible: true,
          position: { longitude: e.lnglat.lng, latitude: e.lnglat.lat },
          time: e.target.getExtData().time,
        })
      },
    }

    const closeWindow = () => {
      this.setState({
        visible: false,
      })
    }

    return (
      <LocaleProvider locale={zh_CN}>
        <div style={{ marginTop: '20px' }}>
          <div style={{ overflow: 'hidden' }}>
            <div style={{ float: 'left', marginBottom: '20px' }}>
              <RangePicker
                onChange={this.onChange}
                value={this.state.st ? [moment(this.state.st), moment(this.state.et)] : null}
              />
            </div>
            <div style={{ float: 'left', marginLeft: '150px', marginBottom: '20px' }}>
              <Button onClick={this.startAnimation}>开始动画</Button>
              <Button onClick={this.pauseAnimation}>暂停动画</Button>
              <Button onClick={this.resumeAnimation}>继续动画</Button>
            </div>
          </div>

          <div>
            <Row gutter={16}>
              <Col className="gutter-row" span={16} style={{ height: '640px' }}>
                <Map
                  amapkey={'d57b050e830566009d1332a076231223'}
                  mapStyle={'amap://styles/a59593ff89ee3597dbc63eb987f5a36f'}
                  center={this.state.lineArr && this.state.lineArr[0]}
                  zoom={17}
                  version={'1.4.9'}
                >
                  <Marker
                    position={this.state.lineArr && this.state.lineArr[0]}
                    icon={'/assets/jc-icon.png'}
                    offset={[-26, -13]}
                    autoRotation={true}
                    angle={-90}
                    events={events}
                  />
                  <Markers markers={this.state.markers} events={markerEvents} />
                  <Polyline
                    path={this.state.lineArr}
                    showDir={true}
                    strokeColor="#28F"
                    strokeWeight={6}
                    events={lineEvents}
                  />
                  <Polyline
                    path={this.state.lineArr}
                    strokeColor="#AF5"
                    strokeWeight={6}
                    events={lineEvents}
                  />
                  <InfoWindow
                    position={this.state.position}
                    visible={this.state.visible}
                    isCustom={true}
                    offset={{ x: -4, y: -20 }}
                  >
                    <div className="amap-ext-info">
                      <div className="box" style={{ width: '240px' }}>
                        <div className="arrow" style={{ left: '105px' }}>
                          <em></em>
                          <span></span>
                        </div>
                        <div className="content"></div>
                        <div>
                          <div>
                            位置:{this.state.position.longitude} , {this.state.position.latitude}{' '}
                          </div>
                          <div>时间:{this.state.time}</div>
                        </div>
                        <div>
                          <div className="show-btn" style={{ marginLeft: '80px' }}>
                            <Button type="primary" onClick={closeWindow}>
                              关 闭
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </InfoWindow>
                </Map>
              </Col>
              <Col className="gutter-row" span={8}>
                <Table columns={columns} dataSource={this.props.active_paths} bordered />
              </Col>
            </Row>
          </div>
        </div>
      </LocaleProvider>
    )
  }
}
