import { Row, Col, Icon } from 'antd';
import React from "react"

export default class Component extends React.Component {
	render(){
		return(
			<div className="gutter-example">
		    <Row gutter={16}>
		      <Col className="gutter-row" span={6}>
		        <div className="gutter-box intro_function_item">
		        	<div className="intro_function_item_title">
		        		<Icon type="radar-chart" theme="outlined" style={{marginRight: 10}} />现场明察暗访
		        	</div>
		        	<div className="intro_function_item_text">
		        		明察暗访人员使用手机APP，开展现场明察暗访并拍照上报问题，系统自动记录明察暗访路径、问题定位，支持离线上报问题。
		        	</div>
		        </div>
		      </Col>
		      <Col className="gutter-row" span={6}>
		        <div className="gutter-box intro_function_item">
		        	<div className="intro_function_item_title">
		        		<Icon type="user" theme="outlined" style={{marginRight: 10}} />问题审核
		        	</div>
		        	<div className="intro_function_item_text">
		        		审核员通过PC端管理后台实时审核问题，把控检查质量，审核通过的问题系统自动转发至责任单位。
		        	</div>
		        </div>
		      </Col>
		      <Col className="gutter-row" span={6}>
		        <div className="gutter-box intro_function_item">
		        	<div className="intro_function_item_title">
		        		<Icon type="warning" theme="outlined" style={{marginRight: 10}} />现场整改
		        	</div>
		        	<div className="intro_function_item_text">
		        		责任单位使用微信小程序到现场整改，将整改结果拍照反馈至平台，系统自动提醒明察暗访人员进行复核。
		        	</div>
		        </div>
		      </Col>
		      <Col className="gutter-row" span={6}>
		        <div className="gutter-box intro_function_item">
		        	<div className="intro_function_item_title">
		        		<Icon type="file-text" theme="outlined" style={{marginRight: 10}} />一键导出报告
		        	</div>
		        	<div className="intro_function_item_text">
		        		可定制化的报告生成模块，自动根据明察暗访人员上报的问题，以及责任单位整改情况，一键生成明察暗访报告。
		        	</div>
		        </div>
		      </Col>
		    </Row>
		  </div>
		)
	}
}