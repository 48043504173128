import React from 'react'
import { Table, Pagination } from 'antd'

export default class Component extends React.Component {
  state = {
    dataList: [],
    pagis: {
      total: 0,
      size: 10,
      page: 0,
    },
  }
  getList = page => {
    axios
      .get(`/assessment/by_ori?unitId=${this.props.user.organization_id}&page=${page}&size=10`)
      .then(res => {
        console.log('ooo===', res)
        this.setState({
          dataList: res.data.data,
          pagis: res.data.pagis,
        })
      })
  }
  componentDidMount() {
    this.getList(0)
  }
  changePage = page => {
    this.getList(page - 1)
  }
  render() {
    const columns = [
      {
        title: '统计月份',
        dataIndex: 'month',
        key: 'month',
      },
      {
        title: '更新时间',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
      },
      {
        title: '排名',
        dataIndex: 'rank',
        key: 'rank',
      },
      {
        title: '汇总得分',
        dataIndex: 'summaryScore',
        key: 'summaryScore',
        render: score => {
          return <span>{score && score.toFixed(2)}</span>
        },
      },
    ]
    const { dataList, pagis } = this.state
    return (
      <div>
        <div style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '20px' }}>
          单位名称: {this.props.user.name}
        </div>
        <div>
          <Table columns={columns} dataSource={dataList} pagination={false} />
          <Pagination
            current={pagis.page + 1}
            total={pagis.total}
            pageSize={pagis.size}
            style={{ float: 'right', marginTop: '15px' }}
            onChange={this.changePage}
          />
        </div>
      </div>
    )
  }
}
